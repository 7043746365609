import React, { useEffect, useState, useContext } from 'react';
import { ReformatContext } from '../../../../pages/e-commerce/Reformatting';
import reformatServices from '../../../../services/reformatServices';
import { useSearchParams } from 'react-router-dom';
import usePopup from '../../../../helper/usePopup';
import SizeGroup from './SizeGroup';
import NewInfoPopup from '../../Popup/AddOrder/NewInfoPopup';

const FormatList = () => {
  const [formats, setFormats] = useState([]);
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [renderOnce, setRenderOnce] = useState(true);
  const { initPopup } = usePopup();

  const checkIfStrcturesIncludes = (basicSizeList, element) => {
    for (let i = 0; i < basicSizeList.length; i++) {
      if (basicSizeList[i].group === element.group) return true;
    }
    return false;
  };

  const generateBasicSizeListDataStructure = (data, initsizes) => {
    const basicSizeList = [];
    let groupHelper = '';

    data.sort((a, b) => a.size_group - b.size_group);

    data.forEach((el) => {
      if (el.size_group_name !== groupHelper) {
        groupHelper = el.size_group_name;
        const obj = {
          group: el.size_group,
          group_name: el.size_group_name,
          chosen_sizes: []
        };
        basicSizeList.push(obj);
      }
    });

    if (initsizes) {
      setReformatData({ ...reformatData, basic_sizes_list: basicSizeList });
    } else {
      const basicSizeListCopy = [...reformatData.basic_sizes_list];
      let isSizeListChanged = false;

      basicSizeList.forEach((el) => {
        if (!checkIfStrcturesIncludes(basicSizeListCopy, el)) {
          basicSizeListCopy.push(el);
          if (!isSizeListChanged) isSizeListChanged = true;
        }
      });

      if (isSizeListChanged)
        setReformatData({
          ...reformatData,
          basic_sizes_list: basicSizeListCopy
        });
    }
  };

  const getSizes = (initsizes) => {
    reformatServices
      .getClientSizes()
      .then((res) => {
        generateBasicSizeListDataStructure(res, initsizes);
        const groupedFormats = Object.values(
          res.reduce((groups, item) => {
            const groupName = item.size_group_name;
            if (!groups[groupName]) {
              groups[groupName] = { size_group_name: groupName, items: [] };
            }
            groups[groupName].items.push(item);
            return groups;
          }, {})
        );
        setFormats(groupedFormats);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<NewInfoPopup type={'negative'} info={'format_list'} />);
      });
  };

  useEffect(() => {
    if (!searchParams.get('order')) {
      getSizes(true);
    }
  }, []);

  useEffect(() => {
    if (renderOnce) {
      let initsizes = false;
      if (reformatData.basic_sizes_list === null) {
        initsizes = true;
      }
      getSizes(initsizes);
      setRenderOnce(false);
    }
  }, [reformatData]);

  return (
    <>
      <ul className="format-list">
        {formats.length > 0 ? (
          formats.map((el, index) => {
            return (
              <>
                <SizeGroup group={el} key={index} setReformatData={setReformatData} reformatData={reformatData} />
              </>
            );
          })
        ) : (
          <>
            {loading ? (
              <span>loading</span>
            ) : (
              <li>
                <span className="error">brak dostępnych formatów</span>
              </li>
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default FormatList;
