import React, { useContext, useState } from 'react';
import orderServices from '../../../../../services/orderServices';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import Loader from '../../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../../globalElements/Loader/LOADER_TYPE';
import { ReloadTableContext } from '../../../../../Router';
import usePopup from '../../../../../helper/usePopup';
import TakeOver from '../../../Popup/AddOrder/TakeOver';
import SomethingWentWrong from '../../../Popup/AddOrder/SomethingWentWrong';
import { sendSentryInfo, sendSentryError } from '../../../../../helper/sentryHelper';

const TakeOverButton = ({ orderID, rowData }) => {
  const { user } = useContext(UserContext);
  const { setReloadComponent } = useContext(ReloadTableContext);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const { initPopup } = usePopup();

  const handleCaptureButton = () => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    setActiveButtonLoader(true);
    orderServices
      .assignProjectToCurrentAccUser(orderID, user.pk, true)
      .then(() => {
        initPopup(<TakeOver />);
        setReloadComponent(true);
        sendSentryInfo('FormatButTakeOverButtonton action', sentryContext);
      })
      .catch(() => {
        initPopup(<SomethingWentWrong type={'negative'} info={'take_over_error'} />);
        setActiveButtonLoader(false);
        sendSentryError('TakeOverButton action', sentryContext);
      });
  };
  return (
    <>
      {shouldDisplayButton('take_over', rowData?.order_status, user) !== 'Brak' && (
        <>
          <button
            className={`capture ${shouldDisplayButton('take_over', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={handleCaptureButton}
          >
            {activeButtonLoader ? (
              <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} />
            ) : (
              'Przejmij projekt'
            )}
          </button>
        </>
      )}
    </>
  );
};

export default TakeOverButton;
