const checkIfLockIsOlder = (time) => {
  if (time) {
    const currentDate = new Date().getTime();
    const lockTime = new Date(time.end_time).getTime();

    return currentDate > lockTime;
  }

  return false;
};

export default checkIfLockIsOlder;
