/**
 * checkIfValueHasChildren() - checkes if box field values has children value
 *
 * example usage: OrderBox.js, RadioList.js
 *
 * @param {Object} value - box field data
 * @returns true/false
 */

const checkIfValueHasChildren = (value) => {
  const searchArr = value.box_field_dictionary;

  for (let i = 0; i < searchArr.length; i++) {
    if (searchArr[i].children.length > 0) {
      return true;
    }
  }

  return false;
};

export default checkIfValueHasChildren;
