import React, { useState, useContext, useEffect, useRef } from 'react';
import Month from './Month';
import Weekdays from './Weekdays';
import CalenderErrorrs from './Errors';
import { MainAppContext } from '../../MainAppComponent';
import renderCalendar from '../../../functions/calenderFunctions/renderCalender';
import showCalender from '../../../functions/calenderFunctions/showCalender';
import setCorrectDate from '../../../functions/calenderFunctions/setCorrectDate';
import handleDisplayMonth from '../../../functions/calenderFunctions/handleDisplayMonth';
import handleCalenderInputs from '../../../functions/calenderFunctions/handleCalenderInputs';
import { month_list } from './month_list';
import { ReactComponent as DropdownIcon } from '../../../assets/dropdownicon.svg';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

/**
 * CalenderContainer - visual component for calendar deadline
 *
 * This component requires declaration of all fields and methods from props.
 *
 * Example components based on CalenderContainer: AddNewOrderCalenderDeadline.js, ReformatingCalenderDeadline.js
 */

const CalenderContainer = ({
  blockEdit,
  addNewOrder,
  calenderValue,
  setUpDeadline,
  hour,
  minute,
  error,
  noSourceError,
  inputValidated,
  setChosenDate,
  setHoure,
  setMinute,
  chosenDate,
  handleShadow,
  allowChoseAnyDate,
  showTimeInput,
  disActiveOnDateChose,
  displayCalender,
  setDisplayCalender,
  disallowPosition,
  deadline,
  placeholder,
  autoDateSet,
  calendarRef
}) => {
  const getDate = new Date();
  const getCurrYear = getDate.getFullYear();
  const getCurrMonth = getDate.getMonth();

  const months = month_list;

  const [date, setDate] = useState(getDate);
  const [time, setTime] = useState();
  const [currentDays, setCurrentDays] = useState([]);
  const [currYear, setCurrYear] = useState(getCurrYear);
  const [currMonth, setCurrMonth] = useState(getCurrMonth);
  const [currentMonthName, setCurrentMonthName] = useState(months[currMonth]);
  const [chosenDateError, setChosenDateError] = useState(false);
  const [isNotNumeric, setIsNotNumeric] = useState(false);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  const positionToElementRef = useRef();
  const calenderDropdownRef = useRef();
  const ref = useRef();

  useEffect(() => {
    renderCalendar(setCurrentDays, chosenDate, setCurrentMonthName, currYear, currMonth, handleDate);
  }, [date, displayCalender]);

  const handleCalender = () => {
    showCalender(
      setDisplayCalender,
      chosenDateError,
      setChosenDateError,
      setShadowActive,
      isShadowActive,
      handleShadow
    );
  };

  const handleDate = (e) => {
    const dateElement = e.target;

    const liList = document.querySelectorAll('.days li');

    setCorrectDate(dateElement, setChosenDateError, setChosenDate, liList, allowChoseAnyDate, autoDateSet);
    if (disActiveOnDateChose) disActiveOnDateChose();
  };
  const handleCalenderButton = (e) => {
    const element = e.target;
    handleDisplayMonth(element, currMonth, currYear, setCurrMonth, setDate, setCurrYear);
    renderCalendar(setCurrentDays, chosenDate, setCurrentMonthName, currYear, currMonth, handleDate);
  };

  const handleTimeInputs = () => {
    if (time && dayjs.isDayjs(time) && time.isValid()) {
      const hour = time.hour();
      const minute = time.minute();
      if (hour !== undefined && minute !== undefined) {
        handleCalenderInputs(hour, minute, setChosenDateError, setHoure, setMinute, chosenDate, setUpDeadline);
      }
    }
  };
  const handleChangeTime = (value) => {
    if (value) {
      setTime(value);
    }
  };
  const handleWriteTime = (value) => {
    if (value) {
      setTime(value);
    }
  };

  const positionDropdown = () => {
    const relativeElement = positionToElementRef.current;
    const dropdown = calenderDropdownRef.current;

    if (relativeElement && dropdown) {
      // Adjust the dropdown position if necessary
    }
  };

  useEffect(() => {
    if (chosenDate && time && dayjs.isDayjs(time) && time.isValid()) {
      handleTimeInputs();
      if (hour !== undefined && minute !== undefined && setUpDeadline) {
        setUpDeadline();
      }
    }
  }, [chosenDate, time, hour, minute]);

  useEffect(() => {
    if (!disallowPosition && positionToElementRef) {
      positionDropdown();
      window.addEventListener('scroll', positionDropdown);
      document.addEventListener('click', handleWindowClick);
    }
  }, [displayCalender]);

  const handleWindowClick = (e) => {
    if (
      calenderDropdownRef.current &&
      !calenderDropdownRef.current.contains(e.target) &&
      !positionToElementRef?.current.contains(e.target)
    ) {
      if (setDisplayCalender) setDisplayCalender(false);
      document.removeEventListener('click', handleWindowClick);
    }
  };

  // New useEffect to set default time to 17:00 when calendar closes
  useEffect(() => {
    if (!displayCalender && chosenDate) {
      if (!time) {
        const defaultTime = dayjs().hour(17).minute(0);
        setTime(defaultTime);
      }
    }
  }, [displayCalender, chosenDate]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const handleShowTooltip = (value) => {
    if (value) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      const id = setTimeout(() => {
        setShowTooltip(value);
      }, 300);
      setTimeoutId(id);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      const id = setTimeout(() => {
        setShowTooltip(value);
      }, 300);
      setTimeoutId(id);
    }
  };
  return (
    <>
      <div
        ref={calendarRef}
        className={blockEdit ? 'calendar-deadline blocked' : `calendar-deadline ${displayCalender ? 'visible' : ''}`}
      >
        {addNewOrder ? (
          !calenderValue ? (
            <button ref={positionToElementRef} onClick={() => setDisplayCalender(true)} className="add-new-deadline">
              +
            </button>
          ) : (
            <span
              className="chosen-date"
              ref={positionToElementRef}
              onClick={() => setDisplayCalender(true)}
              onMouseEnter={() => handleShowTooltip(true)}
              onMouseLeave={() => handleShowTooltip(false)}
            >
              {calenderValue}
              {showTooltip && (
                <span
                  onClick={() => setChosenDate('')}
                  className={showTooltip ? 'delete-tooltip tooltipVisible' : 'delete-tooltip'}
                >
                  Usuń
                </span>
              )}
            </span>
          )
        ) : (
          <>
            <input
              className={inputValidated ? 'calender-data' : 'calender-data not-validated'}
              onClick={handleCalender}
              placeholder={placeholder ? placeholder : 'Wybierz deadline'}
              readOnly="readonly"
              style={deadline ? { height: '30px' } : null}
              value={calenderValue}
              ref={positionToElementRef}
            ></input>
            <DropdownIcon className="arrow-down" onClick={handleCalender} />
          </>
        )}
        {displayCalender && (
          <div className="wrapper" ref={calenderDropdownRef}>
            <div className="calendar-container visible">
              <Month handleCalenderButton={handleCalenderButton} currentMonthName={currentMonthName} />
              <Weekdays currentDays={currentDays} />
              {showTimeInput && setUpDeadline && (
                <>
                  <div onClick={(e) => e.stopPropagation()}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Godzina zakończenia"
                        ampm={false}
                        value={time}
                        onAccept={handleChangeTime}
                        onChange={handleWriteTime}
                        inputRef={ref}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock
                        }}
                        InputProps={{
                          sx: {
                            '& .MuiOutlinedInput-root': {
                              paddingRight: '0px'
                            }
                          }
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            paddingRight: '0px',
                            borderRadius: '0px'
                          },
                          '& .MuiInputBase-input-MuiOutlinedInput-input': {
                            border: 'none'
                          },
                          input: {
                            border: 'none !important',
                            width: '450% !important'
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                            borderTop: '0.7px solid #e2e2e2'
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </>
              )}
              {!addNewOrder && (
                <CalenderErrorrs
                  error={error}
                  noSourceError={noSourceError}
                  chosenDateError={chosenDateError}
                  isNotNumeric={isNotNumeric}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CalenderContainer;
