import React from 'react';
import TableContextContainer from '../../elements/e-commerce/Table/TableContextContainer';
import pageLogo from '../../../assets/in-progrss-logo.svg';
import TABLE_TYPES from '../../../globalEnums/TABLE_TYPES_ENUMS';

const InProgressProjects = () => {
  return (
    <TableContextContainer
      tableType={TABLE_TYPES.IN_PROGRESS}
      pageTitle={'Projekty w trakcie realizacji'}
      logo={pageLogo}
    />
  );
};

export default InProgressProjects;
