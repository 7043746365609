import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';
import TABLE_TYPES from '../../globalEnums/TABLE_TYPES_ENUMS.js';

const createNewOrder = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const observeOrder = async (order_id, observe) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body: JSON.stringify({ is_observed: !observe })
  };

  let res = await fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/set-observe/`, requestOptions);
  const attempts = 3;
  let step = 0;

  while (!res.ok && step < attempts) {
    res = await fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/set-observe/`, requestOptions);
    step++;
  }

  return res.ok;
};

const getOrderList = async (status) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const endpoint_status = status == TABLE_TYPES.IN_PROGRESS ? 'inprogress' : status;
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/?status=${endpoint_status}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrdersNames = async (status, search, count) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const endpoint_status = status == TABLE_TYPES.IN_PROGRESS ? 'inprogress' : status;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/orders/order-names/?status=${endpoint_status}&search=${search}`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getTableOrderList = async (status, currentPage, postPerPage, externalEndpoint, searchByValue, orderBy) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const endpoint_status =
    status == TABLE_TYPES.IN_PROGRESS ? 'inprogress' : status == TABLE_TYPES.FINISHED ? status + ',cancelled' : status;
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  let enpoint = `orders/v2/?status=${endpoint_status}&page_size=${postPerPage}&page=${currentPage}&`;
  if (externalEndpoint) enpoint += externalEndpoint + '&';
  if (searchByValue) enpoint += `search=${searchByValue}&`;
  if (orderBy) enpoint += `ordering=${orderBy.join(',')}&`;

  return fetch(`${process.env.REACT_APP_API_URL}/${enpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const changeOrderStatus = async (order_id, order_status) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body: JSON.stringify({ status: order_status })
  };
  ///orders/${order_id}/status/`

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const changeOrderDetail = async (order_id, body) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrderSizes = async (order_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrderAssignedToUser = async (user_pk) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };
  let endpointURL;
  if (user_pk) {
    endpointURL = `orders/teams/${user_pk}/user_projects`;
  } else {
    endpointURL = 'orders/teams/user_projects';
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${endpointURL}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrderAssignedToUserSordetBy = async (user_pk, searchBarValue, externalEndoint) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };
  let endpointURL;
  if (user_pk) {
    endpointURL = `orders/teams/${user_pk}/user_projects?`;
  } else {
    endpointURL = 'orders/teams/user_projects?';
  }

  if (searchBarValue) endpointURL += `search=${searchBarValue}`;
  if (externalEndoint) endpointURL += `${externalEndoint}`;

  return fetch(`${process.env.REACT_APP_API_URL}/${endpointURL}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const assignProjectToCurrentAccUser = async (order_id, current_userId, changeStatus) => {
  const auth = authHeader();

  const bodyValues = changeStatus
    ? { managed_by: current_userId, status: 'accepted_for_realization' }
    : { managed_by: current_userId };

  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify(bodyValues)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const assignProjectToDtpUser = async (order_id, user_id, changeStatus) => {
  const auth = authHeader();

  const bodyValues = changeStatus
    ? { assigned_to: user_id, status: 'accepted_for_realization' }
    : { assigned_to: user_id };

  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify(bodyValues)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrdersAssignedToCertainUser = async (user_id, search, externalEndoint) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  let endpoint = `orders/teams/${user_id}/user_projects?search=${search || ''}`;
  if (externalEndoint) endpoint += externalEndoint;

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrdersAssignedToUserProjects = async (user_id, search, externalEndoint) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  let endpoint = `orders/teams/${user_id}/user-assigned-projects?search=${search || ''}`;
  if (externalEndoint) endpoint += externalEndoint;

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const cloneOrder = async (order_id, deadline, orderName) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body: JSON.stringify({
      deadline: deadline,
      action_name: orderName
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/clone/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrderImages = async (order_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/images`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getSizes = async (order_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/sizes/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const removeFromAssignedDtpProjectToAccProject = async (order_id, current_userId) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({
      managed_by: current_userId
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const addCounter = async (order_id, action_name, deadline) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({
      action_name: action_name,
      deadline: deadline
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/clone/counter`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const changeSource = async (order_id, body) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getOrderDetails = async (order_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const setUpUiSettings = async (order_id, data) => {
  const body = JSON.stringify({ ui_settings: { hidden_boxes: data } });
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const setServerLocation = async (order_id, data) => {
  const body = JSON.stringify({ agency_internal_url: data });
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/detail/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const serachOrderBy = async (status, search, page_number, size) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/orders/v2/?status=${status}&page_size=${size}&search=${search}`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const searchOrderByID = async (id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/v2/?order_id=${id}&page=1`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const sendOrderComment = async (order_id, note, createdby) => {
  const auth = authHeader();
  const body = JSON.stringify({
    order: order_id,
    created_by: createdby,
    comment: note
  });
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/comment/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r;
    });
  });
};

const getOrderComments = async (order_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/comments/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const restoreProjectAcc = async (order_id, deadline) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ deadline: deadline })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/restore-acc`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r;
    });
  });
};

const restoreProjectClient = async (order_id, deadline) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ deadline: deadline })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/restore/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r;
    });
  });
};

const getOrderLocks = async (order_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/orders/order-edit-lock/${order_id ? order_id : ''}`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const creatOrderLock = async (order_id, time) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ order: order_id, duration_seconds: time })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/order-edit-lock/`, requestOptions);
};

const removeOrderLock = async (lock_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/order-edit-lock/${lock_id}`, requestOptions);
};

const extendOrderLock = async (lock_id, time) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify({ duration_seconds: time })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/order-edit-lock/${lock_id}`, requestOptions);
};
const checkOrderHistory = async (id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/tools/${id}/order-history/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};
export default {
  restoreProjectAcc,
  checkOrderHistory,
  restoreProjectClient,
  createNewOrder,
  getOrderList,
  changeOrderStatus,
  cloneOrder,
  getOrderImages,
  getSizes,
  getOrderSizes,
  getOrderAssignedToUser,
  assignProjectToCurrentAccUser,
  assignProjectToDtpUser,
  getOrdersAssignedToCertainUser,
  removeFromAssignedDtpProjectToAccProject,
  addCounter,
  changeSource,
  getOrderDetails,
  setUpUiSettings,
  setServerLocation,
  serachOrderBy,
  changeOrderDetail,
  getTableOrderList,
  getOrdersAssignedToUserProjects,
  sendOrderComment,
  getOrderComments,
  getOrderAssignedToUserSordetBy,
  observeOrder,
  getOrdersNames,
  getOrderLocks,
  creatOrderLock,
  removeOrderLock,
  extendOrderLock,
  searchOrderByID
};
