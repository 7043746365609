import React from 'react';
import usePopup from '../../../helper/usePopup';

const GlobalPopup = () => {
  const { popup, closePopup } = usePopup();

  return (
    <div
      className={`${popup.state === 'custom' ? 'bg-dark' : 'bg-blur'} ${popup.display ? 'active' : ''}`}
      onClick={closePopup}
    >
      <div onClick={(e) => e.stopPropagation()}>{popup.component}</div>
    </div>
  );
};

export default GlobalPopup;
