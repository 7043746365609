import React, { useState, useRef, useEffect, useContext } from 'react';
import { AddNewOrderContext } from '../../../pages/e-commerce/AddNewOrder';
import CalenderContainer from '../../calenderElements/CalenderContainer';
import { updateGLobalBoxFieldsData } from '../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData';
import { getActualBoxFieldState } from '../../../../functions/boxFieldsFunctions/getActualBoxFieldData';
import { getBoxFieldContent } from '../../../../functions/boxFieldsFunctions/getBoxFieldContent';
import { getUniqueFieldState } from '../../../../functions/boxFieldsFunctions/getActualBoxFieldData';

import usePopup from '../../../helper/usePopup';
import DeadlineError from '../Popup/AddOrder/DeadlineError';

const DateInputCalendar = ({ addNewOrder, boxFieldID, actualVariant, blockEdit, orderDeadlineFieldID, orderID }) => {
  const { boxFieldsData, setBoxFieldsData, headerBoxesData } = useContext(AddNewOrderContext);
  const [chosenDate, setChosenDate] = useState('');
  const [hour, setHoure] = useState('');
  const [minute, setMinute] = useState('');
  const [error, setError] = useState(false);
  const [displayCalender, setDisplayCalender] = useState(false);
  const { initPopup } = usePopup();

  const hourRef = useRef();
  const minuteRef = useRef();

  const isChosenDateHigherThanMain = () => {
    const mainDeadlineValue = getUniqueFieldState(headerBoxesData, orderDeadlineFieldID, orderID).value.content.split(
      ' '
    )[0];
    const mainDate = mainDeadlineValue.split('-');
    const formatedChosenDate = chosenDate.split('-');

    if (mainDeadlineValue === null || mainDeadlineValue === undefined) return false;

    return (
      new Date(parseInt(formatedChosenDate[2]), parseInt(formatedChosenDate[1]) - 1, parseInt(formatedChosenDate[0])) <=
      new Date(parseInt(mainDate[2]), parseInt(mainDate[1]) - 1, parseInt(mainDate[0]))
    );
  };

  const setUpDeadline = () => {
    if (chosenDate !== '') {
      if (isChosenDateHigherThanMain()) {
        setError(false);
        const copyInputData = {
          ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
          value: { content: chosenDate }
        };

        updateGLobalBoxFieldsData(copyInputData, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);

        setDisplayCalender(false);
      } else {
        initPopup(<DeadlineError />);
        setChosenDate('');
      }
    } else if (chosenDate === '') {
      setError(true);
    }
  };

  useEffect(() => {
    setUpDeadline();
  }, [chosenDate]);

  return (
    <div className="deadline-proposition">
      {
        <CalenderContainer
          addNewOrder={addNewOrder}
          blockEdit={blockEdit}
          calenderValue={getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant)}
          setUpDeadline={setUpDeadline}
          hour={hour}
          minute={minute}
          hourRef={hourRef}
          minuteRef={minuteRef}
          setChosenDate={setChosenDate}
          setHoure={setHoure}
          setMinute={setMinute}
          chosenDate={chosenDate}
          error={error}
          inputValidated={true}
          handleShadow={false}
          showTimeInput={false}
          displayCalender={displayCalender}
          setDisplayCalender={setDisplayCalender}
          comapreWithMainDeadline={true}
          autoDateSet={false}
        />
      }
    </div>
  );
};

export default DateInputCalendar;
