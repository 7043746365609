import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../../../assets/closse-icon.svg';
import usePopup from '../../../helper/usePopup';

const ShowVideoPopup = ({ url }) => {
  const [name, setName] = useState('');
  const { closePopup } = usePopup();
  function extractFileName(url) {
    const parts = url.split('/');
    const fileNameWithExtension = parts.pop() || '';
    setName(fileNameWithExtension.split('.').slice(0, -1).join('.'));
  }
  useEffect(() => {
    extractFileName(url);
  }, []);
  return (
    <div className="svg-popup" onClick={(e) => e.stopPropagation()}>
      <div className="close-container">
        <Close
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            closePopup();
          }}
        />
      </div>
      <figure>
        <img src={url} style={{ width: 200, height: 200, objectFit: 'cover' }}></img>
      </figure>
      <div className="title-container">
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ShowVideoPopup;
