/**
 * Method generateReformatsDisplayView() - used for generating reformats data structure
 * 
 * @param {Object} orderReformat - order reformat data
 * @returns Array of filtered reformat data structure 
 * 
 * example structure: [{ data_type: "additional_info", name: "Dodatkowa informacja", value: orderReformat.additional_info }]
 */

export const generateReformatsDisplayView = (orderReformat) => {
  let basicSizes = orderReformat?.basic_size_list ? JSON.parse(orderReformat.basic_size_list) : null;
  let customSizes = orderReformat?.custom_size_list ? JSON.parse(orderReformat.custom_size_list) : null;
  let reformatDataStructure = [];

  if (basicSizes || customSizes) {
    reformatDataStructure.push({ data_type: "size", name: "Rozmiar", value: generateReformatSizes(basicSizes, customSizes) });
  }
  if (orderReformat?.additional_info) {
    reformatDataStructure.push({ data_type: "additional_info", name: "Dodatkowa informacja", value: orderReformat.additional_info });
  }
  if (orderReformat?.own_file) {
    reformatDataStructure.push({ data_type: "own_file", name: "Plik", value: orderReformat.own_file });
  }
  if (orderReformat?.external_url) {
    reformatDataStructure.push({ data_type: "external_url", name: "Zwenętrzne źródło", value: orderReformat.external_url });
  }
  if (orderReformat?.brief) {
    reformatDataStructure.push({ data_type: "brief", name: "Brief", value: orderReformat.brief });
  }
  if (orderReformat?.correction) {
    reformatDataStructure.push({ data_type: "correction", name: "Korekta", value: orderReformat.correction });
  }

  return reformatDataStructure;
};

/**
 * generateReformatSizes() - helper for generateReformatsDisplayView() that generates correct sizes content
 * 
 * @param {Array} basicSizes - array with basic sizes from reformats
 * @param {Array} customSizes - array of custom sizes provided by user
 * @returns String with prepared sizes content
 */

export const generateReformatSizes = (basicSizes, customSizes) => {
  let result = (
    <div className="reformat-sizes">
      {basicSizes.map((sizeGroup, index) => {
        if (sizeGroup.chosen_sizes.length > 0) {
          return (
            <ul className="list">
              <li className="list-header">{sizeGroup.group_name}:</li>
              {sizeGroup.chosen_sizes.map((size) => {
                return <li>{size}</li>;
              })}
            </ul>
          );
        }
      })}
      {customSizes?.length > 0 && <ul className="list"><li className="list-header">inne: </li>{customSizes.map(size=> <li>{size}</li>)}</ul>}
    </div>
  );

  return result;
};


export default generateReformatsDisplayView