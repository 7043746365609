import React from 'react';

const AddCampaignAction = ({ handleAddButton, noSourceError }) => {
  return (
    <div className="actions">
      <button className="add" onClick={handleAddButton}>
        + Dodaj nową kampanię
      </button>
      {noSourceError && (
        <span className="error campaign">
          *Aby dodać kamnpanię <strong>musisz wybrać plik źródłowy</strong>
        </span>
      )}
    </div>
  );
};

export default AddCampaignAction;
