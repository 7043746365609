import React, { useRef, useEffect } from 'react';
import Loader from '../Loader/Loader';
import LOADER_TYPE from '../Loader/LOADER_TYPE';
//TODO: make that componen universal for all dropdowns in app

const Dropdown = ({
  data,
  handleListElement,
  handleCheckBox,
  displayCheckbox,
  positionToElementRef,
  listDescription,
  children,
  setShowDropdown,
  showDropdown,
  campaignValue,
  dropdownType,
  showCheckbox = true
}) => {
  const dropdownListRef = useRef(null);

  useEffect(() => {
    if (positionToElementRef) {
      positionDropdown();
      window.addEventListener('scroll', positionDropdown);
      document.addEventListener('click', handleWindowCLick);
    }
  }, [data, showDropdown]);

  const handleWindowCLick = (e) => {
    if (
      dropdownListRef.current &&
      e.target !== dropdownListRef.current &&
      e.target !== positionToElementRef?.current &&
      !dropdownListRef?.current.contains(e.target) &&
      !positionToElementRef?.current.contains(e.target)
    ) {
      if (setShowDropdown) setShowDropdown(false);
      document.removeEventListener('click', handleWindowCLick);
    }
  };
  useEffect(() => {
    positionDropdown();
  }, [dropdownType]);
  const positionDropdown = () => {
    if (positionToElementRef) {
      const relativeElement = positionToElementRef.current;
      const dropdown = dropdownListRef.current ? dropdownListRef.current : '';

      if (relativeElement && dropdown) {
        dropdown.style.position = 'absolute';
        dropdown.style.top = 'calc(100% + 4px)';
        dropdown.style.left = '0px';
        dropdown.style.zIndex = '99999999';
        dropdown.style.minWidth = '250px';
      }
    }
  };
  return (
    <div className="dropdown dropdown-list" ref={dropdownListRef} style={{ height: data ? 'auto' : `150px` }}>
      {listDescription && (
        <div className="list-search">
          <span>{listDescription}</span>
        </div>
      )}
      {children && children}
      {data ? (
        <ul className="dropdown-list__container">
          {data.map((el, index) => {
            return (
              <li className="dropdown-list__element" key={`dropdown-list-element-${index}`}>
                <label
                  htmlFor={`checkboxes-${index}-${el.displayName}`}
                  data-group-id={el?.groupId ? el.groupId : el.value}
                  data-name-content={el.displayName}
                >
                  <input
                    type="checkbox"
                    id={`checkboxes-${index}-${el.displayName}`}
                    onChange={handleListElement}
                    value={el.value}
                    checked={campaignValue === el.displayName}
                    {...(displayCheckbox ? { checked: handleCheckBox(el.value) } : {})}
                    data-name-content={el.displayName}
                    data-group-id={el?.groupId ? el.groupId : el.value}
                    style={{ display: showCheckbox ? 'block' : 'none' }}
                  />
                  {el.displayName}
                </label>
              </li>
            );
          })}
        </ul>
      ) : (
        <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
      )}
    </div>
  );
};

export default Dropdown;
