import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import orderServices from '../../../../../services/orderServices';
import usePopup from '../../../../../helper/usePopup';
import OrderUnderChanges from '../../../Popup/AddOrder/OrderUnderChanges';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const AddCorectionButton = ({ orderID, isReformat, rowData }) => {
  const { user } = useContext(UserContext);
  const { initPopup } = usePopup();
  const navigate = useNavigate();

  const checkIfLockIsOlder = () => {
    if (rowData.order_locks[0]) {
      const currentDate = new Date().getTime();
      const lockTime = new Date(rowData.order_locks[0].end_time).getTime();

      return currentDate > lockTime;
    }

    return false;
  };

  const handleCorrectionBtn = async (repeat) => {
    let link = `/addneworder/?edit_order=true&order=${orderID}&is_correction=true`;

    if (isReformat) {
      link = `/reformatting/?edit_order=true&order=${orderID}&reformat_id=${rowData.order_reformat.id}&is_correction=true`;
    }

    if (repeat > 0) {
      const res = await orderServices.creatOrderLock(orderID, 900);

      if ([400, 423, 401].includes(res.status)) {
        if (rowData.order_locks.length > 0) {
          if (checkIfLockIsOlder() || user.pk === rowData.order_locks[0].user) {
            //temporary solution, backend should do this in future
            await orderServices.removeOrderLock(rowData.order_locks[0].id);
            handleCorrectionBtn(repeat - 1);
          } else {
            initPopup(<OrderUnderChanges editInfo={rowData.order_locks.length > 0 && rowData.order_locks[0]} />);
          }
        } else {
          initPopup(<OrderUnderChanges editInfo={rowData.order_locks.length > 0 && rowData.order_locks[0]} />);
        }
      } else {
        const data = await res.json();
        navigate(link + `&lock_id=${data.id}`);
      }
    }
  };

  const handleButton = () => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('AddCorrectionButton action', sentryContext);
    handleCorrectionBtn(3);
  };
  useEffect(() => {
    console.log(shouldDisplayButton('add_correction', rowData?.order_status, user));
  }, []);
  return (
    <>
      {shouldDisplayButton('add_correction', rowData?.order_status, user) !== 'Brak' && (
        <button
          className={`add-corection ${shouldDisplayButton('add_correction', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
          onClick={handleButton}
        >
          Dodaj korektę
        </button>
      )}
    </>
  );
};

export default AddCorectionButton;
