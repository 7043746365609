import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundShadow from '../../../globalElements/BackgroundShadow';
import { ActiveCorrectionPopupPopupsContext } from '../collapseElements/CollapseRow';
import { MainAppContext } from '../../../../MainAppComponent';

const Correction = ({ banner, data, orderID }) => {
  const { setShadowActive } = useContext(MainAppContext);
  const { setActiveCorrectionPopup } = useContext(ActiveCorrectionPopupPopupsContext);

  const navigate = useNavigate();

  const handleEdit = () => {
    setActiveCorrectionPopup(false);
    setShadowActive(false);
    const link = `/addneworder/?edit_order=true&order=${orderID}&is_correction=true`;
    navigate(link);
  };

  return (
    <>
      <div className="correction-inputs-fields">
        <div className="inputs-fields">
          {data
            ? data.map((el, index) => {
                return (
                  <div className="input-box" onClick={handleEdit} key={`correction-${index}`}>
                    <label key={`input-box-${index}`}>
                      <span className="name">{el.name}</span>
                      {typeof el.value === 'object' ? (
                        <span>{el.value}</span>
                      ) : (
                        <span dangerouslySetInnerHTML={{ __html: el.value }}></span>
                      )}
                    </label>
                    <div className="hint">
                      <span>kliknij aby</span>
                      <span>edytowac</span>
                      <div className="arrow"></div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="banner">
          <figure>
            <img src={banner}></img>
          </figure>
        </div>
      </div>
      <BackgroundShadow setShow={setActiveCorrectionPopup} handleShadow={true} />
    </>
  );
};

export default Correction;
