import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';

const getLogotypList = async (logotypeName) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/coremedia/logotypes/?name=${logotypeName}`, requestOptions).then(
    (r) => {
      return handleResponse(r, requestOptions).then((r) => {
        return r.json();
      });
    }
  );
};

const getLogotypeImage = async (logotype_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/coremedia/${logotype_id}/logotype-core/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getLogotypeZip = async (logotype_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/coremedia/${logotype_id}/logotypes-download`, requestOptions).then(
    (r) => {
      return handleResponse(r, requestOptions).then((r) => {
        return r.json();
      });
    }
  );
};

export default { getLogotypList, getLogotypeImage, getLogotypeZip };
