/**
 * getCurrentHour() - method that return current hour
 *
 * @returns current hour
 */

const getCurrentHour = () => {
  const date = new Date();
  const time = date.getHours();

  return time;
};

export default getCurrentHour;
