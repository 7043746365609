import { getHiddenBoxesFieldsID } from '../boxFieldsFunctions/getHiddenBoxesFieldsID';

export const handleBoxStatus = (boxData, boxFieldsData, setBoxFieldsData, hiddenBoxes) => {
  let result = [];
  const fieldsID = getHiddenBoxesFieldsID(hiddenBoxes, boxData);

  result = boxFieldsData.map((value) => {
    if (fieldsID.includes(value.box_field)) {
      let updatedVariants = [];
      if (value.variants?.length > 0 && value.variants !== undefined) {
        updatedVariants = value.variants.map((el) => {
          return { ...el, is_hidden: true };
        });
        value.variants = [...updatedVariants];
      }
    } else {
      let oldVariants = [];
      if ('order' in value && value.order) {
        oldVariants = value.order.map((el) => {
          return { ...el, is_hidden: false };
        });
        value.order = [...oldVariants];
      } else {
        oldVariants = value.variants?.map((el) => {
          return { ...el, is_hidden: false };
        });
        value.variants = [...oldVariants];
      }
    }

    return value;
  });

  setBoxFieldsData(result);
};
