import React, { useContext } from 'react';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import downloadFileService from '../../../../../services/downloadFileService';
import { getFileBaseName } from '../../../../../../functions/getFileBaseName';
import handleDownload from '../../../../../../functions/handleDownload';
import usePopup from '../../../../../helper/usePopup';
import NewInfoPopup from '../../../Popup/AddOrder/NewInfoPopup';
import { sendSentryInfo, sendSentryError } from '../../../../../helper/sentryHelper';

const DownloadButton = ({ orderID, rowData }) => {
  const { user } = useContext(UserContext);
  const { initPopup } = usePopup();

  const sentryContext = {
    title: 'Action Details',
    payload: {
      orderId: orderID
    }
  };

  const handleDownloadButton = () => {
    downloadFileService.downloadFile(orderID).then((res) => {
      if (res.file) {
        const basename = getFileBaseName(res.file);
        handleDownload(basename, res.file);
        sendSentryInfo('DownloadButton action', sentryContext);
      } else {
        initPopup(<NewInfoPopup type={'negative'} info={'no_files_to_download'} />);
        sendSentryError('DownloadButton action', sentryContext);
      }
    });
  };

  return (
    <>
      {shouldDisplayButton('download', rowData?.order_status, user) !== 'Brak' && (
        <>
          <button
            className={`download ${shouldDisplayButton('download', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={handleDownloadButton}
          >
            Pobierz
          </button>
        </>
      )}
    </>
  );
};

export default DownloadButton;
