import React, { createContext, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import authorization from '../services/authorization';
import LoadingPage from '../elements/globalElements/Loader/LoadingPage';

export const UserContext = createContext(null);

const AuthWrap = (props) => {
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['api_key']);

  const [isCookie, setIsCookie] = useState(false);
  const [error, setError] = useState('');

  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    if (cookies && cookies.api_key) {
      let core_id = 2; //default
      switch (window.location.hostname) {
        case 'delta.app4ad.lcbox.pl':
        case 'obi.app4ad.pl':
          core_id = 5;
          break;
        case 'netto.app4ad.pl':
          core_id = 6;
          break;
      }
      authorization
        .getTokens(cookies.api_key, core_id)
        .then((r) => {
          if (r.refresh && r.access) {
            localStorage.setItem('tokens', JSON.stringify(r));

            authorization.getCurrentUserId().then((res) => setUser(res));
          } else {
            setError('Błąd ustawiania tokenów JWT');
          }
        })
        .catch((error) => {
          setError('Błąd ustawiania tokenów JWT');
        });
      setIsCookie(true);
    }
  }, [cookies]);

  return (
    <>
      {isCookie && user ? (
        <UserContext.Provider value={userValue}>{props.children}</UserContext.Provider>
      ) : (
        <LoadingPage />
      )}
      {error && <p>{error}</p>}
    </>
  );
};

export default AuthWrap;
