import React from 'react';
import checkPrefix from '../../../../../../functions/checkPrefix';
import checkIfSourceIsPDF from '../../../../../../functions/checkIfSourceIsPDF';
import pdfIcon from '../../../../../../assets/file-pdf.svg';

const Image = ({ source, imagePK, index, handleImage, modified }) => {
  return (
    <>
      <figure key={`image-${index}`} style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
        {checkIfSourceIsPDF(source) ? (
          <img
            src={pdfIcon}
            data-file={checkPrefix(source)}
            data-image-pk={imagePK}
            data-index={index}
            onClick={handleImage}
          ></img>
        ) : (
          <img
            src={`${checkPrefix(source)}?timestamp=${modified}`}
            data-image-pk={imagePK}
            data-index={index}
            onClick={handleImage}
          ></img>
        )}
      </figure>
    </>
  );
};

export default Image;
