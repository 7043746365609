import React, { useState, useEffect, useContext } from 'react';
import SectionHeader from './sectionsElements/SectionHeader';
import FormatList from './sectionsElements/FormatList';
import { ReformatContext } from '../../../pages/e-commerce/Reformatting';
import CustomSizeInputs from '../../globalElements/radioListComponents/CustomSizeInputs';

const Formats = ({ validate }) => {
  const [activeCustomSizes, setActiveCustomSizes] = useState(false);
  const { reformatData, setReformatData } = useContext(ReformatContext);

  useEffect(() => {
    setReformatData({
      ...reformatData,
      send_custom_sizes_list: activeCustomSizes
    });
  }, [activeCustomSizes]);

  const handleCustomSizeButton = (event, inputValue) => {
    const btnType = event.target.classList[0];
    const value = inputValue;

    if (value && value !== '' && btnType === 'add_btn') {
      if (reformatData.custom_sizes_list) {
        setReformatData({
          ...reformatData,
          custom_sizes_list: [...reformatData.custom_sizes_list, value]
        });
      } else {
        setReformatData({ ...reformatData, custom_sizes_list: [value] });
      }
    } else {
      const copySizesList = [...reformatData.custom_sizes_list];
      const removeValue = event.target.dataset.removeValue;
      const startIndex = copySizesList.indexOf(removeValue);
      copySizesList.splice(startIndex, 1);
      setReformatData({ ...reformatData, custom_sizes_list: copySizesList });
    }
  };

  return (
    <div className={validate ? 'formats' : 'formats not-validated'}>
      <div className="row">
        <div className="sizes">
          <SectionHeader headerType={'formats'} />
          <FormatList activeCustomSizes={activeCustomSizes} setActiveCustomSizes={setActiveCustomSizes} />
        </div>
        <div className="custom-sizes">
          <SectionHeader headerType={'unstandard'} />
          <ul className="custom-size-inputs">
            <CustomSizeInputs
              customSizeList={reformatData.custom_sizes_list}
              handleData={handleCustomSizeButton}
              reformatData={reformatData}
              setReformatData={setReformatData}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Formats;
