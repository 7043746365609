import TABLE_TYPES from '../../../../../../globalEnums/TABLE_TYPES_ENUMS';
import checkPermissions from '../../../../../../functions/checkPermissions';
import PERMISSIONS_ENUMS from '../../../../../../globalEnums/PERMISSIONS_ENUMS';

/**
 * generateTableElementsClass() - method that generates main table elements classes
 *
 * @param {String} elementType - table element type, it will be first class for that element
 * @param {String} tableType - table type
 * @param {Object} userData - current user data
 * @returns class list for table elements
 */

const generateTableElementsClass = (elementType, tableType, userData) => {
  let result = elementType;

  if (tableType === TABLE_TYPES.NEW || tableType === TABLE_TYPES.DRAFT) {
    result += 'col-3 standard ';
  } else if (
    tableType === TABLE_TYPES.NOTIFICATION &&
    !checkPermissions(userData, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])
  ) {
    result += 'col-6 standard ';
  } else if (tableType === TABLE_TYPES.FINISHED) {
    result += 'col-6 special ';
  } else if (tableType === TABLE_TYPES.IN_PROGRESS) {
    result += 'col-7 ';
  } else {
    result += 'col-5 standard ';
  }

  return result;
};

export default generateTableElementsClass;
