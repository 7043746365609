import React, { useContext, useRef, useState } from 'react';
import { TableDataContext } from '../../../../Router';
import { ReloadTableContext } from '../../../../Router';
import SearchBar from '../../../globalElements/SearchBar';
import DownloadDataSheet from '../../../globalElements/DownloadDataSheet';
import FilterBar from '../../../globalElements/Filters/FilterBar';
import { ORDER_DATA_PARAMETERS_ACTION_TYPE } from '../OrderDataParameterReducer/orderDataParametersReducer';
import { OrderDataParametersContext } from '../TableContextContainer';

const TableActions = ({ setLoadingTableData, handleAbort }) => {
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { setReloadComponent } = useContext(ReloadTableContext);
  const { orderDataParameters, setOrderDataParameters } = useContext(OrderDataParametersContext);
  const [searchBarValue, setSearchBarValue] = useState('');
  const serchBarInputRef = useRef();
  let postPerPageTimer;

  const handlePostPerPage = (e) => {
    setTableContext((prevState) => ({
      ...prevState,
      postPerPage: e.target.value
    }));

    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGE_SIZE,
      page_size: parseInt(e.target.value)
    });

    clearTimeout(postPerPageTimer);
    postPerPageTimer = setTimeout(() => {
      setReloadComponent(true);
    }, 300);
  };

  const handleSearchBar = (value) => {
    handleAbort();
    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_SEARCH_AND_PAGINATION,
      search: value,
      page: 1
    });

    // setOrderDataParameters({
    //   type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGINATION,
    //   page: 1
    // });
  };

  const handleFilterBar = (data) => {
    setTableContext({
      ...tableContext,
      tableData: data.results,
      staticTableData: data.results,
      total_page: data.total_page
    });
    setLoadingTableData(false);
  };

  const setExternalEndpoint = (filterEndpoint) => {
    handleAbort();
    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_EXTERNAL_ENDPOINT,
      external_endpoint: filterEndpoint
    });
  };

  return (
    <div className="actions">
      <div className="show-number">
        <span>Pokaż</span>
        <input
          placeholder="all"
          value={orderDataParameters.page_size}
          onChange={handlePostPerPage}
          ref={serchBarInputRef}
        ></input>
        <span>wpisów</span>
      </div>
      <FilterBar
        showPerAccount={true}
        showStatus={true}
        showDeadline={true}
        filterType={tableContext.tableType}
        handleFilteredData={handleFilterBar}
        setDataLoader={setLoadingTableData}
        setExternalEndpoint={setExternalEndpoint}
        currentPage={orderDataParameters.page}
        postPerPage={tableContext.postPerPage}
        search={searchBarValue}
        onValueChange={[searchBarValue]}
      />
      <SearchBar
        handleSerchBar={handleSearchBar}
        searchValue={orderDataParameters.search}
        setSearchValue={setSearchBarValue}
        handleAbort={handleAbort}
      />
      <DownloadDataSheet sheetType={tableContext.tableType} />
    </div>
  );
};

export default TableActions;
