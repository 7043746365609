/**
 * checkIfDateIsLessThan() - compare dates and check if date1 is less than date2
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns boolean (true or false)
 */
const checkIfDateIsLessThan = (date1, date2) => {
  const date1_parts = date1.split('-');
  const date2_parts = date2.split('-');

  const date1_parse = new Date(
    parseInt(date1_parts[2]), // Rok
    parseInt(date1_parts[1]) - 1, // Miesiąc (0 - 11)
    parseInt(date1_parts[0]) // Dzień
  );

  const date2_parse = new Date(
    parseInt(date2_parts[2]), // Rok
    parseInt(date2_parts[1]) - 1, // Miesiąc (0 - 11)
    parseInt(date2_parts[0]) // Dzień
  );

  return date1_parse < date2_parse;
};

export default checkIfDateIsLessThan;
