import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Icon } from '../../../../../assets/popups/correct.svg';
import imageServices from '../../../../services/imageServices';
import orderServices from '../../../../services/orderServices';
import { ReloadTableContext } from '../../../../Router';
import IMAGE_STATUS from '../../../../../globalEnums/IMAGE_STATUS';
import PageComponent from '../../Team/globalelements/PageComponent';
import { getFileBaseName } from '../../../../../functions/getFileBaseName';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../helper/usePopup';
import NewInfoPopup from '../../Popup/AddOrder/NewInfoPopup';
import { sendSentryInfo } from '../../../../helper/sentryHelper';
import { getFileExtension, getExtensionIcon } from '../../../../../functions/fileExtensions';

const AcceptProjectsModal = ({ orderID, variantsData }) => {
  const [imageList, setImageList] = useState([]);
  const [orderImages, setOrderImages] = useState([]);
  const [acceptedImages, setAcceptedImages] = useState([]);
  const [error, setError] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [dataLoader, setDataLoader] = useState(false);
  const [orderVariantsList, setOrderVariantsList] = useState([]);
  const [page, setPage] = useState(0);
  const { initPopup, closePopup } = usePopup();
  const sortImagesArray = (imagesArray) => {
    imagesArray.sort((a, b) => {
      // Porównaj wartości variant w obiektach a i b
      const variantA = a.variant;
      const variantB = b.variant;

      if (variantA < variantB) {
        return -1;
      } else if (variantA > variantB) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    if (orderID) {
      imageServices
        .getImages(orderID)
        .then((res) => {
          const variantsList = variantsData.map((variant) => variant.id);

          const imagesArray = [];
          const acceptDataStructure = [];

          sortImagesArray(res);

          variantsList.forEach((variantIdHelper, index) => {
            imagesArray.push({
              variant: variantIdHelper,
              images: [],
              variantNumber: index
            });

            acceptDataStructure.push({
              variant: variantIdHelper,
              image_pk: null
            });

            for (let i = 0; i < res.length; i++) {
              if (res[i].image && res[i].status !== 'cloned') {
                if (res[i].variant == variantIdHelper) {
                  imagesArray[index].images.push({
                    image_pk: res[i].pk,
                    src: res[i].image
                  });
                }
              }
            }
          });

          setImageList(imagesArray);
          setAcceptedImages(acceptDataStructure);
          setOrderImages(res);
          setOrderVariantsList(variantsList);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<NewInfoPopup type={'negative'} info={'accept_button'} />);
        });

      orderServices.getOrderDetails(orderID).then((res) => {
        setOrderDetails(res);
      });
    }
  }, []);

  const handleBackBtn = () => {
    closePopup();
  };

  const handleOrderDetails = (body) => {
    if (orderID) {
      orderServices
        .changeOrderDetail(orderID, body)
        .then((res) => {
          setDataLoader(false);
          closePopup();
          setReloadComponent(true);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<NewInfoPopup type={'negative'} info={'accept_button'} />);
        });
    }
  };

  const handleChangeOrderStatus = () => {
    let status = '';

    if (orderDetails?.formats_count == 1 || orderDetails?.has_first_acceptation) {
      status = 'finished';
    } else {
      status = 'renew';
    }

    if (status) {
      if (!orderDetails?.has_first_acceptation) {
        const body = {
          has_first_acceptation: true,
          status: status,
          assigned_to: null
        };
        handleOrderDetails(body);
      } else {
        const body = { status: status };
        handleOrderDetails(body);
      }
    }
  };

  const handleImagesAcceptation = async (index, maxRequestNumber, acceptedImagesList) => {
    if (index < maxRequestNumber) {
      try {
        if (orderImages[index].status != IMAGE_STATUS.CLONED) {
          //we dont want to interact with cloned images
          await imageServices.changeImageStatus(
            orderImages[index].pk,
            acceptedImagesList.includes(orderImages[index].pk) ? IMAGE_STATUS.ACCEPTED : IMAGE_STATUS.REJECTED
          );
        }

        handleImagesAcceptation(index + 1, maxRequestNumber, acceptedImagesList);
      } catch (error) {
        console.error(error);
        initPopup(<NewInfoPopup type={'negative'} info={'accept_button'} />);
      }
    } else {
      handleChangeOrderStatus();
    }
  };

  const handleAcceptBtn = (e) => {
    const acceptedImagesList = [];

    acceptedImages.forEach((el) => {
      if (el.image_pk) acceptedImagesList.push(parseInt(el.image_pk));
    });

    if (acceptedImagesList.length > 0) {
      setDataLoader(true);
      handleImagesAcceptation(0, orderImages.length, acceptedImagesList);
    } else {
      setError('musisz wybrać zdjęcie');
    }

    sendSentryInfo('AcceptProjectModal action', {
      title: 'Details',
      payload: { orderId: orderID }
    });
  };

  const selectBanner = (e) => {
    const imagePk = e.target.value;
    const variantID = e.target.dataset.variant;
    const acceptedImagesCopy = [...acceptedImages];

    for (let i = 0; i < acceptedImagesCopy.length; i++) {
      if (acceptedImagesCopy[i].variant == variantID) {
        acceptedImagesCopy[i].image_pk = imagePk;
        break;
      }
    }

    setAcceptedImages(acceptedImagesCopy);
  };

  const handleCheckbox = (variantID, imagePk) => {
    for (let i = 0; i < acceptedImages.length; i++) {
      if (acceptedImages[i].variant == variantID && acceptedImages[i].image_pk == imagePk) return true;
    }

    return false;
  };

  return (
    <>
      <div className="accept-project-popup">
        <Icon />
        <h3>Banery do AKCEPTACJI</h3>
        <div className="middle-part-popup">
          {orderVariantsList.length ? (
            <PageComponent
              page={page}
              setPage={setPage}
              data={orderVariantsList}
              showContent={true}
              centerVariantsContainer={true}
            >
              {imageList[page] && imageList[page].images.length > 0 ? (
                imageList[page].images.map((image, imageIndex) => {
                  return (
                    <div className="input-box" key={`image-${imageIndex}`}>
                      <label className="custom-radio">
                        <input
                          checked={handleCheckbox(orderVariantsList[page], image.image_pk)}
                          type="checkbox"
                          value={image.image_pk}
                          data-variant={orderVariantsList[page]}
                          onChange={selectBanner}
                        ></input>
                        <span className="custom-radio-checkmark"></span>
                        <div>
                          <figure className="input-banner">
                            {['mp4', 'avi', 'svg'].includes(getFileExtension(image.src)) ? (
                              getExtensionIcon(getFileExtension(image.src))
                            ) : (
                              <img src={image.src} alt={getFileBaseName(image.src)}></img>
                            )}
                          </figure>
                          <span>{getFileBaseName(image.src)}</span>
                        </div>
                      </label>
                    </div>
                  );
                })
              ) : (
                <span>Brak grafik dla tego wariantu</span>
              )}
            </PageComponent>
          ) : (
            <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />
          )}
        </div>
        {error && <span className={`error`}>*musisz wybrać zdjęcie</span>}
        <p>Tej operacji nie można cofnąć.</p>
        <div className="buttons centered-buttons">
          <button className="button cancel" onClick={handleBackBtn}>
            Anuluj
          </button>
          <button className="button accept" onClick={handleAcceptBtn}>
            Akceptuj
          </button>
        </div>

        {dataLoader && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
      </div>
    </>
  );
};

export default AcceptProjectsModal;
