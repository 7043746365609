import React, { useState, useRef, useContext } from 'react';
import { MainAppContext } from '../../../MainAppComponent';
import { ReformatContext } from '../../../pages/e-commerce/Reformatting';
import CalenderContainer from '../../calenderElements/CalenderContainer';

const ReformatingCalenderDeadline = ({ data, setData, boxID, validate, sourceType }) => {
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [noSourceError, setNoSourcerError] = useState(false);
  const [chosenDate, setChosenDate] = useState('');
  const [hour, setHoure] = useState('');
  const [minute, setMinute] = useState('');
  const { setShadowActive } = useContext(MainAppContext);
  const [error, setError] = useState(false);

  const [displayCalender, setDisplayCalender] = useState(false);

  const calendarRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  const setUpDeadline = () => {
    if (sourceType) {
      setNoSourcerError(false);
      if (chosenDate !== '') {
        setError(false);

        setShadowActive(false);
        setDisplayCalender(false);
        const deadline = `${chosenDate} ${hour !== '' ? hour : '17'}:${minute !== '' ? minute : '00'}`;
        setReformatData((prevState) => {
          return { ...prevState, deadline: deadline };
        });

        for (let i = 0; i < data.length; i++) {
          if (data[i].box_field === boxID) {
            data[i].order[0].value.content = deadline;
            break;
          }
        }

        setData(data);
      } else {
        setError(true);
      }
    } else {
      setNoSourcerError(true);
    }
  };

  return (
    <CalenderContainer
      calenderValue={reformatData.deadline || ''}
      calendarRef={calendarRef}
      setUpDeadline={setUpDeadline}
      hour={hour}
      minute={minute}
      hourRef={hourRef}
      minuteRef={minuteRef}
      error={error}
      noSourceError={noSourceError}
      setHoure={setHoure}
      setMinute={setMinute}
      chosenDate={chosenDate}
      setChosenDate={setChosenDate}
      inputValidated={validate}
      handleShadow={true}
      showTimeInput={true}
      displayCalender={displayCalender}
      setDisplayCalender={setDisplayCalender}
      autoDateSet={true}
    />
  );
};

export default ReformatingCalenderDeadline;
