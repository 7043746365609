import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserContext } from '../../wrapper/AuthWrap';
import PERMISSIONS_ENUMS from '../../../globalEnums/PERMISSIONS_ENUMS';
import AGENTION_NOTES_ENUMS from '../../elements/e-commerce/Table/collapseElements/ENUMS/AGENTION_NOTES_ENUMS';
import EXTERNAL_LINKS from '../../elements/e-commerce/ENUMS/EXTERNAL_LINKS';
import getBoxFields from '../../services/getBoxFields';
import usePopup from '../../helper/usePopup';
import { useParams } from 'react-router-dom';
import orderServices from '../../services/orderServices';
import variantsServices from '../../services/variantsServices';
import correctionServices from '../../services/correctionServices';
import imageServices from '../../services/imageServices';
import TableDataList from '../../elements/e-commerce/Table/TableDataList';
import generateDisplayData from '../../../functions/generateDisplayData';
import generateReformatsDisplayView from '../../../functions/reformatsFunctions/generateReformatsDisplayView';
import checkPermissions from '../../../functions/checkPermissions';
import AgentionNotes from '../../elements/e-commerce/Table/collapseElements/AgentionNote';
import CollapseBanner from '../../elements/e-commerce/Table/collapseElements/CollapseBanner';
import logo from '../../../assets/banner1.png';
import ExternalLink from '../../elements/e-commerce/ExternalLink';
import AuthorInfo from '../../elements/e-commerce/Table/collapseElements/AuthorInfo';
import CollapseActions from '../../elements/e-commerce/Table/collapseElements/CollapseActions';
import OrderEditInfo from '../../elements/e-commerce/Table/collapseElements/OrderEditInfo';
import AcceptProjectsModal from '../../elements/e-commerce/Table/InProgressPopups/AcceptProjectsModal';
import {
  ActiveAcceptPopupContext,
  ActiveCorrectionPopupPopupsContext
} from '../../elements/e-commerce/Table/collapseElements/CollapseRow';
import checkIfLockIsOlder from '../../../functions/checkIfLockIsOlder';
import CorrectionList from '../../elements/e-commerce/Table/collapseElements/CorrectionList';

const externalLinksComponentTypes = [
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.ACTION_URL,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE
    ]
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION
    ]
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION
    ]
  },
  {
    link_type: EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES,
    validate: false,
    permisions: [
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_DTP,
      PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_CREATION
    ]
  }
];

const agentionNotesTypes = [
  { note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.MANAGE_BY_NOTE, button: true },
  { note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.ASSIGNED_TO_NOTE, button: true }
];

const Order = () => {
  // const { tableContext, setTableContext } = useContext(TableDataContext);
  const { user } = useContext(UserContext);
  const params = useParams();
  const dataListRef = useRef();
  const [activeCorrectionPopup, setActiveCorrectionPopup] = useState(false);
  const [activeAcceptPopup, setActiveAcceptPopup] = useState(false);
  const activeAcceptPopupValue = useMemo(
    () => ({ activeAcceptPopup, setActiveAcceptPopup }),
    [activeAcceptPopup, setActiveAcceptPopup]
  );
  const activeCorrectionPopupValue = useMemo(
    () => ({ activeCorrectionPopup, setActiveCorrectionPopup }),
    [activeCorrectionPopup, setActiveCorrectionPopup]
  );
  const rowWraperRef = useRef();
  const [data, setData] = useState([]);
  const [graphicInfo, setGraphicInfo] = useState([]);
  const [variants, setVariants] = useState([]);
  const [variantsBoxField, setVariantsBoxField] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [reformatData, setReformatData] = useState([]);
  const [authorInfo, setAuthorInfo] = useState([]);
  const [mainBannerFromClone, setMainBannerFromClone] = useState(null);
  useEffect(() => {
    orderServices.searchOrderByID(params.id).then((res) => {
      const temp = res.results[0];
      temp.order_status = temp.status;
      setData(temp);
    });
    variantsServices.getVariants(params.id).then((res) => setVariants(res));
    getBoxFields.getVariantBoxFieldValue(params.id).then((res) => setVariantsBoxField(res));
  }, [params.id]);

  const generateAuthorData = () => {
    const chosenVariantData = variants[0];
    const authorInfoData = [];

    if (chosenVariantData?.creation_last_contributor)
      authorInfoData.push({
        department: 'Kreacja',
        name: chosenVariantData.creation_last_contributor
      });
    if (chosenVariantData?.dtp_last_contributor)
      authorInfoData.push({
        department: 'DTP',
        name: chosenVariantData.dtp_last_contributor
      });

    setAuthorInfo(authorInfoData);
  };

  const displayOrderEditInfo = () => {
    if (data.order_locks?.length > 0) {
      return !checkIfLockIsOlder(data.order_locks[0]);
    }

    return false;
  };
  useEffect(() => {
    setDisplayData(generateDisplayData(variantsBoxField, 0, false, data.custom_size_list));
    generateAuthorData();
  }, [data, variantsBoxField]);
  useEffect(() => {
    if (data?.is_reformat) {
      const reformatDataStructure = generateReformatsDisplayView(data.order_reformat);

      if (reformatDataStructure.length) setReformatData(reformatDataStructure);
    }
  }, []);
  return (
    <div className="order-details">
      <div className="row">
        <div className={`col`} ref={dataListRef}>
          <TableDataList
            displayData={displayData}
            reformatData={reformatData}
            mainBannerFromClone={mainBannerFromClone}
            orderDisplay
            status={data?.status_label}
            type={data?.size_group}
            client={data?.client_managed_by}
            agency={data?.managed_by}
            setMainBannerFromClone={setMainBannerFromClone}
            dataListRef={dataListRef}
          />
          {agentionNotesTypes.map((type, index) => {
            return (
              <AgentionNotes
                key={`agention-note-${index}`}
                isReformat={data?.is_reformat || false}
                orderID={params.id}
                variantsData={variants}
                showVariant={0}
                reformatData={data[type.note_type] || ''}
                noteType={type.note_type}
                viewType={AGENTION_NOTES_ENUMS.VIEW_TYPE.TABLE}
                saveButton={type.button}
              />
            );
          })}
        </div>
        <div className="col banner-action">
          <ActiveAcceptPopupContext.Provider value={activeAcceptPopupValue}>
            <ActiveCorrectionPopupPopupsContext.Provider value={activeCorrectionPopupValue}>
              {variants.length > 0 && (
                <>
                  <CollapseBanner
                    variantID={variants[0].id}
                    rowWraperRef={rowWraperRef}
                    logo={logo}
                    orderID={params.id}
                    data={displayData}
                    setDisplayData={setDisplayData}
                    fetchedData={variantsBoxField}
                    rowData={data}
                    isReformat={data?.is_reformat || false}
                    setMainBannerFromClone={setMainBannerFromClone}
                    graphicInfo={graphicInfo}
                    setGraphicInfo={setGraphicInfo}
                  />
                  {checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.EXTERNAL_LINKS) && (
                    <div className="links-view external-links">
                      <div className="text">
                        <h4>Link</h4>
                        <span> — podaj źródło w odpowiedniej sekcji i zatwierdź zmiany przyciskiem zapisz</span>
                      </div>
                      {externalLinksComponentTypes.map((link, index) => {
                        return (
                          <ExternalLink
                            orderID={params.id}
                            linkType={link.link_type}
                            variantsData={variants}
                            showVariant={0}
                            isReformat={data?.is_reformat || false}
                            orderData={data}
                            key={`external-link-key-${index}`}
                            isReadonly={!checkPermissions(user, link.permisions)}
                            saveButton={true}
                          />
                        );
                      })}
                    </div>
                  )}

                  <AuthorInfo authorsList={authorInfo} />
                  <CollapseActions
                    orderID={params.id}
                    data={displayData}
                    rowData={data}
                    variantID={variants[0].id}
                    status={data?.status_table}
                  />
                  {displayOrderEditInfo() && <OrderEditInfo editInfo={data.order_locks[0]} rowPosition={'start'} />}
                  {activeAcceptPopup ? (
                    <AcceptProjectsModal rowWraperRef={rowWraperRef} orderID={params.id} variantsData={variants} />
                  ) : null}
                </>
              )}
            </ActiveCorrectionPopupPopupsContext.Provider>
          </ActiveAcceptPopupContext.Provider>
        </div>
      </div>
      <div className="row">
        {variants.length > 0 && (
          <CorrectionList variantID={variants[0].id} page={0} data={variantsBoxField} orderID={params.id} />
        )}
      </div>
    </div>
  );
};

export default Order;
