import React, { useState } from 'react';
import { createContext } from 'react';

export const TeamContext = createContext();

const TeamProvider = ({ children }) => {
  const [state, setState] = useState();
  const [currentWorkProject, setCurrentWorkProject] = useState([]);

  return (
    <TeamContext.Provider value={{ state, setState, currentWorkProject, setCurrentWorkProject }}>
      <div>{children}</div>
    </TeamContext.Provider>
  );
};

export default TeamProvider;
