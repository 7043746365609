import React from 'react';
import { ReactComponent as Delete } from '../../../../../assets/popups/error.svg';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
import orderServices from '../../../../services/orderServices';
const ReformatCancelProceeding = ({ setRemoveOrderID, removeOrderID, orderID, removeReformat, alloweRemoveOrder }) => {
  const navigate = useNavigate();
  const { closeOrderPopup } = usePopup();
  const handleFirstButton = () => {
    if (removeReformat) {
      removeReformat();
    } else if (alloweRemoveOrder) {
      orderServices.changeOrderStatus(orderID, 'deleted');
    }
    if (setRemoveOrderID && removeOrderID) {
      const newData = {
        ...removeOrderID,
        noDisplayOrder: [...removeOrderID.noDisplayOrder, orderID]
      };
      setRemoveOrderID(newData);
    }
    closeOrderPopup();
    navigate('/');
  };
  const handleSecondButton = () => {
    closeOrderPopup();
  };
  return (
    <div className="abort-popup">
      <div className="upper-part-popup">
        <Delete />
        <h3>
          Przerwij realizację
          <br /> zlecenia.
        </h3>
        <p>Tej operacji nie można cofnąć.</p>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={handleSecondButton}>
          Anuluj
        </button>
        <button className="button abort" onClick={handleFirstButton}>
          Przerwij
        </button>
      </div>
    </div>
  );
};

export default ReformatCancelProceeding;
