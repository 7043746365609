const TABLE_TYPES = {
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
  DRAFT: 'draft',
  NOTIFICATION: 'notification',
  NEW: 'new',
  ORDER_DETAIL: 'order_detail'
};

export default TABLE_TYPES;
