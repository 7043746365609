import React from 'react';
import addFileIMG from '../../../../../assets/table_collapse/cloud_icon.svg';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';

const AddFileWrapper = ({ handleInputFileChange, loading, uploadFilesList, isDisabled, width, height }) => {
  return (
    <div className="file-content" style={{ width: `${width}px`, height: `${height}px` }}>
      <div className={'add-file-wrapper'} style={{ width: `${width}px`, height: `${height}px` }}>
        <input
          type="file"
          name="file"
          className="file-wrapper-input"
          multiple="multiple"
          onChange={(e) => handleInputFileChange(e)}
          disabled={isDisabled}
        />
        {loading ? (
          <Loader type={LOADER_TYPE.ACTION} startAnimation={true} />
        ) : (
          <img className="add-file-img" src={addFileIMG} />
        )}
        <p className="file-upload-label">Upuść plik tutaj lub kliknij, aby go wybrać</p>
        {uploadFilesList?.length > 0 && (
          <span className="files-counter">Pliki oczekujące do wysłania: {uploadFilesList.length}</span>
        )}
      </div>
    </div>
  );
};

export default AddFileWrapper;
