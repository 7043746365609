import React, { useContext, useState, useEffect } from 'react';
import { TableDataContext } from '../../../../Router';
import { useNavigate } from 'react-router-dom';
import getBoxFields from '../../../../services/getBoxFields';
import PageComponent from '../../Team/globalelements/PageComponent';
import CollapseRow from '../collapseElements/CollapseRow';
import CorrectionList from '../collapseElements/CorrectionList';
import notificationServices from '../../../../services/notificationServices';
import variantsServices from '../../../../services/variantsServices';
import usePopup from '../../../../helper/usePopup';
import ErrorLoadingData from '../../Popup/AddOrder/ErrorLoadingData';
import { OrderDataParametersContext } from '../TableContextContainer';
import { ORDER_DATA_PARAMETERS_ACTION_TYPE } from '../OrderDataParameterReducer/orderDataParametersReducer';

const Collapse = ({
  collapseRef,
  orderID,
  rowWraperRef,
  reformatSizes,
  rowData,
  orderReformat,
  celData,
  showCollapse
}) => {
  const { tableContext, paginationPage } = useContext(TableDataContext);
  const { orderDataParameters, setOrderDataParameters } = useContext(OrderDataParametersContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [variantID, setVariantID] = useState(null);

  const [loading, setLoading] = useState(true);
  const [collapseData, setCollapseData] = useState(null);
  const [variantsData, setVariantsData] = useState([]);
  const [updateVariantData, setUpdateVariantsData] = useState(false);

  const { initPopup } = usePopup();
  const findVariantsData = () => {
    variantsServices
      .getVariants(orderID)
      .then((r) => {
        setVariantsData(r.filter((variant) => variant.is_saved));
        if (r && r[0]?.id) {
          handleOrderAndVariant(r[0].id, 0);
        }
      })
      .catch((error) => {
        console.error(error);
        initPopup(<ErrorLoadingData orderID={orderID} error={error} componentName={'Collapse'} />);
      });
  };

  useEffect(() => {
    findVariantsData();
  }, [orderID]); //updateVariantData

  useEffect(() => {
    initCollapseData();
  }, [orderID]);

  const initCollapseData = () => {
    setLoading(true);
    if (tableContext.tableType === 'notification') {
      notificationServices
        .getNotificationHistory(orderID)
        .then((res) => {
          setLoading(false);
          setCollapseData(res);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<ErrorLoadingData orderID={orderID} error={error} componentName={'Collapse'} />);
        });
    } else {
      getBoxFields
        .getVariantBoxFieldValue(orderID)
        .then((res) => {
          setLoading(false);
          setCollapseData(res);
        })
        .catch((error) => {
          console.error(error);
          initPopup(<ErrorLoadingData orderID={orderID} error={error} componentName={'Collapse'} />);
        });
    }
  };

  useEffect(() => {
    if (!loading) {
      collapseRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [loading]);

  const getCorrectionDetails = (correction_pk, correction_master) => {
    const searchParams = new URLSearchParams({
      order: orderDataParameters.order_id,
      variant_id: variantID,
      correction_pk: correction_pk,
      page: orderDataParameters.variant_index, //page
      pagination: orderDataParameters.page || 1,
      correction_master: correction_master,
      prevPage: window.location.pathname.replace('/', '')
    }).toString();

    navigate(`/compare-variants?${searchParams}`, {
      state: { prevPage: window.location.pathname }
    });
  };

  const handleOrderAndVariant = (variantID, page) => {
    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.ORDER_AND_VARIANT,
      order_id: orderID,
      variant_id: variantID,
      variant_index: page
    });
    setVariantID(variantID);
  };

  useEffect(() => {
    handleOrderAndVariant(variantID, page);
  }, [updateVariantData]);

  const handleSetVariantID = (id) => {
    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_VARIANT_ID,
      variant_id: id
    });
    setVariantID(id);
  };

  const handleSetShowVariantIndex = (index) => {
    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_SHOW_VARIANT_INDEX,
      variant_index: index
    });
    setPage(index);
  };

  if (loading) {
    return (
      <div className="collapse" ref={collapseRef}>
        <div className="row">
          <p className="loading-text">Loading</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`collapse ${showCollapse ? 'open' : ''}`}
        ref={collapseRef}
        onClick={() => setUpdateVariantsData(!updateVariantData)}
      >
        {tableContext.tableType === 'notification' ? (
          <CollapseRow orderID={orderID} data={collapseData} showVariant={page} celData={celData} />
        ) : (
          <PageComponent
            page={page}
            orderID={orderID}
            setPage={handleSetShowVariantIndex}
            data={variantsData}
            setVariantID={handleSetVariantID}
            showContent={true}
          >
            <CollapseRow
              variantID={variantID}
              orderReformat={orderReformat}
              reformatSizes={reformatSizes}
              rowWraperRef={rowWraperRef}
              orderID={orderID}
              data={collapseData}
              showVariant={page}
              rowData={rowData}
              variantsData={variantsData}
              updateVariantData={updateVariantData}
              setUpdateVariantsData={setUpdateVariantsData}
            />
            {tableContext.tableType === 'in_progress' && (
              <CorrectionList
                getCorrectionDetails={getCorrectionDetails}
                variantID={variantID}
                showVariant={page}
                data={collapseData}
                orderID={orderID}
                rowData={rowData}
                page={page}
                paginationPage={paginationPage}
              />
            )}
          </PageComponent>
        )}
      </div>
    </>
  );
};

export default Collapse;
