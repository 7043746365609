import getCurrentDate from '../getCurrentDate';
import getCurrentHour from '../getCurrentHour';

let timeOut;

/**
 * handleCalenderInputs() - method that handle display time format in calender inputs,
 * that method handle 24h format, set time and disallow to chose time older than current
 *
 * @param {*} element - handle node element
 * @param {Integer} value - passed value
 * @param {*} setChosenDateError - state to set errors
 * @param {*} setHoure - state that set chosen houre
 * @param {*} setMinute - state that set chosen minute
 * @param {*} chosenDate - chosen date
 * @param {*} minuteRef - reference to node element that get minutes values
 * @param {*} hourRef - reference to node element that get hour values
 */

const handleCalenderInputs = (hour, minute, setChosenDateError, setHoure, setMinute, chosenDate) => {
  const currentDate = getCurrentDate();
  const currentTime = getCurrentHour();
  setHoure(`${hour < 10 ? '0' + hour : hour}`);
  clearTimeout(timeOut);
  if (currentDate == chosenDate && hour !== '') {
    setChosenDateError(true);
    timeOut = setTimeout(() => {
      if (hour < currentTime) {
        setHoure(`${hour + 1 < 10 ? '0' + hour + 1 : hour + 1}`);
      }
      clearTimeout(timeOut);
    }, 300);
  }
  if (minute > 59) minute = 59;
  if (minute < 0) minute = 0;
  setMinute(`${minute < 10 ? '0' + minute : minute}`);
};

export default handleCalenderInputs;
