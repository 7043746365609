import ENUMS from '../elements/e-commerce/Team/ENUMS';
import orderServices from '../services/orderServices';

/**
 * teamBoxGetData() - method to get and set data for TeamBoxACC.js
 *
 * @param {Object} selectBoxData - selected data of team
 * @param {Function} setTeamOrderBoxLoading - useState for data loader
 * @param {Array} columns - columns data state
 * @param {Function} setColumns - set columns data for usestate
 * @param {String} search - search parameter
 */

const teamBoxGetData = (selectBoxData, setTeamOrderBoxLoading, columns, setColumns, search, externalEndoint) => {
  if (selectBoxData.id) {
    setTeamOrderBoxLoading(true);
    if (selectBoxData.role == ENUMS.ROLE.DTP || selectBoxData.role == ENUMS.ROLE.KREACJA) {
      orderServices.getOrdersAssignedToUserProjects(selectBoxData.id, search, externalEndoint).then((r) => {
        setColumns({
          ...columns,
          assignedToPerson: {
            ...columns.assignedToPerson,
            items: r
          }
        });
        setTeamOrderBoxLoading(false);
      });
    } else {
      orderServices.getOrdersAssignedToCertainUser(selectBoxData.id, search, externalEndoint).then((r) => {
        setColumns({
          ...columns,
          assignedToPerson: {
            ...columns.assignedToPerson,
            items: r
          }
        });
        setTeamOrderBoxLoading(false);
      });
    }
  }
};

export default teamBoxGetData;
