import React, { useState, useRef, useContext } from 'react';
import { AddNewOrderContext } from '../../../../pages/e-commerce/AddNewOrder';
import { getBoxFieldContent } from '../../../../../functions/boxFieldsFunctions/getBoxFieldContent';
import { choseDefaultNameLanguage } from '../../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage';
import { DROPDOWN_TYPE } from './ENUMS/DROPDOWN_ENUM';
import checkIfContentIncludeID from '../../../../../functions/boxFieldsFunctions/checkIfContentIncludeID';
import OrderBoxDropdownContainer from './OrderBoxDropdownContainer';
const OrderBoxDropdown = ({ value, description, boxFieldID, actualVariant }) => {
  const { boxSettings } = useContext(AddNewOrderContext);
  const { boxFieldsData } = useContext(AddNewOrderContext);

  const dropdownRef = useRef();

  const [dropdownSetting, setDropdownSetting] = useState(false);

  const handleDropdown = (e) => {
    if (e.target === e.currentTarget) {
      e.stopPropagation();
      setDropdownSetting(!dropdownSetting);
    }
  };

  const handleCheckboxCheck = (id) => {
    const content = getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant);
    return checkIfContentIncludeID(id, content);
  };

  return (
    <>
      <div className={'content dropdown-box'}>
        <span>{description || ''}</span>
        {value.field_type === DROPDOWN_TYPE.DROPDOWN || value.field_type === DROPDOWN_TYPE.SINGLE_DROPDOWN ? (
          <>
            <div
              className={`custom-input ${dropdownSetting ? 'focus' : null}`}
              onClick={handleDropdown}
              ref={dropdownRef}
            >
              {value.box_field_dictionary
                ? value.box_field_dictionary.map((el, index) => {
                    if (handleCheckboxCheck(el.id)) {
                      return (
                        <span className={'chosen-element'} key={`dropdown-element-${index}`}>
                          {boxSettings.language !== 'PL'
                            ? choseDefaultNameLanguage(el, boxSettings.language, 'name')
                            : el.default_name}
                        </span>
                      );
                    }
                  })
                : null}
              {value.field_type === DROPDOWN_TYPE.DROPDOWN &&
                getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant)?.length === 0 && (
                  <span className="info" key={'last-1'}>
                    (kliknij, aby wybrać z listy)
                  </span>
                )}

              {value.field_type === DROPDOWN_TYPE.SINGLE_DROPDOWN &&
                getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant)?.length === 0 && (
                  <span className="info">(kliknij, aby wybrać z listy)</span>
                )}
              <span className="arrow-down" onClick={handleDropdown} />
              <OrderBoxDropdownContainer
                actualVariant={actualVariant}
                boxFieldID={boxFieldID}
                description={description}
                value={value}
                dropdownRef={dropdownRef}
                dropdownSetting={dropdownSetting}
                setDropdownSetting={setDropdownSetting}
                showCheckbox={value.field_type !== DROPDOWN_TYPE.SINGLE_DROPDOWN}
              />
            </div>
          </>
        ) : (
          <>
            <div className="input-field">
              <input placeholder="Wpisz..." readOnly="readonly" onClick={handleDropdown} ref={dropdownRef}></input>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderBoxDropdown;
