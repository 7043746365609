/**
 * getCurrentDate() - method that return current date in format DD-MM-YYYY
 *
 * @returns current date
 */

const getCurrentDate = () => {
  const getDate = new Date();
  const getCurrYear = getDate.getFullYear();
  const getCurrMonth = getDate.getMonth();
  const getCurrDay = getDate.getDate();

  const month = getCurrMonth < 10 ? `0${getCurrMonth + 1}` : getCurrMonth + 1;
  const day = getCurrDay < 10 ? `0${getCurrDay}` : getCurrDay;

  const dateValue = `${day}-${month}-${getCurrYear}`;

  return dateValue;
};

export default getCurrentDate;
