import React, { useState, useRef, useEffect, useContext } from 'react';
import FILTER_BAR_TYPES from './ENUMS/FILTER_BAR_TYPE';
import FILTER_BAR_DROPDOWN_TYPES from './ENUMS/FILTER_BAR_DROPDOWN_TYPES';
import { MainAppContext } from '../../../MainAppComponent';
import FilterTabs from './FilterComponents/FilterTabs';
import generateFilterEndpointForOrders from './helpers/generateFilterEndpointForOrders';
import handleDataListElements from '../../../../functions/handleDataListElements';

const FilterBar = ({
  showPerAccount,
  showStatus,
  showDeadline,
  filterType,
  setDataLoader,
  userID,
  setExternalEndpoint,
  currentPage,
  postPerPage,
  search
}) => {
  const { filterSettings } = useContext(MainAppContext);

  //global component states
  const [dropdownType, setDropdownType] = useState(FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT);
  const [showDropdown, setShowDropdown] = useState(false);

  //dropdown data states
  const [perUserData, setPerUserData] = useState([]); //string after delimiter
  const [statusData, setStatusData] = useState([]); //string after delimiter

  //deadline states
  const [chosenDateFrom, setChosenDateFrom] = useState('');
  const [chosenDateTo, setChosenDateTo] = useState('');

  const [filterData, setFilterData] = useState({});

  const filterRef = useRef();
  const dropdownRef = useRef();
  const tabsRef = useRef([]);
  const calenderRef = useRef();

  const initFilterSettings = () => {
    const settingsObj = {};

    if (filterSettings) {
      settingsObj[FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT] = prepareFilterDataStructure(
        FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT,
        filterSettings.users
      );

      if (
        [
          FILTER_BAR_TYPES.IN_PROGRESS,
          FILTER_BAR_TYPES.FINISHED,
          FILTER_BAR_TYPES.DRAFT,
          FILTER_BAR_TYPES.NEW
        ].includes(filterType)
      ) {
        settingsObj[FILTER_BAR_DROPDOWN_TYPES.STATUS] = prepareFilterDataStructure(
          FILTER_BAR_DROPDOWN_TYPES.STATUS,
          filterSettings.order_state
        );
      }
      if (FILTER_BAR_TYPES.NOTIFICATION === filterType) {
        settingsObj[FILTER_BAR_DROPDOWN_TYPES.STATUS] = [
          { value: true, displayName: 'przeczytane' },
          { value: false, displayName: 'nieprzeczytane' }
        ];
      } else if (
        ![
          FILTER_BAR_TYPES.IN_PROGRESS,
          FILTER_BAR_TYPES.FINISHED,
          FILTER_BAR_TYPES.DRAFT,
          FILTER_BAR_TYPES.NEW
        ].includes(filterType)
      ) {
        settingsObj[FILTER_BAR_DROPDOWN_TYPES.STATUS] = prepareFilterDataStructure(
          FILTER_BAR_DROPDOWN_TYPES.STATUS,
          filterSettings.designer_status
        );
      }
    }
    setFilterData(settingsObj);
  };

  const prepareFilterDataStructure = (dropdownType, data) => {
    const dataStructure = [];

    if (data) {
      data.forEach((el) => {
        let obj;

        if (dropdownType === FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT) {
          obj = { value: el.pk, displayName: el.first_name };

          if (el.has_any_order) dataStructure.push(obj);
        } else {
          obj = { value: el.id, displayName: el.label };

          dataStructure.push(obj);
        }
      });
    }

    return dataStructure;
  };

  const handleTab = (e) => {
    const element = e.currentTarget;
    const tabType = element.dataset.tabType;
    setDropdownType(tabType);
  };

  useEffect(() => {
    initFilterSettings();
  }, [filterSettings]);

  const handleDropdownListElements = (e) => {
    const elementType = dropdownType;
    const value = e.currentTarget.value;

    if (elementType === FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT) {
      const perUser = handleDataListElements(value, perUserData);
      setPerUserData(perUser);
      getFilteredData(perUser, statusData, chosenDateFrom, chosenDateTo);
    } else if (elementType === FILTER_BAR_DROPDOWN_TYPES.STATUS) {
      const statusList = handleDataListElements(value, statusData);
      setStatusData(statusList);
      getFilteredData(perUserData, statusList, chosenDateFrom, chosenDateTo);
    }
  };

  const getFilteredData = (
    perUser = perUserData,
    status = statusData,
    dateFrom = chosenDateFrom,
    dateTo = chosenDateTo
  ) => {
    if (setDataLoader) setDataLoader(true);
    tabsRef.current.forEach((tab) => {
      if (tab) tab.classList.remove('active');
    });

    const filterEndpoint = generateFilterEndpointForOrders(
      perUser,
      status,
      dateFrom,
      dateTo,
      filterType,
      userID,
      currentPage,
      postPerPage,
      search
    );

    if (setExternalEndpoint) setExternalEndpoint(filterEndpoint); //modify endpoint from other page, need to be implementet in generateEndpointOrders

    if (dropdownType === FILTER_BAR_DROPDOWN_TYPES.DEADLINE) setShowDropdown(false);
  };

  const handleCheckBox = (value) => {
    if (dropdownType === FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT) {
      return perUserData.includes(value.toString());
    }
    return statusData.includes(value.toString());
  };

  return (
    <div className={`filter-bar`} ref={filterRef}>
      <div className={`filter-bar-wrapper`}>
        <FilterTabs
          showPerAccount={showPerAccount}
          showStatus={showStatus}
          showDeadline={showDeadline}
          handleTab={handleTab}
          tabsRef={tabsRef}
          isPerAccountChosen={perUserData.length > 0}
          isStatusChosen={statusData.length > 0}
          isDedlineChosen={chosenDateFrom || chosenDateTo}
          showDropdown={showDropdown}
          setDropdownType={setDropdownType}
          filterData={filterData}
          dropdownType={dropdownType}
          filterRef={filterRef}
          handleDropdownListElements={handleDropdownListElements}
          handleCheckBox={handleCheckBox}
          setShowDropdown={setShowDropdown}
          dropdownRef={dropdownRef}
          chosenDateFrom={chosenDateFrom}
          setChosenDateFrom={setChosenDateFrom}
          chosenDateTo={chosenDateTo}
          setChosenDateTo={setChosenDateTo}
          getFilteredData={getFilteredData}
          calenderRef={calenderRef}
        />
      </div>
    </div>
  );
};

export default FilterBar;
