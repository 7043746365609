import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import usePopup from "../../../helper/usePopup";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateGLobalBoxFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getActualBoxFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import noPhotoPlaceholder from "../../../../assets/no_photo_2.svg";
import Slider from "../Table/Slider";
import loop from "../../../../assets/loop.svg";
import BackgroundSrcInputPopup from "../Popup/BackgroundSrcInputPopup";
import ImageModal from "../Popup/ImageModla";
import MODAL_TYPES from "../Popup/ENUMS/IMAGE_MODAL_TYPES";
import {MainAppContext} from "../../../MainAppComponent";

const BackgroundChooser = ({ actualVariant, boxFieldID }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { initPopup } = usePopup();

  const [buttonChooserData, setButtonChooserData] = useState([]);
  const [showTooltip, setShowTooltip] = useState({ type: false, id: undefined });
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState(null);
  const timeoutIdRef = useRef(null); // Ref for managing timeout=

  useEffect(() => {
    //initial data
    let actualBoxFieldState = getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant);
    if (actualBoxFieldState?.value?.content) {
      //this is solution for older orders
      setButtonChooserData({src: actualBoxFieldState.value.content, description: ''});
    } else if (actualBoxFieldState?.value?.length > 0) {
      //filter data for handle older orders and orders before ABC-203 (task in linear)
      let data = actualBoxFieldState.value.filter(el=>{
        if(typeof el === 'object') return el;
        return {src: el, description: ''}
      })

      setButtonChooserData(data);
    }
  }, []);

  useEffect(() => {
    //save new data
    setBoxFieldContent();
  }, [buttonChooserData]);

  const setBoxFieldContent = () => {
    let initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: buttonChooserData,
    };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  const saveData = (backgroundSrc, backgroundDesc) => {
    setButtonChooserData((prevState) => [...prevState, {src: backgroundSrc, description: backgroundDesc}]);
  };

  const removeData = (chooserIndex) => {
    setButtonChooserData(buttonChooserData.filter((el, index) => index !== chooserIndex));
  };

  const handleChooserButton = (e, index) => {
    e.stopPropagation();
    initPopup(<BackgroundSrcInputPopup saveData={saveData} />);
  };

  const handleMouseEnter = (index) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current); // Clear the existing timeout
    }
    timeoutIdRef.current = setTimeout(() => {
      setShowTooltip({ type: true, id: index });
    }, 300); // Delay the appearance of the tooltip
  };

  const handleMouseLeave = (index) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current); // Clear the existing timeout
    }
    timeoutIdRef.current = setTimeout(() => {
      setShowTooltip({ type: false, id: index });
    }, 300); // Delay the disappearance of the tooltip
  };

  const handleImageModal = (src, e) => {
    //display product on mobile
    e.stopPropagation();
    setShowImageModal(true);
    setShowImage(true);
    setImageModalSrc(src);
  };
  
  const { setShowImage } = useContext(MainAppContext);
  const slideArr = useMemo(() => {
    //render slider elements
    const slides = buttonChooserData?.map((value, index) => (
      <div
        className="product"
        data-id-value={value.id}
        key={`add-product-${index}`}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave(index)}
      >
        {index + 1}
        <div id="tooltip" className={showTooltip.type && showTooltip.id === index ? "tooltipVisible" : ""}>
          <div className="image-view" onClick={(e) => handleImageModal(value.src || noPhotoPlaceholder, e)}>
            <div className="hover-shadow">
              <div className="shadow"></div>
              <div className="loop">
                <img src={loop} alt="loop" />
              </div>
            </div>
            <img className="view" src={value.src || noPhotoPlaceholder} alt="product view" />
          </div>
          <span onClick={() => removeData(index)}>Usuń</span>
        </div>
      </div>
    ));

    // Add "add-new-logotype" button at the end
    slides.push(
      <button className="add-new-product" onClick={(e) => handleChooserButton(e, slides.length)} key="add-new-product">
        +
      </button>
    );

    return slides;
  }, [buttonChooserData, showTooltip]);

  return (
    <>
      <div className="content add-product-box show">
        <div className="add-product">
          {slideArr.length ? (
            <Slider
              spaceBetween={0}
              slidesPerView={8}
              slides={slideArr}
              hasCustomNavigation={false}
              breakpoints={{ 2400: { slidesPerView: 10 }, 1600: { slidesPerView: 7 }, 772: { slidesPerView: 7 }, 320: { slidesPerView: 1 } }}
            />
          ) : null}
        </div>
      </div>
      {showImageModal && (
        <ImageModal setShowImageModal={setShowImageModal} setShowImage={setShowImage} imageSource={imageModalSrc} modalType={MODAL_TYPES.SINGLE_IMAGE} isOutSideLink={true} />
      )}
    </>
  );
};

export default BackgroundChooser;
