import React from 'react';
import { ReactComponent as Save } from '../../../../../assets/popups/save.svg';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../../helper/usePopup';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendBoxFIeldData } from '../../../../services/sendBoxFieldsData';
const SaveSketch = ({ boxData, headerBoxesData, boxFieldsData, boxSettings, methodType }) => {
  const navigate = useNavigate();
  const { closeOrderPopup } = usePopup();
  const handleSaveSketch = () => {
    sendBoxFIeldData(boxData, headerBoxesData, boxFieldsData, boxSettings, methodType).then((data) => {
      if (data && data?.status === 'draft') {
        toast.success('Szkic pomyślnie zapisano', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: false,
          progress: undefined,
          theme: 'colored',
          transition: Bounce
        });
        closeOrderPopup();
        navigate('/draft');
      } else {
        toast.error('Coś poszło nie tak', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: false,
          progress: undefined,
          theme: 'colored',
          transition: Bounce
        });
      }
    });
  };
  return (
    <div className="save-popup">
      <div className="upper-part-popup">
        <Save />
        <h3>Zapisz zlecenie jako SZKIC.</h3>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={() => closeOrderPopup()}>
          Anuluj
        </button>
        <button className="button accept" onClick={handleSaveSketch}>
          OK
        </button>
      </div>
    </div>
  );
};

export default SaveSketch;
