import React, { useContext } from 'react';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../../../helper/usePopup';
import Format from '../../../Popup/AddOrder/Format';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const FormatButton = ({ orderID, variantID, isReformat, rowData }) => {
  const { user } = useContext(UserContext);
  const { initPopup, closePopup } = usePopup();
  const navigate = useNavigate();

  const handleFormatBtn = (orderName, calenderDeadline) => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('FormatButton action', sentryContext);

    const link = `/reformatting/?&create_format=true&order=${orderID}&variant=${variantID}&order_name=${orderName}&deadline=${calenderDeadline}`;
    closePopup();
    navigate(link);
  };

  return (
    <>
      {shouldDisplayButton('format', rowData?.order_status, user) !== 'Brak' && !isReformat && (
        <>
          <button
            className={`format  ${shouldDisplayButton('format', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={() => initPopup(<Format contentType={'format'} handleAction={handleFormatBtn} />)}
          >
            Formatuj
          </button>
        </>
      )}
    </>
  );
};

export default FormatButton;
