import axios from 'axios';
import authHeader from '../../../../helper/auth-header';

const FileSender = async (inputFile, slot_pk, addPhoto, currentRetry = 0) => {
  const auth = authHeader();
  const data = new FormData();
  data.append('file', inputFile.selectedFile);

  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: auth,
      'Content-Disposition': `attachment; filename="filename.jpg"`
    }
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/orders/images/${slot_pk}/upload`,
    data,
    requestOptions
  );

  if ([500, 502].includes(response.status) && currentRetry < 3) {
    if (addPhoto) {
      FileSender(inputFile, slot_pk, addPhoto, currentRetry + 1);
    } else {
      FileSender(inputFile, slot_pk, null, currentRetry + 1);
    }
  } else if (![500, 502].includes(response.status)) {
    if (addPhoto) addPhoto((prevState) => [response.data, ...prevState]);
  }

  return response;
};

export default FileSender;
