import React from 'react';

const ProductShow = ({ productsVisualisation }) => {
  return (
    <div className="product-value">
      {' '}
      {/*<div className="product-titles">*/}
      {/*  <span className="value-ean">EAN</span>*/}
      {/*  <span className="size">Rozmiar</span>*/}
      {/*  <span className="rotation">Rotacja</span>*/}
      {/*</div>*/}
      <div className="product-row">{productsVisualisation} </div>
    </div>
  );
};

export default ProductShow;
