import React, { useEffect, useRef } from 'react';
import usePopup from '../../../helper/usePopup';

const AddNewPopupContainer = () => {
  const { popup, closeOrderPopup } = usePopup();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !popupRef.current.children[0].classList.contains('duplicate-popup')
      ) {
        closeOrderPopup();
      }
    };

    if (popup.order) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popup.order, closeOrderPopup]);

  return (
    <div className={`bg-clear ${popup.order ? 'active' : ''}`}>
      <div ref={popupRef}>{popup.component}</div>
    </div>
  );
};

export default AddNewPopupContainer;
