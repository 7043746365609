import React, { useRef } from 'react';
import TableHeaders from '../tableElements/TableHeaders';
import TableBody from '../tableElements/TableBody';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
const Table = ({ loadingTableData, setOrderDataParameters, orderDataParameters }) => {
  const tableRef = useRef();

  return (
    <div className="table" ref={tableRef}>
      <TableHeaders
        orderDataParameters={orderDataParameters}
        setOrderDataParameters={setOrderDataParameters}
        tableRef={tableRef}
      />
      <TableBody orderDataParameters={orderDataParameters} setOrderDataParameters={setOrderDataParameters} />
      {loadingTableData && (
        <Loader type={LOADER_TYPE.LOAD_DATA} width={25} height={25} position={`absolute`} startAnimation={true} />
      )}
    </div>
  );
};

export default Table;
