import React from 'react';
import { ReactComponent as Delete } from '../../../../../assets/popups/delete.svg';
import usePopup from '../../../../helper/usePopup';
import imageServices from '../../../../services/imageServices';

const DeleteAllFiles = ({ setActiveReloadAnimation, variantID, orderID, setReloadComponent }) => {
  const { closePopup } = usePopup();

  const handleFirstButton = () => {
    if (setActiveReloadAnimation) setActiveReloadAnimation(true);
    imageServices
      .removeImageFromVariant(variantID, orderID)
      .then(() => {
        if (setReloadComponent) setReloadComponent(true);
        closePopup();
      })
      .catch((error) => {
        setActiveReloadAnimation(false);
        console.error(error);
      });
  };

  return (
    <div className="delete-all-popup">
      <div className="upper-part-popup">
        <Delete />
        <h3>Usunąć wszystkie pliki?</h3>
        <p>Tej operacji nie można cofnąć</p>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={() => closePopup()}>
          Anuluj
        </button>
        <button className="button abort" onClick={handleFirstButton}>
          Usuń
        </button>
      </div>
    </div>
  );
};

export default DeleteAllFiles;
