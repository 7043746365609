import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';

const getVariants = async (order_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
    // body: JSON.stringify({ order: order_id }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/variant/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const updateVariants = async (variant_id, body) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: auth },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/variant/${variant_id}/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getVariantByID = async (variant_id) => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/variant/${variant_id}/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getVariants,
  updateVariants,
  getVariantByID
};
