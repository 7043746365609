import React from 'react';
import TableContextContainer from '../../elements/e-commerce/Table/TableContextContainer';
import pageLogo from '../../../assets/in-progrss-logo.svg';
import TABLE_TYPES from '../../../globalEnums/TABLE_TYPES_ENUMS';

const Notification = () => {
  return <TableContextContainer tableType={TABLE_TYPES.NOTIFICATION} pageTitle={'Powiadomienia'} logo={pageLogo} />;
};

export default Notification;
