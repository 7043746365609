const LINKS_TYPES = {
  SERVER_LOCATION: 'server_location',
  STOCK_IMAGE_URL: 'stock_image_url',
  ACTION_URL: 'action_url',
  OPEN_FILES: 'open_files'
};

const LINK_INPUT_TITLE = {
  server_location: 'lokalizacja',
  stock_image_url: 'pliki stock',
  action_url: 'akcja',
  open_files: 'pliki otwarte'
};

const LINK_INPUT_PLACEHOLDER = {
  server_location: 'Z://',
  stock_image_url: 'https://',
  action_url: 'https://',
  open_files: 'https://'
};

const COPY_OPEN_BUTTON_CONTENT = {
  server_location: 'skopiuj',
  stock_image_url: 'skopiuj',
  action_url: 'skopiuj',
  open_files: 'skopiuj'
};

const COMPONENT_CLASS = {
  server_location: 'server_location',
  stock_image_url: 'stock_image_url',
  action_url: 'action_url',
  open_files: 'open_files'
};

export default {
  LINKS_TYPES,
  LINK_INPUT_TITLE,
  LINK_INPUT_PLACEHOLDER,
  COPY_OPEN_BUTTON_CONTENT,
  COMPONENT_CLASS
};
