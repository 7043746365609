import React, { useState, useRef, useContext, useEffect } from "react";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { choseDefaultNameLanguage } from "../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage";
import { updateGLobalBoxFieldsData, updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getActualBoxFieldState, getActualBoxFieldValue } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { getBoxFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import { handleDisactiveBoxList } from "../../../../functions/boxFunctions/handleDisactiveBoxList";
import checkIfValueHasChildren from "../../../../functions/boxFieldsFunctions/checkIfValueHasChildren";
import checkIfContentIncludeID from "../../../../functions/boxFieldsFunctions/checkIfContentIncludeID";
import checkIfArraContainsObject from "./helper/checkIfArraContainsObject";
import generateVariantArrayFromTabs from "../../../../functions/boxFunctions/generateVariantsFromTabs";
import usePopup from "../../../helper/usePopup";
import CustomSizeInputs from "../../globalElements/radioListComponents/CustomSizeInputs";
import ListElements from "./radioListElements/ListEelements";
import SelectAllElements from "./radioListElements/SelectAllElements";
import RadioInput from "../../RadioInput";
import VariantContinue from "../Popup/AddOrder/VariantContinue";
import SizeDropdownChooser from "./SizeDropdownChooser";
import { capitalizeFirstLetter } from "../../../../functions/capitalizeFirstLetter";
import NewInfoPopup from "../Popup/AddOrder/NewInfoPopup";

const RadioList = ({
  value,
  description,
  boxFieldID,
  actualVariant,
  variant,
  validate,
}) => {
  const {
    boxFieldsData,
    setBoxFieldsData,
    orderCustomSizeList,
    setOrderCustomSizeList,
    boxSettings,
    setBoxSettings,
    tabsArr,
    boxData,
  } = useContext(AddNewOrderContext);

  const [parentChildData, setParentChildData] = useState(null);
  const [column1Elements, setColumn1Elements] = useState(null);
  const [column2Elements, setColumn2Elements] = useState(null);
  const [textInputsValues, setTextInputsValues] = useState([
    { input_id: 1, input_value: "" },
  ]);
  const [checkAll, setCheckedAll] = useState(false);
  const [checkAllData, setCheckAllData] = useState([]);
  const [chosenIndividualChecbox, setChosenIndividualCheckbox] = useState(null);
  const [changeIndividualCheckboxValue, setChangeIndividualCheckboxValue] =
    useState(false);
  const [showCustomSizeInputs, setShowCustomSizeInputs] = useState(false);
  const [chosenSizeName, setChosenSizeName] = useState(""); //size name to display in size dropdown input

  const { initPopup } = usePopup();
  const boxRef = useRef();
  const radioRef = useRef([]);
  const option2Ref = useRef([]);
  const textInput = useRef([]);
  const option2Inputs = useRef([]);
  const handleCheckbox = (e) => {
    e.stopPropagation();
    let parentElement;
    if (
      e.target.dataset.side !== "beside" &&
      e.target.dataset.side !== "bottomside"
    ) {
      parentElement = e.target.parentNode;
      let siblingOfParenElement = parentElement.nextSibling;
      if (
        e.target.dataset.type == "text-input" ||
        e.target.dataset.type == "percentage"
      ) {
        textInput.current.forEach((el) => {
          if (el !== null) {
            if (el.isSameNode(siblingOfParenElement)) {
              el.classList.add("active");
            } else {
              el.classList.remove("active");
            }
          }
        });
      } else {
        textInput.current.forEach((el) => {
          if (el !== null) el.classList.remove("active");
        });
      }
      let customInputValue = "";

      textInputsValues.forEach((el) => {
        if (el.input_id == e.target.dataset.checkboxGroup) {
          customInputValue = el.input_value;
        }
      });

      let initialValue = {
        ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
        value: { content: e.target.dataset.checkboxGroup },
        custom_inputs_value: customInputValue,
      };
      updateGLobalBoxFieldsData(
        initialValue,
        boxFieldsData,
        setBoxFieldsData,
        boxFieldID,
        actualVariant
      );
    } else {
      parentElement = e.target.parentNode.children[0];
      let siblingOfParenElement = parentElement.nextSibling;
      if (
        e.target.parentNode.children[0].children[0].dataset.type ==
          "text-input" ||
        e.target.parentNode.children[0].children[0].dataset.type == "percentage"
      ) {
        textInput.current.forEach((el) => {
          if (el !== null) {
            if (el.isSameNode(siblingOfParenElement)) {
              el.classList.add("active");
            } else {
              el.classList.remove("active");
            }
          }
        });
      } else {
        textInput.current.forEach((el) => {
          if (el !== null) el.classList.remove("active");
        });
      }
      let customInputValue = "";

      textInputsValues.forEach((el) => {
        if (
          el.input_id ==
          e.target.parentNode.children[0].children[0].dataset.checkboxGroup
        ) {
          customInputValue = el.input_value;
        }
      });

      let initialValue = {
        ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
        value: {
          content:
            e.target.parentNode.children[0].children[0].dataset.checkboxGroup,
        },
        custom_inputs_value: customInputValue,
      };
      updateGLobalBoxFieldsData(
        initialValue,
        boxFieldsData,
        setBoxFieldsData,
        boxFieldID,
        actualVariant
      );
    }
  };

  const updateIndividualCheckboxData = (element) => {
    let checkboxGroupValue = { parent_id: null, children: [] };
    for (let i = 0; i < parentChildData.length; i++) {
      if (parentChildData[i].parent_id == element) {
        checkboxGroupValue = parentChildData[i];
        break;
      }
    }

    let initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: checkboxGroupValue,
      custom_inputs_value: "",
    };
    updateUniqueFieldsData(
      initialValue,
      boxFieldsData,
      setBoxFieldsData,
      boxFieldID,
      actualVariant
    );
  };
  const individualCheckboxChange = (e) => {
    e.stopPropagation();
    setChosenSizeName(e.target.dataset.textContent);
    setChosenIndividualCheckbox(e.target);
    if (generateVariantArrayFromTabs(tabsArr).length > 1) {
      initPopup(
        <VariantContinue
          setChangeIndividualCheckboxValue={setChangeIndividualCheckboxValue}
        />
      );
    } else {
      setChangeIndividualCheckboxValue(true);
    }
  };
  const handleIndividualCheckbox = () => {
    let hiddenBoxArr = handleDisactiveBoxList(
      boxData,
      boxSettings,
      setBoxSettings,
      chosenIndividualChecbox.dataset.checkboxGroup,
      tabsArr,
      false
    );

    setBoxSettings({ ...boxSettings, hidden_boxes: hiddenBoxArr });
    option2Ref.current.forEach((el) => {
      if (el !== null) {
        if (chosenIndividualChecbox.dataset.checkbox !== el.dataset.checkbox) {
          el.classList.remove("option-2-active");
        } else {
          el.classList.add("option-2-active");
        }
      }
    });

    updateIndividualCheckboxData(chosenIndividualChecbox.dataset.checkboxGroup);
  };

  useEffect(() => {
    if (changeIndividualCheckboxValue) {
      handleIndividualCheckbox();
      setChangeIndividualCheckboxValue(false);
    }
  }, [changeIndividualCheckboxValue]);
  const handleTextInput = (e) => {
    let fieldID = e.target.dataset.checkboxGroup;
    let value = e.target.value;
    let additionStar =
      e.target.dataset.side == "bottomside" && !value.includes("*") ? "*" : "";

    // Don't update state unnecessarily if the value is the same
    let existingInput =
      textInputsValues.find((el) => el.input_id === fieldID)?.input_value || "";
    if (additionStar + value === existingInput) {
      return; // If the value hasn't changed, don't do anything
    }

    // Save the current cursor position before updating the state
    const cursorPosition = e.target.selectionStart;

    // We create a copy of the current input values
    let newArr = textInputsValues.map((el) => {
      if (el.input_id == fieldID) {
        return { input_id: el.input_id, input_value: additionStar + value };
      } else {
        return el;
      }
    });

    let custominput = additionStar + value;
    let dataInputType = e.target.dataset.inputType;

    if (dataInputType == "beside_percent") {
      custominput = value + " " + "%";
    }

    // Update the text inputs state only if necessary
    setTextInputsValues(newArr);

    // Update global box fields data
    let initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      custom_inputs_value: custominput,
    };

    updateGLobalBoxFieldsData(
      initialValue,
      boxFieldsData,
      setBoxFieldsData,
      boxFieldID,
      actualVariant
    );

    // Restore cursor position after state updates
    requestAnimationFrame(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    });
  };
  const generateCustomInputs = () => {
    let state = getActualBoxFieldState(
      boxFieldsData,
      boxFieldID,
      actualVariant
    );
    let newArr = [];
    value.box_field_dictionary.forEach((field, index) => {
      if (field.is_custom_input) {
        let value = "";
        if (state && state.value && field.id == state.value.content)
          value = state.custom_inputs_value;

        if (value.includes("%")) value = value.slice(0, value.length - 1);

        let obj = { input_id: field.id, input_value: value };
        newArr.push(obj);
      }
    });

    setTextInputsValues(newArr);
  };

  useEffect(() => {
    generateCustomInputs();
  }, []);

  const setInputTextValue = (fieldID) => {
    let result = "";
    textInputsValues.forEach((el) => {
      if (el.input_id === fieldID) {
        result = el.input_value;
      }
    });

    for (let i = 0; i < textInputsValues.length; i++) {
      if (textInputsValues[i].input_id == fieldID) {
        return textInputsValues[i].input_value;
      }
    }
    return result;
  };

  const rednerLiClass = (field) => {
    if (field.is_custom_input) {
      if (field.custom_input_placement == "beside") {
        return "beside";
      } else if (field.custom_input_placement == "beside_percent") {
        return "percentage";
      }
    }
    return null;
  };

  const handleSizeOptionsCheckboxes = (field) => {
    let hasChildren = checkIfValueHasChildren(value);
    let actualBoxFieldValue = getActualBoxFieldValue(
      boxFieldsData,
      boxFieldID,
      actualVariant,
      hasChildren
    );

    if (actualBoxFieldValue == field.id) {
      if (hasChildren && !chosenSizeName) setChosenSizeName(field.default_name);
      return true;
    } else {
      return false;
    }
  };

  const renderRadioListLayout = () => {
    let column1 = [],
      column2 = [],
      liElement;
    value.box_field_dictionary.forEach((field, index) => {
      liElement = (
        <li key={`radio-list-${index}`} className={rednerLiClass(field)}>
          <div
            className={
              field.custom_input_placement != "beside" &&
              field.custom_input_placement != "beside_percent"
                ? "input-group text-input"
                : "input-group text-input beside"
            }
          >
            <RadioInput
              ref={(el) => {
                radioRef.current.push(el);
              }}
              dataTextContent={
                boxSettings.language !== "PL"
                  ? choseDefaultNameLanguage(
                      field,
                      boxSettings.language,
                      "name"
                    )
                  : field.default_name
              }
              dataType="text-input"
              dataCheckbox={`checkbox-${index}`}
              dataCheckboxGroup={field.id}
              value={
                boxSettings.language !== "PL"
                  ? choseDefaultNameLanguage(field, boxSettings.language, "id")
                  : field.id
              }
              checked={handleSizeOptionsCheckboxes(field)}
              onChange={
                checkIfValueHasChildren(value)
                  ? individualCheckboxChange
                  : handleCheckbox
              }
              label={
                boxSettings.language !== "PL"
                  ? choseDefaultNameLanguage(
                      field,
                      boxSettings.language,
                      "name"
                    )
                  : field.default_name
              }
            />
            {field.is_custom_input && (
              <input
                ref={(el) => textInput.current.push(el)}
                data-side={
                  field.custom_input_placement != "beside" &&
                  field.custom_input_placement != "beside_percent"
                    ? "bottomside"
                    : "beside"
                }
                defaultValue={
                  textInputsValues.find((el) => el.input_id === field.id)
                    ?.input_value || ""
                } // Use defaultValue instead of value to avoid rerendering
                data-checkbox-group={field.id}
                onClick={
                  checkIfValueHasChildren(value)
                    ? individualCheckboxChange
                    : handleCheckbox
                }
                data-id={
                  boxSettings.language !== "PL"
                    ? choseDefaultNameLanguage(
                        field,
                        boxSettings.language,
                        "id"
                      )
                    : field.id
                }
                className={
                  field.custom_input_placement != "beside" &&
                  field.custom_input_placement != "beside_percent"
                    ? "text-input"
                    : "text"
                }
                placeholder={capitalizeFirstLetter(
                  field.custom_input_placeholder
                )}
                data-input-type={field.custom_input_placement}
                onChange={handleTextInput}
              ></input>
            )}
          </div>
        </li>
      );

      if (field.column == 1) {
        column1.push(liElement);
      } else if (field.column == 2) {
        column2.push(liElement);
      }
    });

    setColumn1Elements(column1.length > 0 ? column1 : null);
    setColumn2Elements(column2.length > 0 ? column2 : null);
  };

  useEffect(() => {
    renderRadioListLayout();
  }, [boxFieldsData, boxSettings]);

  useEffect(() => {
    if (checkIfValueHasChildren(value)) {
      let valueArr = [];
      let checkAllData = [];
      let boxDictionery = value.box_field_dictionary;

      for (let i = 0; i < boxDictionery.length; i++) {
        valueArr.push({ parent_id: boxDictionery[i].id, children: [] });
        checkAllData.push({
          parent_id: boxDictionery[i].id,
          is_checked: false,
        });
      }

      setParentChildData(valueArr);
      setCheckAllData(checkAllData);
    }
  }, []);

  const handleChildOption = (e) => {
    // getChildrenHiddenBoxes(e.target.dataset.checkboxGroup)

    let parentID = e.target.dataset.parent;
    let result = parentChildData.map((el) => {
      if (e.target.dataset.parent == el.parent_id) {
        let childrenArr = [...el.children];
        let indexToRemove = null;
        childrenArr.forEach((child, index) => {
          if (child.id === e.target.dataset.textvalue) {
            indexToRemove = index;
          }
        });

        if (indexToRemove !== null) {
          el.children.splice(indexToRemove, 1);
        } else {
          el.children.push({
            id: e.target.dataset.textvalue,
            value: e.target.value,
          });
        }
      }
      return el;
    });

    handleCheckAllButtonStatus(parentID, false);
    updateIndividualCheckboxData(e.target.dataset.parent, result);
    setParentChildData(result);
  };

  const initOption2Status = () => {
    let state = getActualBoxFieldState(
      boxFieldsData,
      boxFieldID,
      actualVariant,
      true
    );
    let boxFields = value.box_field_dictionary;
    let resultIndex = 0;

    if (state?.value?.parent_id) {
      for (let i = 0; i < boxFields.length; i++) {
        if (boxFields[i].id == state.value.parent_id) {
          resultIndex = i;
          break;
        }
      }
    }
    return resultIndex;
  };

  const handleCheckboxCheck = (id) => {
    let content = getBoxFieldContent(
      boxFieldsData,
      boxFieldID,
      actualVariant,
      true
    );
    return checkIfContentIncludeID(id, content, true);
  };

  const handleCheckAll = (e) => {
    let parentID = e.target.dataset.parent;
    let action = e.target.dataset.action;
    let checkAll = action == "checkall" ? true : false;
    let result = parentChildData.map((el) => {
      if (el.parent_id == parentID) {
        if (checkAll) {
          option2Inputs.current.forEach((input) => {
            if (input && input.dataset.parent == parentID) {
              let obj = { id: input.dataset.textvalue, value: input.value };
              if (checkIfArraContainsObject(el.children, obj) == false) {
                el.children.push({
                  id: input.dataset.textvalue,
                  value: input.value,
                });
              }
            }
          });
        } else {
          el.children = [];
        }
      }

      return el;
    });

    handleCheckAllButtonStatus(parentID, checkAll);
    updateIndividualCheckboxData(parentID, result);
    setParentChildData(result);
  };

  const handleCheckAllButtonStatus = (parentID, value) => {
    let newCheckedAll = checkAllData.map((el) => {
      if (el.parent_id == parentID) {
        el.is_checked = value;
      }

      return el;
    });
    setCheckedAll(newCheckedAll);
  };

  const handleCheckeAllDataCheck = (parentID) => {
    for (let i = 0; i < checkAllData.length; i++) {
      if (checkAllData[i].parent_id == parentID) {
        return checkAllData[i].is_checked;
      }
    }
  };

  const handleCustomInputs = (event, inputValue) => {
    let btnType = event.target.classList[0];

    if (inputValue && btnType == "add_btn") {
      setOrderCustomSizeList((prevState) => [...prevState, inputValue]);
    } else {
      let removeValue = event.target.dataset.removeValue;
      setOrderCustomSizeList((prevState) =>
        prevState.filter((value) => value !== removeValue)
      );
    }
  };

  return (
    <div className="content radio-box" ref={boxRef}>
      <span>{description || ""}</span>

      {parentChildData ? (
        <SizeDropdownChooser chosenSizeName={chosenSizeName}>
          {column1Elements}
        </SizeDropdownChooser>
      ) : (
        <div className={column2Elements ? "list column-2" : "list column-1"}>
          {column1Elements ? (
            <ul className={column2Elements ? "mechanism" : ""}>
              {column1Elements}
            </ul>
          ) : null}
          {column2Elements ? (
            <ul className={column2Elements ? "mechanism" : ""}>
              {column2Elements}
            </ul>
          ) : null}
        </div>
      )}
      {parentChildData ? (
        <div className="option-2">
          {value && chosenSizeName
            ? value.box_field_dictionary.map((field, index) => {
                return (
                  <div
                    key={`option-2-${index}`}
                    className={
                      index == initOption2Status()
                        ? "inputs option-2-active"
                        : "inputs"
                    }
                    data-checkbox={`checkbox-${index}`}
                    ref={(el) => option2Ref.current.push(el)}
                  >
                    <ul className="children-list">
                      {!showCustomSizeInputs && (
                        <>
                          <ListElements
                            elements={field.children}
                            handleCheckboxCheck={handleCheckboxCheck}
                            inputs={option2Inputs}
                            parent={field}
                            handleChildOption={handleChildOption}
                          />
                          <SelectAllElements
                            hasChildren={field.children.length > 1}
                            handleCheckeAllDataCheck={handleCheckeAllDataCheck}
                            handleCheckAll={handleCheckAll}
                            parent={field}
                          />
                        </>
                      )}
                      <CustomSizeInputs
                        setOrderCustomSizeList={setOrderCustomSizeList}
                        chosenSizes={getBoxFieldContent(
                          boxFieldsData,
                          boxFieldID,
                          actualVariant,
                          true
                        )}
                        customSizeList={orderCustomSizeList}
                        handleData={handleCustomInputs}
                        removeButtonType={"checkbox"}
                      />
                    </ul>
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default RadioList;
