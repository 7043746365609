export const getHiddenBoxesFieldsID = (hiddenBoxes, boxData) => {
  const idArr = [];

  hiddenBoxes.forEach((el) => {
    if (el.variants?.length) idArr.push(el.box_id);
  });

  let fieldsID = [];
  boxData.forEach((boxObj) => {
    if (idArr.includes(boxObj.box.id)) {
      const boxFieldsID = boxObj.box.box_fields.map((el) => el.id);
      const obj = { box_id: boxObj.box.id, box_fields: boxFieldsID };
      fieldsID = [...fieldsID, obj];
    }
  });

  return fieldsID;
};
