import React from 'react';
import { ReactComponent as Observe } from '../../../../../assets/popups/stop_eye.svg';
import usePopup from '../../../../helper/usePopup';

const StopObserve = () => {
  const { closeOrderPopup } = usePopup();
  return (
    <div className="stop-observe-popup">
      <div className="upper-part-popup">
        <Observe />
        <h3>
          Przestałeś obserwować
          <br />
          wybrane zlecenie
        </h3>
      </div>
      <div className="buttons single-button ">
        <button className="button accept" onClick={() => closeOrderPopup()}>
          OK
        </button>
      </div>
    </div>
  );
};

export default StopObserve;
