import React, { useState } from 'react';
import { ReactComponent as DuplicateIcon } from '../../../../../assets/popups/duplicate.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
import AddCounterCalenderDeadline from '../additionalElements/AddCounterCalenderDeadline';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';

const suffix = '_reformat';
const Duplicate = ({ contentType, handleAction }) => {
  const { closeOrderPopup } = usePopup();
  const [orderName, setOrderName] = React.useState('');
  const [calenderDeadline, setCalenderDeadline] = useState('');
  const [error, setError] = useState(false);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const handleOrderName = (e) => {
    setOrderName(e.target.value);
  };
  const isValidated = () => {
    if (contentType === 'format') return calenderDeadline && orderName;

    return calenderDeadline;
  };
  const handleDuplicate = () => {
    if (isValidated()) {
      if (error) setError(false);
      setActiveButtonLoader(true);
      const name = ['format'].includes(contentType) ? orderName + suffix : orderName;
      handleAction(name, calenderDeadline, setActiveButtonLoader);
    } else {
      setError(true);
    }
  };
  return (
    <div className="duplicate-popup">
      <div className="upper-part-popup">
        <DuplicateIcon />
        <h3>Powiel aktualne zlecenie</h3>
      </div>
      <div className="middle-part-popup">
        <input
          className="order-name"
          value={orderName}
          onChange={handleOrderName}
          placeholder={'Nazwa nowego zlecenia'}
        />
        <div className="input-group" style={{ display: 'block', width: '100%', maxHeight: 25 }}>
          <AddCounterCalenderDeadline
            setCalenderDeadline={setCalenderDeadline}
            deadline
            placeholder={'Wybierz deadline'}
          />
          {error && <span className="error">*Musisz wskazać nowy deadline</span>}
        </div>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={() => closeOrderPopup()}>
          Anuluj
        </button>
        <button
          className="button accept"
          style={{ pointerEvents: activeButtonLoader ? 'none' : 'all' }}
          onClick={handleDuplicate}
        >
          {activeButtonLoader ? (
            <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} />
          ) : (
            'OK'
          )}
        </button>
      </div>
    </div>
  );
};

export default Duplicate;
