import generateVariantArrayFromTabs from './generateVariantsFromTabs';

export const handleDisactiveBoxList = (boxData, boxSettings, setBoxSettings, chosenParameter = null, tabsArr) => {
  let result = [];

  boxData.forEach((el) => {
    const boxFields = el.box.box_fields;
    for (let i = 0; i < boxFields.length; i++) {
      const boxFieldDict = boxFields[i].box_field_dictionary;
      if (result.length > 0) break;
      for (let j = 0; j < boxFieldDict.length; j++) {
        if (boxFieldDict[j].id == chosenParameter && boxFieldDict[j].hide_boxes_if_selected?.length > 0) {
          const hidBoxIfSelected = [...boxFieldDict[j].hide_boxes_if_selected]; //trzeba dodac metode ktora doda odpowiednie pola
          result = setUpBoxSettings(boxSettings, hidBoxIfSelected, tabsArr);
          break;
        }
      }
    }
  });

  if (result.length > 0) {
    return result;
  } else {
    return boxSettings.hidden_boxes;
  }
};

const setUpBoxSettings = (boxSettings, hide_boxes_if_selected, tabsArr) => {
  const hiddenBoxes = boxSettings.hidden_boxes;
  const variantList = generateVariantArrayFromTabs(tabsArr);

  //clear box setting
  for (let i = 0; i < hide_boxes_if_selected.length; i++) {
    for (let j = 0; j < hiddenBoxes.length; j++) {
      hiddenBoxes[j].variants = [];
    }
  }

  //set up
  for (let i = 0; i < hide_boxes_if_selected.length; i++) {
    for (let j = 0; j < hiddenBoxes.length; j++) {
      if (hide_boxes_if_selected[i].id == hiddenBoxes[j].box_id) {
        hiddenBoxes[j].variants = [...variantList];
      }
    }
  }
  return hiddenBoxes;
};
