import React, { useState } from 'react';
import { ReactComponent as RestoreIcon } from '../../../../../assets/popups/restore.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
import AddCounterCalenderDeadline from '../additionalElements/AddCounterCalenderDeadline';
const suffix = '_reformat';
const Restore = ({ contentType, handleAction }) => {
  const { closeOrderPopup } = usePopup();
  const [orderName, setOrderName] = React.useState('');
  const [calenderDeadline, setCalenderDeadline] = useState('');
  const [error, setError] = useState(false);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);

  const isValidated = () => {
    if (contentType === 'format') return calenderDeadline && orderName;

    return calenderDeadline;
  };
  const handleDuplicate = () => {
    if (isValidated()) {
      if (error) setError(false);
      if (['restore'].includes(contentType)) {
        setActiveButtonLoader(true);
        handleAction(orderName, calenderDeadline, setActiveButtonLoader);
      } else if (['format'].includes(contentType)) {
        handleAction(orderName + suffix, calenderDeadline);
      } else {
        handleAction(orderName, calenderDeadline);
      }
    } else {
      setError(true);
    }
  };
  return (
    <div className="restore-popup">
      <div className="upper-part-popup">
        <RestoreIcon />
        <h3>Przywróć projekt</h3>
      </div>
      <div className="middle-part-popup">
        <div className="input-group" style={{ display: 'block', width: '100%', maxHeight: 25 }}>
          <AddCounterCalenderDeadline
            setCalenderDeadline={setCalenderDeadline}
            deadline
            placeholder={'Wybierz deadline'}
          />
          {error && <span className="error">*Musisz wskazać nowy deadline</span>}
        </div>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={() => closeOrderPopup()}>
          Anuluj
        </button>
        <button className="button accept" onClick={handleDuplicate}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default Restore;
