import React from 'react';

const SearchBar = ({ handleSerchBar, searchValue, setSearchValue, width }) => {
  // const [searchValue, setSearchValue] = useState('')

  return (
    <div className="serchbar">
      <label>
        <span>Szukaj:</span>
        <input
          value={searchValue}
          className="form-control form-control-sm"
          style={{ width: width || 340 }}
          onChange={(e) => {
            setSearchValue(e.target.value);
            if (handleSerchBar) handleSerchBar(e.target.value);
          }}
        ></input>
      </label>
    </div>
  );
};

export default SearchBar;
