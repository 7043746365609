import React, { useState, useRef, useEffect } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

const Slider = ({
  spaceBetween,
  slidesPerView,
  slides,
  hasCustomNavigation,
  breakpoints,
  setName,
  extractFileName
}) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const swiperRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  useEffect(() => {
    const swiperInstance = swiperRef.current;

    if (slides?.length <= slidesPerView) {
      setIsEnd(true);
    }

    if (swiperInstance) {
      swiperInstance.on('slideChange', () => {
        setIsBeginning(swiperInstance.isBeginning);
        setIsEnd(swiperInstance.isEnd);
      });

      swiperInstance.on('reachEnd', () => {
        setIsEnd(true);
      });

      swiperInstance.on('fromEdge', () => {
        setIsEnd(false);
      });
    }
  }, []);

  const handleSetName = () => {
    if (extractFileName) {
      const name = extractFileName(slides[swiperRef.current.activeIndex].props.source);
      setName(name);
    }
  };

  const handlePrevButton = () => {
    swiperRef.current.slidePrev();
  };

  const handleNextButton = () => {
    swiperRef.current.slideNext();
  };

  return (
    <>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        breakpoints={breakpoints}
        loop={false}
        preventClicks={true}
        onSlideChange={handleSetName}
        keyboard={{
          enabled: true
        }}
        modules={[Keyboard, Pagination, Navigation]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {slides
          ? slides.map((slideContent, index) => {
              return (
                <SwiperSlide
                  key={`slide-${index}`}
                  style={{
                    height: '100%',
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {slideContent}
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
      {hasCustomNavigation ? (
        <>
          <i
            className={isBeginning ? 'slide-prev-btn disactive' : 'slide-prev-btn'}
            onClick={handlePrevButton}
            ref={prevBtnRef}
          ></i>
          <i
            className={isEnd ? 'slide-next-btn disactive' : 'slide-next-btn'}
            onClick={handleNextButton}
            ref={nextBtnRef}
          ></i>
        </>
      ) : null}
    </>
  );
};

export default Slider;
