import React, { useContext, useEffect, useRef, useState } from "react";
import banner from "../../../../../assets/banner3.png";
import orderServices from "../../../../services/orderServices";
import { ReformatContext } from "../../../../pages/e-commerce/Reformatting";
import { useSearchParams } from "react-router-dom";
import Dropdown from "../../../globalElements/Dropdown/Dropdown";
import variantsServices from "../../../../services/variantsServices";

const RadioInput = ({ setChosenOrder, chosenOrder, setChosenSource, reloadComponent }) => {
  const thirdConnectorRef = useRef();
  const secondConnectorRef = useRef();
  const firstConnectorRef = useRef();
  const [showErrorPopup, setErrorPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownInputRef = useRef();
  const [orderList, setOrderList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [renderOnce, setRenderOnce] = useState(true);
  const [radioActive, setRadioActive] = useState(false);

  const dropDownRef = useRef();

  useEffect(() => {
    setFile(null);
  }, [reloadComponent]);
  useEffect(() => {
    initOrderDropdownData("finished", "", 10);
  }, []);

  const handleDropdownElement = (e) => {
    orderServices
      .getOrderImages(!e.target.value ? e.target.children[0].value : e.target.value)
      .then((res) => {
        if (res[0]) setChosenSource({ pk: res[0].pk, image: res[0].image });
      })
      .catch((error) => {
        console.error(error);
        setErrorPopup(true);
      });
    setChosenSource(banner);
    setShowDropdown(false);

    if (searchParams.get("variant")) {
      setReformatData({
        ...reformatData,
        order_id: !e.target.value ? e.target.children[0].value : e.target.value,
        order_name: e.target.dataset.nameContent,
      });
    } else {
      variantsServices.getVariants(!e.target.value ? e.target.children[0].value : e.target.value).then((res) => {
        setReformatData({
          ...reformatData,
          order_id: !e.target.value ? e.target.children[0].value : e.target.value,
          order_name: e.target.dataset.nameContent,
          variant_id: res[0].id,
        }); //TODO: change this solution E-312
      });
    }

    setChosenOrder({ order_id: !e.target.value ? e.target.children[0].value : e.target.value, order_name: e.target.dataset.nameContent });
  };

  const initCheckOnOrderEdit = () => {
    if (searchParams.get("edit_order") || searchParams.get("create_format")) {
      if (reformatData.own_file) {
        thirdConnectorRef?.current?.parentNode.parentNode.classList.add("active");
        thirdConnectorRef.current.checked = true;
        setFile(reformatData.own_file);
        setRenderOnce(false);
      } else if (reformatData.external_url) {
        secondConnectorRef?.current?.parentNode.parentNode.classList.add("active");
        secondConnectorRef.current.checked = true;
        setRenderOnce(false);
      } else if (reformatData.order_id) {
        firstConnectorRef?.current?.parentNode.parentNode.classList.add("active");
        setRadioActive(true);
        setRenderOnce(false);
      }
    }
  };

  const initOrderDropdownData = (status, search = "", count = 100) => {
    orderServices.getOrdersNames(status, search, count).then((res) => {
      let oderList = res;

      let mapedOrders = oderList.map((el) => {
        return { value: el.id, displayName: el.action_name ? el.action_name : "Brak nazwy" };
      });

      setOrderList(mapedOrders);
    });
  };

  const handleDropdown = (search) => {
    setShowDropdown(true);
    initOrderDropdownData("finished", search, 1000);
  };

  useEffect(() => {
    if (renderOnce) {
      initCheckOnOrderEdit();
    }
  }, [reformatData]);

  const handleOrderInput = (e) => {
    handleDropdown(e.target.value);
  };

  return (
    <div className={"input-group"} ref={dropDownRef}>
      <input
        className="text-input"
        value={chosenOrder.order_name || ""}
        placeholder="Wybierz  z listy"
        onChange={handleOrderInput}
        ref={dropdownInputRef}
        onClick={() => setShowDropdown((prevState) => !prevState)}
      ></input>
      <span className="presentation"  onClick={() => setShowDropdown((prevState) => !prevState)}>
        <span className="arrow-down" />
      </span>
      {showDropdown && (
        <Dropdown
          data={orderList}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          campaignValue={chosenOrder.order_name}
          handleListElement={handleDropdownElement}
          showCheckbox={false}
          positionToElementRef={dropDownRef}
        />
      )}
    </div>
  );
};

export default RadioInput;
