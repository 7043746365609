/**
 * showCalender() - method that handle show and hide calender
 *
 * @param {*} calendarRef - calender container reference
 * @param {*} chosenDateError - information about any errors
 * @param {*} setChosenDateError - set error state
 * @param {*} setShadowActive - set shadow backgroun component
 * @param {*} isShadowActive - information about if shadow is active
 * @param {*} handleShadow - information if handle shadow
 */

const showCalender = (
  setShowCalender,
  chosenDateError,
  setChosenDateError,
  setShadowActive,
  isShadowActive,
  handleShadow
) => {
  setShowCalender((prevState) => !prevState);
  if (chosenDateError) setChosenDateError(false);
};

export default showCalender;
