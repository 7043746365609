import React, { useEffect, useState, useRef, useContext } from 'react';
import InputText from '../OrderBoxFields/InputText';
import RadioList from '../OrderBoxFields/RadioList';
import OrderBoxDropdown from '../OrderBoxFields/dropdownComponents/OrderBoxDropdown';
import AddProduct from '../OrderBoxFields/Products/AddProduct';
import Language from '../OrderBoxFields/Language';
import defaultLogo from '../../../../assets/e-com-pixabay 1.png';
import { AddNewOrderContext } from '../../../pages/e-commerce/AddNewOrder';
import LogoTypeChooser from '../OrderBoxFields/LogoTypeChooser';
import checkIfValueHasChildren from '../../../../functions/boxFieldsFunctions/checkIfValueHasChildren';
import DateInputCalendar from '../OrderBoxFields/DateInputCalendar';
import { getActualBoxFieldState } from '../../../../functions/boxFieldsFunctions/getActualBoxFieldData';
import { updateGLobalBoxFieldsData } from '../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData';
import BackgroundChooser from '../OrderBoxFields/BackgroundChooser';

const OrderBox = ({ boxData, target, variant, tabsArr, orderID, orderDeadlineFieldID, validate }) => {
  const [boxClassList, setBoxClassList] = useState(`box `);
  const [boxField, setBoxField] = useState();
  const [colorField, setColorField] = useState(null);
  const [dropdownArr, setDropdownArr] = useState(null);
  const [hasDropdown, setHasDropdown] = useState(false);
  const [hideBox, setHideBox] = useState(false);
  const { boxSettings, setBoxSettings, boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);

  //helpers for box Cenówka, for cotroling prices
  const [regularPrice, setRegularPrice] = useState(0);
  const [promoPrice, setPromoPrice] = useState(0);
  const [discountPriceFieldID, setDiscountPriceFieldID] = useState(null);

  const boxRef = useRef();

  const renderBox = () => {
    const boxParameter = boxData;
    let boxClass = 'box ';
    const isUnique = boxParameter.is_order_unique;
    switch (boxParameter.box_size) {
      case 3:
        boxClass += ` box-size-1`;
        break;
      case 2:
        boxClass += ` box-size-2`;
        break;
      case 1:
        boxClass += ` box-size-3`;
        break;
    }

    if (boxData.box.hidden_text) {
      boxClass += ` hidden`;
    }

    boxSettings.hidden_boxes.forEach((el) => {
      if (el.box_id === boxParameter.box.id && el.variants?.includes(variant)) {
        boxClass += ` hidden`;
      }
    });
    // temp.description = boxParameter.box.description;
    const boxFields = boxParameter.box.box_fields;
    let hasColorPicker;
    const boxFieldsArr = [];
    const dropdownElements = [];
    boxFields.forEach((field, index) => {
      if (!field.settings) {
        field.settings = {}; // Initialize it as an empty object if it is null or undefined
      }

      // Check if the 'placeholder' key exists in field.settings
      if (!Object.prototype.hasOwnProperty.call(field.settings, 'placeholder')) {
        if (boxParameter.box.description) {
          field.settings.placeholder = boxParameter.box.description;
        }
      } else if (boxParameter.box.description) {
        field.settings.placeholder = boxParameter.box.description;
      }
      let languageValidated = true;
      boxSettings.notValidatedBoxes.forEach((el) => {
        if (el.box_field == field.id) {
          const variantsList = el.variants;
          for (let i = 0; i < variantsList.length; i++) {
            if (variant == variantsList[i] || orderID == variantsList[i]) {
              if (field.field_type == 'client_language') languageValidated = false;
              boxClass += ` not-validated`;
              break;
            }
          }
        }
      });

      switch (field.field_type) {
        case 'input_text':
          boxFieldsArr.push(
            <InputText
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              description={field.name}
              textAreaSize={boxParameter.box_size}
              target={target}
              fieldRole={field.field_role}
              settings={field.settings}
              isPriceField={boxParameter.box.name == 'Cenówka'} //TODO: temporary solution, change this on backend
              setRegularPrice={setRegularPrice}
              setPromoPrice={setPromoPrice}
            />
          );

          if (field.settings.type == 'percentage') setDiscountPriceFieldID(field.id);
          break;
        case 'client_language':
          boxFieldsArr.push(
            <Language
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={orderID}
              boxFieldID={field.id}
              target={target}
              value={field}
              validated={languageValidated}
            />
          );
          break;
        case 'radio_list':
          if (checkIfValueHasChildren(field)) boxClass += ` has-children`;
          boxFieldsArr.push(
            <RadioList
              variant={variant}
              orderID={orderID}
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              target={target}
              value={field}
              validate={validate}
            />
          );
          break;
        case 'size':
          if (checkIfValueHasChildren(field)) boxClass += ` has-children`;
          boxFieldsArr.push(
            <RadioList
              variant={variant}
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={orderID}
              boxFieldID={field.id}
              target={target}
              value={field}
            />
          );
          break;
        case 'product':
          boxFieldsArr.push(
            <AddProduct
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              value={field}
              hidden={!boxClassList.includes('hidden')}
            />
          );
          break;
        case 'datetime':
          boxFieldsArr.push(
            <div className="calendar-container-width">
              <DateInputCalendar
                tabsArr={tabsArr}
                key={`order-box-${index}`}
                actualVariant={variant}
                boxFieldID={field.id}
                target={target}
                value={field}
                orderDeadlineFieldID={orderDeadlineFieldID}
                orderID={orderID}
                addNewOrder={true}
              />
            </div>
          );
          boxClass += ' no-overflow';
          break;
        case 'logotype':
          boxFieldsArr.push(
            <LogoTypeChooser
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              target={target}
              value={field}
              orderDeadlineFieldID={orderDeadlineFieldID}
              orderID={orderID}
            />
          );
          boxClass += ' no-overflow';
          break;
        case 'background':
          boxFieldsArr.push(
            <BackgroundChooser
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              target={target}
              value={field}
              orderDeadlineFieldID={orderDeadlineFieldID}
              orderID={orderID}
            />
          );
          boxClass += ' no-overflow';
          break;
        default:
          hasColorPicker = false;
          boxFieldsArr.push(
            <OrderBoxDropdown
              tabsArr={tabsArr}
              key={`order-box-${index}`}
              actualVariant={variant}
              boxFieldID={field.id}
              colorPicker={hasColorPicker}
              target={'checkbox'}
              value={field}
              boxRef={boxRef}
            />
          );

          setHasDropdown(true);
          break;
      }
    });

    if (checkVariantIndex() > 0 && isUnique) {
      boxClass += ` hidden`;
    }
    setBoxClassList(boxClass);
    setBoxField(boxFieldsArr);
    setDropdownArr(dropdownElements);
  };

  useEffect(() => {
    renderBox();
  }, [boxSettings, boxData]);

  const handleHideBox = (e) => {
    const box = boxRef.current;
    box.classList.toggle('hidden');
    let indexOf = null;
    const copyHideBoxes = [...boxSettings.hidden_boxes];
    copyHideBoxes.forEach((el, index) => {
      if (el.box_id == e.target.dataset.id) {
        if (el.variants.includes(variant)) {
          indexOf = el.variants.indexOf(variant);
          el.variants.splice(indexOf, 1);
        } else {
          el.variants.push(variant);
        }
      }
    });
    setBoxSettings({ ...boxSettings, hidden_boxes: copyHideBoxes });
  };

  const checkVariantIndex = () => {
    for (let i = 0; i < tabsArr.length; i++) {
      if (tabsArr[i].tab_vairant == variant) {
        return i;
      }
    }
  };

  const checkIfhideBox = (data) => {
    for (let i = 0; i < data?.length; i++) {
      if (data[i].is_required) return true;
    }

    return false;
  };

  const countDiscountPrice = () => {
    let percentageDiscountValue = null;

    if (promoPrice && regularPrice) {
      percentageDiscountValue = Math.floor((1 - parseInt(promoPrice) / parseInt(regularPrice)) * 100);
    }

    if (percentageDiscountValue) {
      const html = `<p>${percentageDiscountValue}%</p>`;
      const copyInputData = {
        ...getActualBoxFieldState(boxFieldsData, discountPriceFieldID, variant),
        value: { content: html }
      };
      updateGLobalBoxFieldsData(copyInputData, boxFieldsData, setBoxFieldsData, discountPriceFieldID, variant);
    }
  };
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    if (boxData.box.name == 'Cenówka') {
      countDiscountPrice();
    }
  }, [regularPrice, promoPrice]);

  return (
    <>
      <div
        className={boxClassList}
        style={hasDropdown ? { order: boxData.position } : { order: boxData.position }}
        ref={boxRef}
        data-box-id={boxData.box.id}
      >
        <div className="box-wrapper">
          <div className="right-col">
            <div className="header">
              <div className="left-side">
                <img src={boxData.box.icon ? boxData.box.icon : defaultLogo}></img>
                <h3>{boxData.box.name}</h3>
              </div>
              {checkIfhideBox(boxData?.box?.box_fields) ? (
                <div className="hide-box">
                  <label className="checkbox" onClick={handleHideBox} data-id={boxData.box.id}>
                    <div className="switch-container">
                      <div
                        className={`switch ${boxRef?.current?.classList.contains('hidden') ? 'off' : 'on'}`}
                        data-id={boxData.box.id}
                      >
                        <div className="switch-button" data-id={boxData.box.id} />
                      </div>
                    </div>
                  </label>
                </div>
              ) : null}
            </div>

            <div className={colorField ? 'fields column-2' : 'fields'}>
              {colorField ? (
                <>
                  <div className="color-column">{colorField}</div>
                  <div className="fields-column">{boxField}</div>
                </>
              ) : (
                <>
                  {/*<span className="field-desc">{boxData.box.description || ""}</span>*/}
                  {boxField}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {dropdownArr}
    </>
  );
};

export default OrderBox;
