import React, {useMemo} from 'react';

const OrderHistory = ({data}) => {

    const history = useMemo(() => Object.keys(data.history || {}).map(key => data.history[key]), [data.history]);
    const notifications = useMemo(() => Object.keys(data.notifications || {}).map(key => data.notifications[key]), [data.notifications]);

    return (
        <div className="order-history">
            <h2>Historia zmian</h2>
            <pre style={{width: '100%', height: "300px", marginBottom: "20px"}}>{JSON.stringify(history, null, 2)}</pre>
            <h2>Powiadomienia</h2>
            <pre style={{width: '100%', height: "300px"}}>{JSON.stringify(notifications, null, 2)}</pre>
        </div>
    );
}

export default OrderHistory;
