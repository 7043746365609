import React, { useEffect, useState } from 'react';
import toolsServices from '../../services/toolsServices';
import usePopup from '../../helper/usePopup';
import Loader from '../../elements/globalElements/Loader/Loader';
import LOADER_TYPE from '../../elements/globalElements/Loader/LOADER_TYPE';
import SpecificationBox from '../../elements/e-commerce/Specification/SpecificationBox';
import SomethingWentWrong from '../../elements/e-commerce/Popup/AddOrder/SomethingWentWrong';

const Specification = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [versionHistory, setVersionHistory] = useState([]);
  const { initPopup } = usePopup();

  useEffect(() => {
    toolsServices
      .getVersionHistory()
      .then((res) => {
        setVersionHistory(res);
        setLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<SomethingWentWrong type={'negative'} info={'history_varsion'} />);
        setLoadingData(false);
      });
  }, []);

  return (
    <div className="specification-section">
      <div className="box-wrapper">
        {loadingData ? (
          <Loader type={LOADER_TYPE.LOAD_DATA} width={25} height={25} position={`absolute`} startAnimation={true} />
        ) : versionHistory.length ? (
          versionHistory.map((el, index) => <SpecificationBox key={`spec-box-${index}`} data={el} />)
        ) : (
          <span>Aktualnie nie możemy wczytać specyfikacji e-commerce</span>
        )}
      </div>
    </div>
  );
};

export default Specification;
