import React from 'react';
import { ReactComponent as Error } from '../../../../../assets/popups/error.svg';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
const ErrorLoadingData = () => {
  const navigate = useNavigate();
  const { closeOrderPopup } = usePopup();
  const handleBackToDashboard = () => {
    closeOrderPopup();
    navigate('/');
  };
  return (
    <div className="error-loading-popup">
      <div className="upper-part-popup">
        <Error />
        <h3>
          Błąd wczytywania danych <br /> Spróbuj ponownie później.
        </h3>
        <p>
          Pomoc: <a href="mailto:e-commercd@app4ad.pl">e-commerce@app4ad.pl</a>
        </p>
      </div>
      <div className="single-button">
        <button className="button accept" onClick={handleBackToDashboard}>
          OK
        </button>
      </div>
    </div>
  );
};

export default ErrorLoadingData;
