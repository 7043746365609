import React from 'react';
import { ReactComponent as Info } from '../../../../../assets/popups/info.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';

const TakeOver = () => {
  const { closeOrderPopup } = usePopup();
  const handleBackToDashboard = () => {
    closeOrderPopup();
  };
  return (
    <div className="take-over">
      <div className="upper-part-popup">
        <Info />
        <h3>Projekt został pomyślnie przypisany do Ciebie.</h3>
      </div>
      <div className="single-button">
        <button className="button accept" onClick={handleBackToDashboard}>
          OK
        </button>
      </div>
    </div>
  );
};

export default TakeOver;
