import React from 'react';
import LOADER_TYPE from './LOADER_TYPE';

const Loader = ({ type, handleAction, startAnimation, width, height, position }) => {
  return (
    <>
      <div
        className={`loader ${type} ${startAnimation ? `animate` : ``} ${`${position || `static`}-loader`}`}
        {...(handleAction ? { onClick: handleAction } : {})}
        style={{ width: `${width || 25}px`, height: `${height || 25}px` }}
      ></div>
      {type === LOADER_TYPE.LOAD_DATA && <div className="loader-bgc"></div>}
    </>
  );
};

export default React.memo(Loader);
