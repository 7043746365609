/**
 * choseDefaultNameLanguage() - method thath handle displayed text in box fields
 *
 *
 * @param {Object} field - box field data
 * @param {String} boxesLanguage - chosen boxes language
 * @param {String} parameter - value that mark what kind of field value we need (id or name)
 * @returns string (translated box fields text values)
 */

export const choseDefaultNameLanguage = (field, boxesLanguage, parameter) => {
  const translations = field.translations;
  let result;

  if (parameter == 'id') {
    translations.forEach((el) => {
      if (el.box_client_language == boxesLanguage) {
        result = el.id;
      }
    });

    if (result == null) {
      result = field.id;
    }
  } else if (parameter == 'name') {
    translations.forEach((el) => {
      if (el.box_client_language == boxesLanguage) {
        result = el.name;
      }
    });

    if (result == null) {
      result = field.default_name;
    }
  }

  return result;
};
