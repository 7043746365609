import productsServices from '../components/services/productsServices';
import logotypeServices from '../components/services/logotypeServices';
import handleDownload from './handleDownload';
import { getFileBaseName } from './getFileBaseName';

const getZipPackage = async (variantID, type) => {
  let response;

  switch (type) {
    case 'product':
      response = await productsServices.downloadProductsZipFileByVariants(variantID);
      break;
    case 'logotype':
      response = await logotypeServices.getLogotypeZip(variantID);
      break;
  }

  const basename = getFileBaseName(response.file);
  const fileToDownlaod = response.file;
  handleDownload(basename, fileToDownlaod);
};

export default getZipPackage;
