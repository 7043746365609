import orderServices from '../../../../services/orderServices';

export const onDragEnd = (result, columns, setColumns, userId_toAsign, current_userId, isClient) => {
  if (!result.destination) return;

  const { source, destination } = result;
  //newOrderBox
  if (destination.droppableId === 'MyProjectsBox' && source.droppableId === 'newOrderBox') {
    orderServices
      .assignProjectToCurrentAccUser(columns.newOrderBox.items[source.index].id, current_userId, true)
      .then((r) => {
        console.log('CURRENT');
        console.log(current_userId);
        console.log('response from assign proejct to user ACC ');
        console.log(r);
      });
  }
  if (destination.droppableId === 'assignedToPerson') {
    if (isClient) {
      orderServices
        .changeOrderDetail(columns.MyProjectsBox.items[source.index].id, {
          client_managed_by: userId_toAsign
        })
        .then((r) => {
          console.log('response from assign proejct to user CLIENT ');
          console.log(r);
        });
    } else {
      orderServices.assignProjectToDtpUser(columns.MyProjectsBox.items[source.index].id, userId_toAsign).then((r) => {
        console.log('response from assign proejct to user ACC ');
        console.log(r);
      });
    }
  }
  if (destination.droppableId === 'MyProjectsBox' && source.droppableId === 'assignedToPerson') {
    orderServices
      .removeFromAssignedDtpProjectToAccProject(columns.assignedToPerson.items[source.index].id, current_userId)
      .then((r) => {
        console.log('response from assign proejct to user ACC ');
        console.log(r);
      });
  }

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};
