import React from 'react';

import MP4 from '../../src/assets/mp4.svg';
import { ReactComponent as AVI } from '../../src/assets/avi.svg';
import { ReactComponent as SVG } from '../../src/assets/svg.svg';

export const getFileExtension = (url) => {
  // Sprawdzamy, czy URL jest typu string i nie jest pusty
  if (typeof url === 'string' && url.length > 0) {
    // Dekodujemy URL, aby uniknąć problemów z zakodowanymi znakami
    const decodedUrl = decodeURIComponent(url);

    // Usuwamy wszelkie parametry zapytania, które mogą występować po nazwie pliku
    const fileName = decodedUrl.split('?')[0].split('#')[0];

    // Dzielimy ostateczną nazwę pliku względem znaku '.' i wyodrębniamy rozszerzenie
    const parts = fileName.split('/').pop().split('.');

    // Sprawdzamy, czy po podziale otrzymaliśmy więcej niż jedną część (co oznacza obecność rozszerzenia)
    if (parts.length > 1) {
      return parts.pop().toLowerCase(); // Zwracamy ostatnią część tekstu (rozszerzenie), zamienione na małe litery
    }
  }

  return ''; // Zwracamy pusty string, jeśli URL nie zawiera rozszerzenia
};

export const getExtensionIcon = (extension) => {
  switch (extension) {
    case 'mp4':
      return (
        <figure>
          <img src={MP4}></img>
        </figure>
      );
    case 'avi':
      return <AVI width={60} height={60} />;
    case 'svg':
      return <SVG width={60} height={60} />;
  }
};
