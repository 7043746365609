import React, { useCallback, useState, useContext, useEffect } from 'react';
import RaportsRadioButtons from './RaportsRadioButtons';
import Loader from '../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../globalElements/Loader/LOADER_TYPE';
import { MainAppContext } from '../../../MainAppComponent';
import handleDataListElements from '../../../../functions/handleDataListElements';
import reportServices from '../../../services/reportServices';
import handleDownload from '../../../../functions/handleDownload';
import { getFileBaseName } from '../../../../functions/getFileBaseName';
import RAPORTS_ENUMS from './RAPORTS_ENUMS';
import usePopup from '../../../helper/usePopup';
import CalenderFromTo from '../../globalElements/CalenderFromTo';
import { ReactComponent as TypeIcon } from '../../../../assets/raports/type.svg';
import { ReactComponent as RangeIcon } from '../../../../assets/raports/range.svg';
import { ReactComponent as LanguageIcon } from '../../../../assets/raports/language.svg';
import { ReactComponent as GraphicType } from '../../../../assets/raports/graphic_type.svg';
import FillMissingInputs from '../Popup/AddOrder/FillMissingInputs';

const layoutSettings = [
  {
    title: 'Rodzaj zliczania',
    values: [
      {
        id: RAPORTS_ENUMS.METHOD_TYPE.FILE_CREATION,
        default_name: 'wg. dat plików'
      },
      {
        id: RAPORTS_ENUMS.METHOD_TYPE.ORDER_FINISH,
        default_name: 'wg. dat zamknięcia zlecenia'
      }
    ],
    layoutDirection: 'column',
    type: 'methodType',
    isValidated: true,
    validationMessage: '* Wybierz rodzaj zliczania',
    icon: <TypeIcon />
  },
  {
    title: 'Zakres',
    values: [],
    layoutDirection: 'column',
    type: 'range',
    isValidated: true,
    validationMessage: '* Wybierz zakres',
    icon: <RangeIcon />
  },
  {
    title: 'Język',
    values: [],
    layoutDirection: 'column',
    type: 'language',
    isValidated: true,
    validationMessage: '* Wybierz język',
    icon: <LanguageIcon />
  },
  {
    title: 'Rodzaj grafik',
    values: [
      { id: RAPORTS_ENUMS.TYPE.REJECTED, default_name: 'odrzucone' },
      { id: RAPORTS_ENUMS.TYPE.ACCEPTED, default_name: 'zaakceptowane' },
      { id: RAPORTS_ENUMS.TYPE.ALL, default_name: 'wszystkie' }
    ],
    layoutDirection: 'column',
    type: 'type',
    isValidated: true,
    validationMessage: '*Wybierz rodzaj grafik',
    icon: <GraphicType />
  }
];

const RaportsContainer = () => {
  const { generalSettings } = useContext(MainAppContext);

  //settings
  const [radioListSettings, setRadioListSettings] = useState(layoutSettings);

  //states for generator API
  const [calenderIsValidated, setCalenderIsValidated] = useState(true);
  const [rangeData, setRangeData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [chosenDateFrom, setChosenDateFrom] = useState('');
  const [chosenDateTo, setChosenDateTo] = useState('');
  const [getRaport, setGetRaport] = useState(false);
  const [raportName, setRaportName] = useState('');
  const [chosenMethod, setChosenMethod] = useState('');
  const [chosenMethodName, setChosenMethodName] = useState('');
  const [chosenType, setChosenType] = useState([]);
  const { initPopup } = usePopup();

  const initData = () => {
    const radioListSettingsCopy = [...radioListSettings];
    for (let i = 0; i < generalSettings.length; i++) {
      const boxFields = generalSettings[i].box.box_fields;
      //set up data for field range (zakres) and for language (Język), get this data from backend
      for (let j = 0; j < boxFields.length; j++) {
        if (['size'].includes(boxFields[j].field_type)) {
          radioListSettingsCopy[1].values = [
            ...boxFields[j].box_field_dictionary,
            { id: 'reformats', default_name: 'przeformatowania' }
          ];
        } else if (['client_language'].includes(boxFields[j].field_type)) {
          radioListSettingsCopy[2].values = boxFields[j].box_field_dictionary;
        }
      }
    }

    setRadioListSettings(radioListSettingsCopy);
  };

  useEffect(() => {
    initData();
  }, [generalSettings]);

  const handleRadioListElement = useCallback(
    (e) => {
      const element = e.currentTarget;
      if (element.dataset.inputType === 'range') {
        const updateState = handleDataListElements(element.value, rangeData);
        setRangeData(updateState);
      } else if (element.dataset.inputType === 'language') {
        setLanguageData(element.value);
      } else if (element.dataset.inputType === 'type') {
        setChosenType([element.value]);
      } else {
        setChosenMethod(element.value);
        setChosenMethodName(element.value);
      }
    },
    [rangeData, languageData, chosenMethod]
  );

  const validateFields = () => {
    const radioListSettingsCopy = [...radioListSettings];
    let allFieldsValidated = true;

    if (!chosenMethod) {
      radioListSettingsCopy[0].isValidated = false;
      allFieldsValidated = false;
    } else {
      radioListSettingsCopy[0].isValidated = true;
    }
    if (rangeData.length === 0) {
      radioListSettingsCopy[1].isValidated = false;
      allFieldsValidated = false;
    } else {
      radioListSettingsCopy[1].isValidated = true;
    }
    if (languageData.length === 0) {
      radioListSettingsCopy[2].isValidated = false;
      allFieldsValidated = false;
    } else {
      radioListSettingsCopy[2].isValidated = true;
    }
    if (chosenType.length === 0) {
      radioListSettingsCopy[3].isValidated = false;
      allFieldsValidated = false;
    } else {
      radioListSettingsCopy[3].isValidated = true;
    }
    if (!chosenDateFrom && !chosenDateTo) {
      setCalenderIsValidated(false);
      allFieldsValidated = false;
    } else {
      setCalenderIsValidated(true);
    }

    return allFieldsValidated;
  };

  const downloadRaport = () => {
    if (validateFields()) {
      setGetRaport(true);
      reportServices
        .getReport(chosenMethod, chosenType[0], chosenDateTo, chosenDateFrom, null, raportName, languageData, rangeData)
        .then((res) => {
          setGetRaport(false);

          const basename = getFileBaseName(res.file);
          handleDownload(basename, res.file);
        })
        .catch((error) => {
          console.log('GET RAPORT ERROR');
          console.error(error);
          setGetRaport(false);
        });
    } else {
      initPopup(<FillMissingInputs />);
    }
  };

  const getChosenData = (type) => {
    if (type === 'range') return rangeData;
    if (type === 'language') return languageData;
    if (type === 'type') return chosenType;
    if (type === 'methodType') return chosenMethodName;
  };

  return (
    <div className="raports-container">
      <div className="raport-headers">
        <div className={`raport-header`}>
          <h3>Nazwa raportu</h3>
          <div className={`value-container raport_name`}>
            <input
              className="input-text"
              placeholder="wpisz"
              onChange={(e) => setRaportName(e.target.value)}
              value={raportName}
            ></input>
          </div>
        </div>
      </div>
      <div className="raports-settings">
        <div className="raports-wrapper">
          <CalenderFromTo
            chosenDateFrom={chosenDateFrom}
            isValidated={calenderIsValidated}
            setChosenDateFrom={(value) => {
              setChosenDateFrom(value);
            }}
            chosenDateTo={chosenDateTo}
            setChosenDateTo={(value) => {
              setChosenDateTo(value);
            }}
            displaySearchButton={false}
          />
          {radioListSettings.map((el, index) => {
            if (el.values.length > 0) {
              return (
                <RaportsRadioButtons
                  key={`raports-radio-list-${index}`}
                  title={el.title}
                  values={el.values}
                  layoutDirection={el.layoutDirection}
                  type={el.type}
                  handleRadioListElement={handleRadioListElement}
                  chosenData={getChosenData(el.type)}
                  isValidated={el.isValidated}
                  validationMessage={el.validationMessage}
                  icon={el.icon}
                />
              );
            }
          })}
        </div>
        <button className="generate-raport" onClick={downloadRaport}>
          {getRaport ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} /> : 'Generuj'}
        </button>
      </div>
    </div>
  );
};

export default RaportsContainer;
