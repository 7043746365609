/**
 * Method that helps in validation of chosen sizes in Reformatting.js
 *
 * @param {array} basicSizes
 * @param {array} customSizes
 * @returns true or false
 */

const checkIfSizesAreValidated = (basicSizes, customSizes) => {
  if (basicSizes) {
    for (let i = 0; i < basicSizes.length; i++) {
      if (basicSizes[i].chosen_sizes?.length) return true;
    }
  }

  if (customSizes?.length) return true;

  return false;
};

export default checkIfSizesAreValidated;
