import React from 'react';
import SectionHeader from './sectionsElements/SectionHeader';
import RadioInput from './sectionsElements/RadioInput';

const SourceFile = ({
  setChosenOrder,
  chosenOrder,
  setChosenSource,
  chosenSource,
  setSourceType,
  validate,
  reloadComponent
}) => {
  return (
    <div className={validate ? 'source-file' : 'source-file not-validated'}>
      <SectionHeader headerType={'source_file'} />
      <RadioInput
        reloadComponent={reloadComponent}
        setSourceType={setSourceType}
        setChosenSource={setChosenSource}
        chosenSource={chosenSource}
        setChosenOrder={setChosenOrder}
        chosenOrder={chosenOrder}
      />
      {chosenSource?.image && (
        <div className="banner-view">
          <img src={chosenSource.image}></img>
        </div>
      )}
    </div>
  );
};

export default SourceFile;
