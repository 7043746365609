import React, { useContext } from 'react';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import usePopup from '../../../../../helper/usePopup';
import orderServices from '../../../../../services/orderServices';
import duplicateOrder from './functions/duplicateOrder';
import { useNavigate } from 'react-router-dom';
import Duplicate from '../../../Popup/AddOrder/Duplicate';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const DuplicateButton = ({ status, orderID, rowData }) => {
  const { user } = useContext(UserContext);
  const { closePopup, initPopup } = usePopup();
  const navigate = useNavigate();

  const handleAction = (orderName, calenderDeadline) => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('DuplicateButton action', sentryContext);

    orderServices
      .cloneOrder(orderID, calenderDeadline, orderName)
      .then((res) => {
        duplicateOrder(status, res.id, navigate, res?.order_reformat?.id || null);
        closePopup();
      })
      .catch((error) => {
        console.error(error);
        closePopup();
      });
  };
  return (
    <>
      {shouldDisplayButton('duplicate', rowData?.order_status, user) !== 'Brak' && (
        <>
          <button
            className={`draft-duplicate ${shouldDisplayButton('duplicate', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={() =>
              initPopup(
                <Duplicate status={status} orderID={orderID} contentType={'clone'} handleAction={handleAction} />
              )
            }
          >
            Powiel
          </button>
        </>
      )}
    </>
  );
};

export default DuplicateButton;
