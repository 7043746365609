import React, { useEffect, useState } from 'react';
import AssignedProjectBox from '../globalelements/AssignedProjectBox';
import FilterBar from '../../../globalElements/Filters/FilterBar';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import SearchBar from '../../../globalElements/SearchBar';
import orderServices from '../../../../services/orderServices';
import { ENUMS } from '../helper/segregateOrdersColumns';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import { ReactComponent as OrderIcon } from '../../../../../assets/team/order.svg';
import FILTER_BAR_TYPES from '../../../globalElements/Filters/ENUMS/FILTER_BAR_TYPE';

const MyProjectsBox = ({
  data,
  setIsDroppableToTeam,
  columns,
  setColumns,
  permission,
  setRealoadComponent,
  isDroppableToMyProjects,
  myProjectBoxLoading,
  setMyProjectBoxLoading,
  setOrderId,
  startProjectRef,
  projectAdditionalActionsRef,
  orderId,
  userType
}) => {
  const [assignedPopup, setAssignePopup] = useState({
    showPopup: false,
    order_id: null
  });
  const [searchValue, setSearchValue] = useState('');
  const [externalEndoint, setExternalEndpoint] = useState('');

  const handleSerchBar = () => {
    setMyProjectBoxLoading(true);
    orderServices.getOrderAssignedToUserSordetBy(null, searchValue, externalEndoint).then((res) => {
      setColumns({
        ...columns,
        [ENUMS.COLUMNS.MyProjectsBox]: {
          ...[ENUMS.COLUMNS.MyProjectsBox],
          items: res
        }
      });
      setMyProjectBoxLoading(false);
    });
  };

  useEffect(() => {
    if (searchValue) {
      const timer = setTimeout(() => {
        handleSerchBar();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchValue, externalEndoint]);

  useEffect(() => {
    if (externalEndoint) {
      handleSerchBar();
    }
  }, [externalEndoint]);

  return (
    <div
      className={permission === 'CLIENT' ? 'my-project-box-container box box-CLIENT' : 'my-project-box-container box'}
    >
      <div className="title-row">
        <OrderIcon />
        <h1>
          {permission === 'CLIENT' ? 'Moje projekty' : 'Moje aktywne projekty'} ({data.length})
        </h1>
      </div>
      <div className="filter-row">
        <FilterBar
          showPerAccount={false}
          showStatus={true}
          showDeadline={true}
          filterType={FILTER_BAR_TYPES.MY_PROJECT_BOX}
          setDataLoader={setMyProjectBoxLoading}
          setExternalEndpoint={setExternalEndpoint}
        />
        <SearchBar setSearchValue={setSearchValue} searchValue={searchValue} width={`234px`} />
      </div>
      <Droppable droppableId={'MyProjectsBox'} key={'2'} isDropDisabled={!isDroppableToMyProjects}>
        {(provided) => {
          return (
            <div
              className="boxes-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              // style={data?.length > 0 ? { paddingBottom: "300px" } : { height: "100%" }}
            >
              {data?.length > 0 &&
                data.map((element, index) => {
                  return (
                    <Draggable key={`myproject-${element.id}`} draggableId={`myproject-${element.id}`} index={index}>
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              ...provided.draggableProps.style
                            }}
                            onMouseDown={() => setIsDroppableToTeam(true)}
                          >
                            <AssignedProjectBox
                              setRealoadComponent={setRealoadComponent}
                              setAssignePopup={setAssignePopup}
                              data={element}
                              id={element.id}
                              key={`myproject-${element.id}`}
                              my_project
                              permission={permission}
                              setOrderId={setOrderId}
                              startProjectRef={startProjectRef}
                              projectAdditionalActionsRef={projectAdditionalActionsRef}
                              orderId={orderId}
                              setLoading={setMyProjectBoxLoading}
                              hasMarginRight={true}
                              userType={userType}
                              showAccAction={true}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      {myProjectBoxLoading && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
    </div>
  );
};

export default MyProjectsBox;
