import React, { useContext } from 'react';
import POPUP_TYPES from '../../elements/e-commerce/Popup/ENUMS/POPUP_TYPES';
import { MainAppContext } from '../../MainAppComponent';

const ActionDataButtons = ({ handleActionButton, isCorrection, reformat, isEdit }) => {
  const { showImage } = useContext(MainAppContext);
  return showImage !== undefined && showImage !== null && showImage ? null : (
    <div className={`action-data ${isCorrection ? `action-data-correction` : null}`}>
      <button className="send" data-name={POPUP_TYPES.POSITIVE} onClick={handleActionButton}>
        {isCorrection ? 'Wyślij koretkę' : 'Wyślij do realizacji'}
      </button>
      {!isCorrection && (
        <button className={'save'} data-name={POPUP_TYPES.NEUTRAL} onClick={handleActionButton}>
          Zapisz szkic
        </button>
      )}
      <button className="cancel" data-name={POPUP_TYPES.NEGATIVE} onClick={handleActionButton}>
        {isCorrection ? 'Anuluj' : isEdit ? 'Anuluj zlecenie' : 'Usuń zlecenie'}
      </button>
    </div>
  );
};

export default ActionDataButtons;
