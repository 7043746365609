import React, { useEffect, useState } from 'react';
import FILTER_BAR_DROPDOWN_TYPES from '../ENUMS/FILTER_BAR_DROPDOWN_TYPES';
import Dropdown from '../../Dropdown/Dropdown';
import CalenderFromTo from '../../CalenderFromTo';

const FilterTabs = ({
  showPerAccount,
  showStatus,
  showDeadline,
  tabsRef,
  isPerAccountChosen,
  isStatusChosen,
  isDedlineChosen,
  setDropdownType,
  filterData,
  dropdownType,
  filterRef,
  handleDropdownListElements,
  handleCheckBox,
  setShowDropdown,
  dropdownRef,
  chosenDateFrom,
  setChosenDateFrom,
  chosenDateTo,
  setChosenDateTo,
  getFilteredData,
  calenderRef
}) => {
  const [showUser, setShowUser] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleShowDropdown = (e, type) => {
    switch (type) {
      case 'user':
        setShowUser((prevState) => !prevState);
        setShowType(false);
        setShowCalendar(false);
        break;
      case 'type':
        setShowType((prevState) => !prevState);
        setShowUser(false);
        setShowCalendar(false);
        break;
      case 'deadline':
        setShowCalendar((prevState) => !prevState);
        setShowType(false);
        setShowUser(false);
        break;
    }
    setDropdownType(e.target.dataset.tabType);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        handleBlurDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);
  const handleBlurDropdown = () => {
    setShowUser(false);
    setShowType(false);
    setShowCalendar(false);
  };
  return (
    <>
      <span id="label">Filtruj:</span>
      {showPerAccount && (
        <div
          className={`btn btn-white dropdown-toggle`}
          data-tab-type={FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT}
          ref={(el) => tabsRef.current.push(el)}
        >
          <span
            {...(isPerAccountChosen && {
              style: { color: `#333333`, fontWeight: 700 }
            })}
            data-tab-type={FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT}
            onClick={(e) => handleShowDropdown(e, 'user')}
          >
            User
          </span>
          {showUser && (
            <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
              {/*{[FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT].includes(dropdownType) && (*/}
              <Dropdown
                data={filterData['per_account']}
                dropdownType={dropdownType}
                handleListElement={handleDropdownListElements}
                displayCheckbox={true}
                handleCheckBox={handleCheckBox}
                positionToElementRef={filterRef}
                setShowDropdown={setShowDropdown}
              ></Dropdown>
              {/*)}*/}
            </div>
          )}
        </div>
      )}
      {showStatus && (
        <div
          className={`btn btn-white dropdown-toggle`}
          data-tab-type={FILTER_BAR_DROPDOWN_TYPES.STATUS}
          ref={(el) => tabsRef.current.push(el)}
        >
          <span
            {...(isStatusChosen && {
              style: { color: `#333333`, fontWeight: 700 }
            })}
            data-tab-type={FILTER_BAR_DROPDOWN_TYPES.STATUS}
            onClick={(e) => handleShowDropdown(e, 'type')}
          >
            Typ/Type
          </span>
          {showType && (
            <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
              {/*{[FILTER_BAR_DROPDOWN_TYPES.STATUS].includes(dropdownType) && (*/}
              <Dropdown
                data={filterData['status']}
                dropdownType={dropdownType}
                handleListElement={handleDropdownListElements}
                displayCheckbox={true}
                handleCheckBox={handleCheckBox}
                positionToElementRef={filterRef}
                setShowDropdown={setShowDropdown}
              ></Dropdown>
              {/*)}*/}
            </div>
          )}
        </div>
      )}
      {showDeadline && (
        <div
          className={`btn btn-white dropdown-toggle`}
          data-tab-type={FILTER_BAR_DROPDOWN_TYPES.DEADLINE}
          ref={(el) => tabsRef.current.push(el)}
        >
          <span
            {...(isDedlineChosen && {
              style: { color: `#333333`, fontWeight: 700 }
            })}
            data-tab-type={FILTER_BAR_DROPDOWN_TYPES.DEADLINE}
            onClick={(e) => handleShowDropdown(e, 'deadline')}
          >
            Deadline
          </span>
          {showCalendar && (
            <div className={`dropdown dropdown-checkbox`} ref={dropdownRef}>
              {/*{![FILTER_BAR_DROPDOWN_TYPES.STATUS, FILTER_BAR_DROPDOWN_TYPES.PER_ACCOUNT].includes(dropdownType) && (*/}
              <CalenderFromTo
                isValidated={true}
                chosenDateFrom={chosenDateFrom}
                setChosenDateFrom={setChosenDateFrom}
                chosenDateTo={chosenDateTo}
                setChosenDateTo={setChosenDateTo}
                handleFilter={getFilteredData}
                calenderRef={calenderRef}
                setShowDropdown={setShowDropdown}
                filter={true}
                displaySearchButton={true}
              />
              {/*)}*/}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FilterTabs;
