export const addNewBoxFieldVariant = (
  variantsObj,
  boxFieldsData,
  setBoxFieldsData,
  actualVariant,
  uniqueBoxData,
  notCopyValueBoxesArr
) => {
  let result = [];

  result = boxFieldsData.map((value) => {
    if (!checkIfFieldIsUnique(value.box_field, uniqueBoxData)) {
      if (value.variants?.length > 0) {
        const lastIndex = value.variants.length - 1;
        const lastArrayElement = value.variants[lastIndex];
        if (notCopyValueBoxesArr.includes(value.box_field)) {
          value.variants = [...value.variants, { ...lastArrayElement, variant_id: actualVariant, value: [] }];
        } else if (value.box_field !== 67) {
          value.variants = [...value.variants, { ...lastArrayElement, variant_id: actualVariant }];
        }
      } else {
        value.variants = [...value.variants, variantsObj];
      }
    }

    return value;
  });

  setBoxFieldsData(result);
};

const checkIfFieldIsUnique = (field_id, uniqueBoxData) => {
  let result = false;
  uniqueBoxData.box_fields.forEach((el) => {
    if (el.id == field_id) result = true;
  });

  return result;
};
