import React from 'react';
import { ReactComponent as Warning } from '../../../../../assets/popups/warning.svg';
import usePopup from '../../../../helper/usePopup';
const SomethingWentWrong = () => {
  const { closeOrderPopup } = usePopup();
  return (
    <div className="wrong-popup">
      <Warning />
      <h3>Coś poszło nie tak, spróbuj ponownie.</h3>
      <p>
        Pomoc: <a href="mailto:e-commerce@app4ad.pl">e-commerce@app4ad.pl</a>
      </p>
      <div className="single-button">
        <button className="button accept" onClick={() => closeOrderPopup()}>
          OK
        </button>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
