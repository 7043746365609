import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
//types are based on ReactQuill theme types
export const EDITOR_TYPES = {
  BUBBLE: 'bubble', //toolbar in the form of a tooltip
  SNOW: 'snow' //toolbar in the traditional form
};

const TextEditor = ({ saveData, initialValue, placeholder, readOnly, style, editorType }) => {
  const [editorContent, setEditorContent] = useState('');
  const quillRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const removeWhitespaceInPTags = (input = '') => {
    //remove white space at the beginning and the end of a content between <p></p> html tags
    const regex = /<p>\s*(.*?)\s*<\/p>/g;
    const result = input.replace(regex, (match, p1) => {
      return `<p>${p1}</p>`;
    });

    return result;
  };

  const saveTextEditorData = (html) => {
    const trimmedHtml = removeWhitespaceInPTags(html);
    saveData(trimmedHtml);
  };

  const sortClassesInHtml = (htmlString) => {
    if (!htmlString) return htmlString;

    const regex = /(\bclass\s*=\s*)(['"])(.*?)\2/gi;

    return htmlString.replace(regex, (_, prefix, quote, classValue) => {
      const classes = classValue.split(/\s+/).filter(Boolean);
      classes.sort();
      return prefix + quote + classes.join(' ') + quote;
    });
  };

  const handleChange = (html) => {
    const sortedHtmlAttributes = sortClassesInHtml(html);
    setEditorContent(sortedHtmlAttributes);
    saveTextEditorData(sortedHtmlAttributes);
  };

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setEditorContent(sortClassesInHtml(initialValue));
    }
  }, [initialValue]);

  const handleReturnStyles = () => {
    const temp = { ...style };
    if (isFocused && Object.keys(style).length > 0) {
      temp.border = '1px solid #1ab394';
    }

    return temp;
  };
  return (
    <ReactQuill
      ref={quillRef}
      theme={editorType ? editorType : EDITOR_TYPES.BUBBLE}
      value={editorContent}
      onChange={handleChange}
      style={handleReturnStyles()}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      placeholder={placeholder ? placeholder : 'kliknij, aby wprowadzić dodatkowe informacje...'}
      readOnly={readOnly}
      modules={{
        toolbar: [['bold'], ['image'], ['clean'], [{ color: ['#eb008c', '#000000'] }]]
      }}
    />
  );
};

export default TextEditor;
