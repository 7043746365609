import React, { useContext, useEffect, useState } from 'react';
import { AddNewOrderContext } from '../../../../pages/e-commerce/AddNewOrder';
import { choseDefaultNameLanguage } from '../../../../../functions/boxFieldsFunctions/choseDefaultNameLanguage';
import { getActualBoxFieldState } from '../../../../../functions/boxFieldsFunctions/getActualBoxFieldData';
import { updateGLobalBoxFieldsData } from '../../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData';
import { getBoxFieldContent } from '../../../../../functions/boxFieldsFunctions/getBoxFieldContent';
import { DROPDOWN_TYPE } from './ENUMS/DROPDOWN_ENUM';
import checkIfContentIncludeID from '../../../../../functions/boxFieldsFunctions/checkIfContentIncludeID';
import Dropdown from '../../../globalElements/Dropdown/Dropdown';

const OrderBoxDropdownContainer = ({
  dropdownRef,
  value,
  actualVariant,
  boxFieldID,
  dropdownSetting,
  setDropdownSetting,
  showCheckbox
}) => {
  const { boxSettings } = useContext(AddNewOrderContext);
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);

  const [dropdownListData, setDropdownListData] = useState([]);

  useEffect(() => {
    generateDropdownData();
  }, [boxSettings.language]);

  const generateDropdownData = () => {
    const mapedData = value.box_field_dictionary.map((el) => {
      return {
        value: boxSettings.language !== 'PL' ? choseDefaultNameLanguage(el, boxSettings.language, 'id') : (el.id ?? ''),
        displayName:
          boxSettings.language !== 'PL' ? choseDefaultNameLanguage(el, boxSettings.language, 'name') : el.default_name,
        groupId: el.id
      };
    });

    setDropdownListData(mapedData);
  };

  const handleDropdownElement = (e) => {
    e.stopPropagation();
    const initialValue = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: { content: [e.target.dataset.groupId] }
    };
    updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);

    if (!showCheckbox) setDropdownSetting(false); //when there is no checkbox we allow choose only one value, at once after choosing value we hide dropdown
  };

  const handleMultipleCheckboxes = (copyArr, elementValue) => {
    const handledArr = copyArr ? [...copyArr] : [];
    if (handledArr.includes(elementValue)) {
      const index = handledArr.indexOf(elementValue);
      handledArr.splice(index, 1);
    } else {
      handledArr.unshift(elementValue);
    }

    return handledArr;
  };

  const handleDropdownCheckboxElement = (e) => {
    let copyArr,
      finalArr = [];
    if (getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant).value === null) {
      copyArr = '';
    } else {
      copyArr = getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant).value.content;
    }
    const elementValue = e.target.dataset.groupId;
    if (e.target.tagName === 'INPUT') {
      e.stopPropagation();
    }
    if (copyArr === null) {
      const initialValue = {
        ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
        value: { content: [e.target.dataset.groupId] }
      };
      updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
    } else {
      if (value.field_type === DROPDOWN_TYPE.SINGLE_DROPDOWN) {
        copyArr = [];
        finalArr = [elementValue];
      } else {
        finalArr = [...handleMultipleCheckboxes(copyArr, elementValue)];
      }
      const initialValue = {
        ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
        value: { content: [...finalArr] }
      };
      updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
    }

    if (!showCheckbox) setDropdownSetting(false); //when there is no checkbox we allow choose only one value, at once after choosing value we hide dropdown
  };

  const handleCheckboxCheck = (id) => {
    const content = getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant);
    return checkIfContentIncludeID(id, content);
  };

  return (
    <>
      {dropdownSetting && (
        <Dropdown
          data={dropdownListData}
          handleListElement={
            [DROPDOWN_TYPE.DROPDOWN, DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type)
              ? handleDropdownCheckboxElement
              : handleDropdownElement
          }
          displayCheckbox={[DROPDOWN_TYPE.DROPDOWN, DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type)}
          positionToElementRef={dropdownRef}
          radioInput={[DROPDOWN_TYPE.SINGLE_DROPDOWN].includes(value.field_type)}
          handleCheckBox={handleCheckboxCheck}
          setShowDropdown={setDropdownSetting}
          showCheckbox={showCheckbox}
        />
      )}
    </>
  );
};

export default OrderBoxDropdownContainer;
