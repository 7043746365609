import authentication from '../services/authorization.js';

export async function handleResponse(response, requestOptions) {
  if (!response.ok) {
    if ([401, 403, 500, 502].indexOf(response.status) !== -1) {
      const clonedResponse = response.clone();
      const body = await clonedResponse.json();

      const refreshTokenRes = authentication.getRefleshToken().then((r) => {
        if (r && r.access) {
          return fetch(response.url, {
            ...requestOptions,
            headers: {
              ...requestOptions.headers,
              Authorization: `Bearer ${r.access}`
            }
          });
        } else {
          return false;
        }
      });

      if (refreshTokenRes) {
        return refreshTokenRes;
      } else {
        throw new Error(response.status);
      }
    }
  }
  return response;
}
