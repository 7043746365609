import React, { useState, useContext, useEffect, createContext, useMemo } from 'react';
import OrderBox from './OrderBox';
import ProductView from '../OrderBoxFields/Products/ProductView';
import { AddNewOrderContext } from '../../../pages/e-commerce/AddNewOrder';
import { useSearchParams } from 'react-router-dom';
export const ProductViewContext = createContext(null);
export const BoxesLanguageContext = createContext(null);

const OrderBoxContent = (props) => {
  const [searchParams] = useSearchParams();
  const { boxFieldsData } = useContext(AddNewOrderContext);
  const { boxData } = useContext(AddNewOrderContext);
  const [productBoxID, setProductBoxID] = useState(null);
  const [col_1_elements, set_col_1_elements] = useState([]);
  const [col_2_elements, set_col_2_elements] = useState([]);
  const [col_3_elements, set_col_3_elements] = useState([]);
  const [activeProductView, setActiveProductView] = useState(false);

  const [productViewData, setProductViewData] = useState([]);

  const productViewValue = useMemo(
    () => ({
      productViewData,
      setProductViewData
    }),
    [productViewData, setProductViewData]
  );

  const getAddProductFieldID = () => {
    let resultID = null;
    for (let i = 0; i < boxData.length; i++) {
      const boxFields = boxData[i].box.box_fields;
      for (let j = 0; j < boxFields.length; j++) {
        if (boxFields[j].field_type === 'product') {
          resultID = boxFields[j].id;
          break;
        }
      }

      if (resultID) break;
    }

    return resultID;
  };
  const findProductViewData = () => {
    const addProductFieldID = getAddProductFieldID();
    let resultData;
    for (let i = 0; i < boxFieldsData.length; i++) {
      if (boxFieldsData[i].box_field === addProductFieldID) {
        resultData = boxFieldsData[i];
        break;
      }
    }

    return resultData;
  };
  useEffect(() => {
    const productView = findProductViewData();
    if (productView) {
      let variant_data;
      const productViewVariants = productView.variants;

      if (searchParams.get('order_status') || searchParams.get('edit_order')) {
        productViewVariants.forEach((el) => {
          if (el.variant_id === props.variant) {
            variant_data = el;
          }
        });
      } else {
        variant_data = productViewVariants[props.chosenTabIndex];
      }

      if (variant_data && variant_data.value && variant_data?.value?.length > 0) {
        setProductViewData(variant_data?.value.filter((el) => el.ean));
      }
    }
  }, [boxData, props.variant]);

  useEffect(() => {
    const col_1 = [],
      col_2 = [],
      col_3 = [];

    boxData.forEach((value, index) => {
      if (value.box.box_fields[0]?.field_type === 'product') {
        setActiveProductView(true); // <---- new solution
        setProductBoxID(value.box.id);
      }

      if (value.column === 1) {
        col_1.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            boxData={value}
            validate={props.validate}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column === 2) {
        col_2.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            validate={props.validate}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      } else if (value.column === 3) {
        col_3.push(
          <OrderBox
            tabsArr={props.tabsArr}
            orderID={props.orderID}
            key={`order-box-${index}`}
            variant={props.variant}
            validate={props.validate}
            boxData={value}
            target={props.target}
            orderDeadlineFieldID={props.orderDeadlineFieldID}
          />
        );
      }
    });

    set_col_1_elements(col_1);
    set_col_2_elements(col_2);
    set_col_3_elements(col_3);
  }, [boxData]);

  return (
    <ProductViewContext.Provider value={productViewValue}>
      <div className={props.visible ? 'box-content' : 'box-content inactive'} data-target={props.target}>
        <div className={props.visible ? 'box-wrapper' : 'box-wrapper inactive'} data-target={props.target}>
          <div className="row">
            <div className="column col-1">{col_1_elements}</div>
            <div className="column col-2">{col_2_elements}</div>
            <div className="column col-3">{col_3_elements}</div>
          </div>
          {activeProductView ? (
            <ProductView keyValue={`${props.target}-view`} productBoxID={productBoxID} variant={props.variant} />
          ) : null}
        </div>
      </div>
    </ProductViewContext.Provider>
  );
};

export default OrderBoxContent;
