import React, { useContext, useState } from 'react';
import { ReactComponent as Assignee } from '../../../../../assets/popups/assign.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
import SelectBox from '../../Team/globalelements/SelectBox';
import ENUMS from '../../Team/ENUMS';
import orderServices from '../../../../services/orderServices';
import { MainAppContext } from '../../../../MainAppComponent';

const ChangeAssignee = ({ setAssignePopup, orderID, setRealoadComponent, assigneToDtp, changeStatus }) => {
  const { closeOrderPopup } = usePopup();
  const [selectBoxData, setSelectBoxData] = useState({ role: '', person: '' });
  const [error, setError] = useState(false);
  const { usersList } = useContext(MainAppContext);
  const handleSecondButton = () => {
    setAssignePopup({ showPopup: false, order_id: null });
    closeOrderPopup();
  };
  const handleFirstButton = () => {
    if (!selectBoxData.role || !selectBoxData.person) {
      setError(true);
      return;
    }
    if (setRealoadComponent) setRealoadComponent(true);

    if (assigneToDtp || selectBoxData.role === ENUMS.ROLE.KREACJA || selectBoxData.role === ENUMS.ROLE.DTP) {
      orderServices
        .assignProjectToDtpUser(orderID, selectBoxData.id, changeStatus)
        .then(() => {
          console.log('ok');
        })
        .catch((error) => {
          console.log('ASSIGNE PROJECT TO DTP ERROR - assignePopup');
          console.error(error);
        });
    } else if (selectBoxData.role === ENUMS.ROLE.CLIENT) {
      orderServices
        .changeOrderDetail(orderID, { client_managed_by: selectBoxData.id })
        .then(() => {
          console.log('ok');
        })
        .catch((error) => {
          console.log('ASSIGNE PROJECT TO CURRENT CLIENT USER ERROR - assignePopup');
          console.error(error);
        });
    } else {
      orderServices
        .assignProjectToCurrentAccUser(orderID, selectBoxData.id, changeStatus)
        .then(() => {
          console.log('ok');
        })
        .catch((error) => {
          console.log('ASSIGNE PROJECT TO CURRENT ACC USER ERROR - assignePopup');
          console.error(error);
        });
    }
    setAssignePopup({ showPopup: false, order_id: null });
    closeOrderPopup();
  };
  return (
    <div className="assignee-popup">
      <div className="upper-part-popup">
        <Assignee />
        <h3>
          Zmiana opiekuna <br /> zlecenia.
        </h3>
      </div>
      <div className="middle-part-popup">
        <SelectBox
          selectBoxData={selectBoxData}
          setSelectBoxData={setSelectBoxData}
          persons={usersList}
          error={error}
          setError={setError}
        />
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={handleSecondButton}>
          Anuluj
        </button>
        <button className="button accept" onClick={handleFirstButton}>
          Zmień
        </button>
      </div>
    </div>
  );
};

export default ChangeAssignee;
