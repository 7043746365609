import React, { useState } from 'react';
import { ReactComponent as FormatIcon } from '../../../../../assets/popups/format.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
import AddCounterCalenderDeadline from '../additionalElements/AddCounterCalenderDeadline';
const suffix = '_reformat';
const Format = ({ contentType, handleAction }) => {
  const { closeOrderPopup } = usePopup();
  const [orderName, setOrderName] = React.useState('');
  const [calenderDeadline, setCalenderDeadline] = useState('');
  const [error, setError] = useState(false);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const handleOrderName = (e) => {
    setOrderName(e.target.value);
  };
  const isValidated = () => {
    if (contentType === 'format') return calenderDeadline && orderName;

    return calenderDeadline;
  };
  const handleDuplicate = () => {
    if (isValidated()) {
      if (error) setError(false);
      if (['restore'].includes(contentType)) {
        setActiveButtonLoader(true);
        handleAction(orderName, calenderDeadline, setActiveButtonLoader);
      } else if (['format'].includes(contentType)) {
        handleAction(orderName + suffix, calenderDeadline);
      } else {
        handleAction(orderName, calenderDeadline);
      }
    } else {
      setError(true);
    }
  };
  return (
    <div className="format-popup">
      <div className="upper-part-popup">
        <FormatIcon />
        <h3>
          Formatowanie <br /> zlecenia
        </h3>
      </div>
      <div className="middle-part-popup">
        <input
          className="order-name"
          value={orderName}
          onChange={handleOrderName}
          placeholder={'Nazwa akcji_reformat'}
        />
        <div className="input-group" style={{ display: 'block', width: '100%', maxHeight: 25 }}>
          <AddCounterCalenderDeadline
            setCalenderDeadline={setCalenderDeadline}
            deadline
            placeholder={'Wybierz deadline'}
          />
          {error && <span className="error">*Musisz wskazać nowy deadline</span>}
        </div>
      </div>
      <div className="buttons">
        <button className="button cancel" onClick={() => closeOrderPopup()}>
          Anuluj
        </button>
        <button className="button accept" onClick={handleDuplicate}>
          Formatuj
        </button>
      </div>
    </div>
  );
};

export default Format;
