import * as Sentry from "@sentry/react";

/**
 * initSentry() - initialize sentry for whole app
 */

export const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENV,
      release: process.env.REACT_APP_COMMIT_ID,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

/**
 * initSentryUser() - initialize sentry user info / Event Highlights
 * @param {Obj} user 
 */

export const initSentryUser = (user) => {
  Sentry.setTag("user_permissions", user.permissions.join(','));

  //init user data for sentry
  Sentry.setUser({
    "id": user.pk, //user ID
    "permissions": user.permissions.join(',') //user permissions
  });
};

/**
 * setUpSentryContext() - handles additional info for sentry
 * @param {Obj} context 
 */

const setUpSentryContext = (context) =>{
  if(context){
    // add additional info as a context in sentry
    const {title, payload} = context;

    Sentry.configureScope((scope) => scope.clear());
    Sentry.setContext(title, payload)
  }
}

/**
 * sendSentryInfo() - sends info to sentry ABC-304
 * @param {String} infoText
 * @param {Obj} context - additional info to display in Sentry
 */

export const sendSentryInfo = (infoText, context) => {
  Sentry.captureMessage(infoText, "info"); //send message as a info
  setUpSentryContext(context)
};

export const sendSentryError = (infoText, context) => {
  Sentry.captureMessage(infoText, "error"); //send message as a error
  setUpSentryContext(context)
};


