const permissionSelection = (user) => {
  const userPermissions = user.permissions;
  const permissionsToSearch = ['ecommerce_admin', 'ecommerce_orders_create', 'ecommerce_dtp', 'ecommerce_creation'];
  let foundPermission, result;

  for (let i = 0; i < userPermissions.length; i++) {
    if (permissionsToSearch.includes(userPermissions[i])) {
      foundPermission = userPermissions[i];

      if (foundPermission === 'ecommerce_admin') {
        result = 'ACCM';
      } else if (foundPermission === 'ecommerce_orders_create') {
        result = 'CLIENT';
      } else if (foundPermission === 'ecommerce_dtp' || foundPermission === 'ecommerce_creation') {
        result = 'GRAPHIC_DESIGNER';
      } else {
        result = 'ACCESS_DENIED';
      }
      break;
    }
  }

  return result;
};

export default permissionSelection;
