import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { MainAppContext } from '../../../../MainAppComponent';
import { AddNewOrderContext } from '../../../../pages/e-commerce/AddNewOrder';
import { updateGLobalBoxFieldsData } from '../../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData';
import Slider from '../../Table/Slider';
import ImageModal from '../../Popup/ImageModla';
import MODAL_TYPES from '../../Popup/ENUMS/IMAGE_MODAL_TYPES';
import ProductSlideElement from './ProductSlideElement';
import { ProductViewContext } from '../../OrderBox/OrderBoxContent';
import AddProductPopup from '../../Popup/AddProductPopup';
import usePopup from '../../../../helper/usePopup';
const AddProduct = ({ description, actualVariant, boxFieldID }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { setShadowActive } = useContext(MainAppContext);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState(null);
  const boxRef = useRef();
  const { initPopup } = usePopup();

  const handleButton = (e, index) => {
    setShadowActive(true);
    initPopup(
      <AddProductPopup productViewData={productViewData} setProductViewData={setProductViewData} productIndex={index} />
    );
  };

  useEffect(() => {
    //update global data on every productViewData change
    const chosenValue = [];
    for (let i = 0; i < productViewData.length; i++) {
      if (productViewData[i].ean) {
        const obj = {
          view_id: productViewData[i].id || null,
          external_id: productViewData[i].external_id || null,
          ean: productViewData[i].ean || null,
          plu: productViewData[i].plu || null,
          name: productViewData[i].name || null,
          quantity: productViewData[i].quantity || null,
          weight: productViewData[i].weight || null,
          scale: productViewData[i].scale || 1,
          rotate: productViewData[i].rotate || 0,
          width: productViewData[i].width || 0,
          height: productViewData[i].height || null,
          display_width: productViewData[i].display_width || null,
          display_height: productViewData[i].display_height || null,
          grammage: productViewData[i].grammage || null,
          photos: [
            {
              external_id: productViewData[i].external_id || null,
              url: productViewData[i].photos[0].url || null
            }
          ]
        };
        chosenValue.push(obj);
      }
    }

    if (chosenValue.length > 0) {
      const initialValue = {
        variant_id: actualVariant,
        is_hidden: false,
        value: chosenValue,
        custom_input_value: null,
        image: null
      };

      updateGLobalBoxFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
    }
  }, [productViewData]);

  const handleImageModal = (src, e) => {
    //display product on mobile
    e.stopPropagation();
    setShowImageModal(true);
    setImageModalSrc(src);
  };

  const handleRemoveProduct = (productIndex, e) => {
    //remove product from the list
    e.stopPropagation();
    setProductViewData(productViewData.filter((value, index) => index !== productIndex));
    // setBoxFieldsData(boxFieldsData.filter((value, index) => index !== productIndex));
    setBoxFieldsData((prevBoxFieldsData) => {
      return prevBoxFieldsData.map((boxField) => {
        if (boxField.box_field !== boxFieldID) {
          // If it's not the target box_field, return it unchanged
          return boxField;
        } else {
          // Modify the target box_field
          const updatedVariants = boxField.variants.map((variant) => {
            // Assuming you want to modify the first variant
            if (variant.variant_id === actualVariant) {
              return {
                ...variant,
                value: variant.value.filter((_, index) => index !== productIndex)
              };
            }
            return variant;
          });

          return {
            ...boxField,
            variants: updatedVariants
          };
        }
      });
    });
  };

  const slideArr = useMemo(() => {
    //render slider elements
    const slides = productViewData
      .filter((el) => el?.ean) //filter is solution for older orders with old functionality
      .map((value, index) => (
        <ProductSlideElement
          key={`product-slide-${index}`}
          data={value}
          productIndex={index}
          handleButton={handleButton}
          handleImageModal={handleImageModal}
          handleRemoveProduct={handleRemoveProduct}
        />
      ));

    // Add "add-new-product" button at the end
    slides.push(
      <button className="add-new-product" onClick={(e) => handleButton(e, slides.length)} key="add-new-product">
        +
      </button>
    );

    return slides;
  }, [productViewData]);

  return (
    <>
      <div className="content add-product-box" ref={boxRef}>
        {description && <span>{description || null}</span>}
        <div className="add-product">
          {slideArr.length ? (
            <Slider
              spaceBetween={0}
              slidesPerView={8}
              slides={slideArr}
              hasCustomNavigation={false}
              breakpoints={{
                2400: { slidesPerView: 10 },
                1600: { slidesPerView: 7 },
                772: { slidesPerView: 7 },
                320: { slidesPerView: 1 }
              }}
            />
          ) : null}
        </div>
      </div>
      {showImageModal && (
        <ImageModal
          setShowImageModal={setShowImageModal}
          imageSource={imageModalSrc}
          modalType={MODAL_TYPES.SINGLE_IMAGE}
        />
      )}
    </>
  );
};

export default AddProduct;
