import React, { useContext } from 'react';
import { OrderderIdToRemove } from '../../../../../Router';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import usePopup from '../../../../../helper/usePopup';
import Abort from '../../../Popup/AddOrder/Abort';
import CancelProceeding from '../../../Popup/AddOrder/CancelProceeding';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const RemoveButton = ({ orderID, rowData }) => {
  const { removeOrderID, setRemoveOrderID } = useContext(OrderderIdToRemove);
  const { user } = useContext(UserContext);
  const { initPopup } = usePopup();
  const handleRemoveBtn = () => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('RemoveButton action', sentryContext);

    if (
      rowData.order_status === 'restored' ||
      rowData.order_status === 'correction' ||
      rowData.order_status === 'in_progress' ||
      rowData.order_status === 'renew' ||
      rowData.order_status === 'ready_for_qa' ||
      rowData.order_status === 'accepted_acc'
    ) {
      initPopup(
        <CancelProceeding
          orderID={orderID}
          removeOrderID={removeOrderID}
          setRemoveOrderID={setRemoveOrderID}
          status={rowData.is_before_any_work ? 'deleted' : 'cancelled'}
        />
      );
    } else {
      initPopup(
        <Abort
          orderID={orderID}
          removeOrderID={removeOrderID}
          setRemoveOrderID={setRemoveOrderID}
          status={rowData.is_before_any_work ? 'deleted' : 'cancelled'}
        />
      );
    }
  };

  return (
    <>
      {shouldDisplayButton(
        rowData.order_status === 'restored' ||
          rowData.order_status === 'correction' ||
          rowData.order_status === 'in_progress' ||
          rowData.order_status === 'renew' ||
          rowData.order_status === 'ready_for_qa' ||
          rowData.order_status === 'accepted_acc'
          ? 'cancel'
          : 'delete',
        rowData?.order_status,
        user
      ) !== 'Brak' && (
        <button
          className={`remove ${
            shouldDisplayButton(
              rowData.order_status === 'restored' ||
                rowData.order_status === 'correction' ||
                rowData.order_status === 'in_progress' ||
                rowData.order_status === 'renew' ||
                rowData.order_status === 'ready_for_qa' ||
                rowData.order_status === 'accepted_acc'
                ? 'cancel'
                : 'delete',
              rowData?.order_status,
              user
            ) === 'Szary'
              ? 'casual'
              : null
          } `}
          onClick={handleRemoveBtn}
        >
          {rowData.order_status === 'restored' ||
          rowData.order_status === 'correction' ||
          rowData.order_status === 'in_progress' ||
          rowData.order_status === 'renew' ||
          rowData.order_status === 'ready_for_qa' ||
          rowData.order_status === 'accepted_acc'
            ? 'Przerwij'
            : 'Usuń'}
        </button>
      )}
    </>
  );
};

export default RemoveButton;
