/**
 * setUpCustomSizeList() - searches for custom size list in boxFields and return parsed array //ABC-255
 * we need this to find custom size list and paste paste it into separated state that holds all custom sizes in order
 */

export const setUpCustomSizeList = (boxFields, draftData) => {
    //search for size box field ID
  let sizeBoxField = null;
  let result = "";

  for (let i = 0; i < boxFields.length; i++) {
    if (boxFields[i].field_type == "size") {
      sizeBoxField = boxFields[i].id;
      break;
    }
  }

  //get custom_inputs_value
  for (let i = 0; i < draftData.length; i++) {
    if (draftData[i].box_field == sizeBoxField) {
      result = draftData[i].order[0].custom_inputs_value;
      break;
    }
  }
  // return JSON.parse(result); //return array example: ["1", "2", 3]
  return result.length > 0 ? JSON.parse(result.replace(/'/g, '"')) : []; //return array example: ["1", "2", 3]
};

/**
 * handleCustomSizeList() - take customSizeList and paste to Size box field into custom_inputs_value //ABC-255
 * we need this to paste customSizList that comes from separated state that holds all custom sizes in order
 */

export const handleCustomSizeList = (boxFieldsData,sizeFieldID,customSizeList) => {
  let boxFieldsDataCopy = [...boxFieldsData];
  let sizeContentIndex = boxFieldsData.findIndex(
    (el) => el.box_field === sizeFieldID
  );

  boxFieldsDataCopy[sizeContentIndex].order[0].custom_inputs_value =
    customSizeList || null;

  return boxFieldsDataCopy;
};
