import React, { useState, useEffect } from 'react';
import AssignedProjectBox from '../globalelements/AssignedProjectBox';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import SearchBar from '../../../globalElements/SearchBar';
import orderServices from '../../../../services/orderServices';
import { ENUMS } from '../helper/segregateOrdersColumns';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../helper/usePopup';
import Assignee from '../../Popup/AddOrder/Assignee';
import { ReactComponent as OrderIcon } from '../../../../../assets/team/order.svg';

const NewOrderBox = ({
  data,
  columns,
  setColumns,
  setRealoadComponent,
  newOrderBoxLoading,
  setNewOrderBoxLoading,
  permission,
  setOrderId,
  startProjectRef,
  projectAdditionalActionsRef,
  orderId
}) => {
  const [assignedPopup, setAssignePopup] = useState({
    showPopup: false,
    order_id: null
  });
  const [searchValue, setSearchValue] = useState('');

  const handleSerchBar = () => {
    setNewOrderBoxLoading(true);
    orderServices.serachOrderBy('new', searchValue).then((res) => {
      setColumns({
        ...columns,
        [ENUMS.COLUMNS.newOrderBox]: {
          ...[ENUMS.COLUMNS.newOrderBox],
          items: res.results
        }
      });
      setNewOrderBoxLoading(false);
    });
  };

  useEffect(() => {
    if (searchValue) {
      const timer = setTimeout(() => {
        handleSerchBar();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchValue]);
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(
        <Assignee
          setRealoadComponent={setRealoadComponent}
          setAssignePopup={setAssignePopup}
          orderID={assignedPopup.order_id}
          changeStatus={true}
        />
      );
    }
  }, [assignedPopup]);
  return (
    <div className={'new-order-box-container box'}>
      <div className="title-row">
        <OrderIcon />
        <h1>Nowe zlecenia ({data.length})</h1>
      </div>
      <div className="filter-row">
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} width={`246px`} />
      </div>
      <Droppable isDropDisabled={true} droppableId={'newOrderBox'} key={'1'}>
        {(provided) => {
          return (
            <div className="boxes-container" {...provided.droppableProps} ref={provided.innerRef}>
              {data?.length > 0 &&
                data.map((element, index) => {
                  return (
                    <Draggable
                      key={`neworder-${element.id}`}
                      draggableId={`neworder-${element.id}`}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style
                            }}
                          >
                            <AssignedProjectBox
                              setRealoadComponent={setRealoadComponent}
                              setAssignePopup={setAssignePopup}
                              data={element}
                              id={element.id}
                              key={element.id}
                              new_order
                              permission={permission}
                              setOrderId={setOrderId}
                              startProjectRef={startProjectRef}
                              projectAdditionalActionsRef={projectAdditionalActionsRef}
                              orderId={orderId}
                              hasMarginRight={false}
                              userType={'CLIENT'}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      {newOrderBoxLoading && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
    </div>
  );
};

export default NewOrderBox;
