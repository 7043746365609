/**
 * isNumeric() - check if value is numeric type
 *
 * @param {*} value
 * @returns boolean (true or false)
 */

export const isNumeric = (value) => {
  return /^-?\d*\.?\d*$/.test(value);
};
