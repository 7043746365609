import React from 'react';
import usePopup from '../../helper/usePopup';
import ErrorLoadingData from '../../elements/e-commerce/Popup/AddOrder/ErrorLoadingData';

const ErrorPopupTestPage = () => {
  const { initPopup } = usePopup();

  return (
    <div className="test-page">
      <button
        onClick={() => initPopup(<ErrorLoadingData />)}
        style={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: '300px',
          height: '150px'
        }}
      >
        ACTIVE POPUP
      </button>
    </div>
  );
};

export default ErrorPopupTestPage;
