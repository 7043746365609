import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';

const getOrderVariant = async (order_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/${order_id}/variant/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default getOrderVariant;
