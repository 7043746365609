import React, { useContext } from 'react';
import checkPermissions, { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import PERMISSIONS_ENUMS from '../../../../../../globalEnums/PERMISSIONS_ENUMS';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import orderServices from '../../../../../services/orderServices';
import { ReloadTableContext } from '../../../../../Router';

import usePopup from '../../../../../helper/usePopup';
import Restore from '../../../Popup/AddOrder/Restore';
import Restored from '../../../Popup/AddOrder/Restored';
import SomethingWentWrong from '../../../Popup/AddOrder/SomethingWentWrong';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const RestoreProjectButton = ({ orderID, rowData }) => {
  const { user } = useContext(UserContext);
  const { setReloadComponent } = useContext(ReloadTableContext);
  const { initPopup } = usePopup();

  const handleRestoreButton = (orderName, calenderDeadline, setActiveButtonLoader) => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('RestoreProjectButton action', sentryContext);

    setActiveButtonLoader(true);
    if (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN])) {
      orderServices
        .restoreProjectAcc(orderID, calenderDeadline)
        .then((res) => {
          if (res.ok) {
            setActiveButtonLoader(true);
            initPopup(<Restored type="positive" info="restore" reload />);
          }
        })
        .catch((error) => {
          if (error) {
            setActiveButtonLoader(false);
            initPopup(<SomethingWentWrong type="negative" info="something_wrong" />);
          }
        });
    } else if (checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE])) {
      orderServices
        .restoreProjectClient(orderID, calenderDeadline)
        .then((res) => {
          if (res.ok) {
            setReloadComponent(true);
            initPopup(<Restored type="positive" info="restore" reload />);
          }
        })
        .catch((error) => {
          if (error) {
            setActiveButtonLoader(false);
            initPopup(<SomethingWentWrong type="negative" info="something_wrong" />);
          }
        });
    }
  };

  return (
    <>
      {shouldDisplayButton('restore', rowData?.order_status, user) !== 'Brak' && (
        <>
          <button
            className={`restore ${shouldDisplayButton('restore', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={() => initPopup(<Restore contentType={'restore'} handleAction={handleRestoreButton} />)}
          >
            Przywróć projekt
          </button>
        </>
      )}
    </>
  );
};

export default RestoreProjectButton;
