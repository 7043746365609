import React, { useEffect, useState, useContext } from 'react';
import RoleBox from './elements/RoleBox';
import SelectBox from '../globalelements/SelectBox';
import Row from '../globalelements/Row';
import AssignedProjectBox from '../globalelements/AssignedProjectBox';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import SearchBar from '../../../globalElements/SearchBar';
import teamBoxGetData from '../../../../helper/teamBoxGetData';
import { MainAppContext } from '../../../../MainAppComponent';
import FilterBar from '../../../globalElements/Filters/FilterBar';
import FILTER_BAR_TYPES from '../../../globalElements/Filters/ENUMS/FILTER_BAR_TYPE';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../helper/usePopup';
import Assignee from '../../Popup/AddOrder/Assignee';
import { ReactComponent as OrderIcon } from '../../../../../assets/team/order.svg';

const TeamBoxAcc = ({
  data,
  isDroppableToTeam,
  setIsDroppableToTeam,
  setSelectBoxData,
  selectBoxData,
  permission,
  setRealoadComponent,
  setColumns,
  columns,
  teamBoxLoading,
  setTeamOrderBoxLoading,
  setOrderId,
  startProjectRef,
  projectAdditionalActionsRef,
  orderId
}) => {
  const [assignedPopup, setAssignePopup] = useState({
    showPopup: false,
    order_id: null
  });

  const [searchBarValue, setSearchBarValue] = useState('');
  const [externalEndoint, setExternalEndpoint] = useState('');

  const { usersList } = useContext(MainAppContext);

  useEffect(() => {
    setIsDroppableToTeam(false);
  }, [data]);

  useEffect(() => {
    if (usersList.length) setTeamOrderBoxLoading(false);
  }, [usersList]);

  const handleSerchBar = () => {
    teamBoxGetData(selectBoxData, setTeamOrderBoxLoading, columns, setColumns, searchBarValue, externalEndoint);
  };

  useEffect(() => {
    if (searchBarValue) {
      const timer = setTimeout(() => {
        handleSerchBar();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [searchBarValue]);

  useEffect(() => {
    if (externalEndoint) handleSerchBar();
  }, [externalEndoint]);
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(
        <Assignee
          assigneToDtp={true}
          setRealoadComponent={setRealoadComponent}
          setAssignePopup={setAssignePopup}
          orderID={assignedPopup.order_id}
          changeStatus={true}
        />
      );
    }
  }, [assignedPopup]);
  return (
    <div className={`team-box-container box box-${permission}`}>
      <div className="title-row">
        <OrderIcon />
        <h1>Team ({data.length})</h1>
      </div>
      <Row paddingLeft={0} paddingTop={0} paddintBottom={0} paddingRight={0}>
        <SelectBox
          permission={permission}
          selectBoxData={selectBoxData}
          setSelectBoxData={setSelectBoxData}
          persons={usersList}
        />
        <RoleBox />
      </Row>
      {/* <Row rowBorder paddintBottom={"16px"} paddingLeft={"6px"} paddingTop={"16px"}>
        <h1 className={"box-title"}>przypisane projekty do osoby z listy powyżej</h1>
      </Row> */}
      <div className="filter-row">
        <FilterBar
          showPerAccount={false}
          showStatus={true}
          showDeadline={true}
          filterType={FILTER_BAR_TYPES.TEAM_BOX_ACC}
          setDataLoader={setTeamOrderBoxLoading}
          userID={selectBoxData.id}
          setExternalEndpoint={setExternalEndpoint}
        />
        <SearchBar setSearchValue={setSearchBarValue} searchValue={searchBarValue} width={`150px`} />
      </div>
      <Droppable
        droppableId={'assignedToPerson'}
        key={'3'}
        isDropDisabled={!selectBoxData.role || !selectBoxData.person || !isDroppableToTeam}
      >
        {(provided) => {
          return (
            <div
              className="boxes-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={data?.length > 0 ? { paddingBottom: '300px' } : { height: '100%' }}
            >
              {data?.length > 0 &&
                data.map((element, index) => {
                  return (
                    <Draggable key={`accbox-${element.id}`} draggableId={`accbox-${element.id}`} index={index}>
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style
                            }}
                          >
                            <AssignedProjectBox
                              setRealoadComponent={setRealoadComponent}
                              setAssignePopup={setAssignePopup}
                              data={element}
                              id={element.id}
                              key={element.id}
                              my_order
                              permission={permission}
                              setOrderId={setOrderId}
                              startProjectRef={startProjectRef}
                              projectAdditionalActionsRef={projectAdditionalActionsRef}
                              orderId={orderId}
                              hasMarginRight={index % 2 === 0}
                              userType={selectBoxData.role}
                              showAccAction={true}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      {teamBoxLoading && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
    </div>
  );
};

export default TeamBoxAcc;
