const checkIfHanfleBoxFieldVariant = (variant, boxFieldID, hiddenBoxFieldsGroup, boxSettings) => {
  let result = true;
  const hiddenBoxList = boxSettings.hidden_boxes;

  if (hiddenBoxFieldsGroup.length) {
    hiddenBoxFieldsGroup.forEach((group) => {
      if (group.box_fields.includes(boxFieldID)) {
        for (let i = 0; i < hiddenBoxList.length; i++) {
          if (hiddenBoxList[i].box_id == group.box_id && hiddenBoxList[i].variants.includes(variant)) {
            result = false;
            break;
          }
        }
      }
    });
  }

  return result;
};

export default checkIfHanfleBoxFieldVariant;
