import authHeader from '../helper/auth-header';
import { handleResponse } from '../helper/handle-response';
import getApiKey from '../helper/getApiKey';

const getNotifications = async (currentPage, postPerPage, externalEndpoint, searchByValue, orderBy) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key,
      'Accept-Language': 'pl'
    }
  };

  let enpoint = `order-notifications/?page_size=${postPerPage}&page=${currentPage}`;
  if (externalEndpoint) enpoint += externalEndpoint;
  if (searchByValue) enpoint += `&search=${searchByValue}`;
  if (orderBy) enpoint += `&ordering=${orderBy.join(',')}`;

  return fetch(`${process.env.REACT_APP_API_URL}/${enpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const markNofificationAsRead = async (order_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/order-notifications/order/${order_id}/mark-as-read/`,
    requestOptions
  ).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getNotificationHistory = async (order_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/order-notifications/order/${order_id}/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getNotificationCount = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/order-notifications/count/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const markAllAsRead = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/order-notifications/mark-as-read-all/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getNotifications,
  markNofificationAsRead,
  getNotificationHistory,
  getNotificationCount,
  markAllAsRead
};
