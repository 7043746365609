import React from 'react';
import './styles.scss';
import Router from './components/Router';
import AuthWrap from './components/wrapper/AuthWrap';

function App() {
  return (
    <div id="mod_ecommerce" className="App">
      <AuthWrap>
        <Router />
      </AuthWrap>
    </div>
  );
}

export default App;
