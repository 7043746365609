import React, { useRef, useState } from 'react';
import usePopup from '../../../helper/usePopup';
import { ReactComponent as Icon } from '../../../../assets/bg-src-icon.svg';
import ReactQuill from 'react-quill-new';

const BackgroundSrcInputPopup = ({ saveData }) => {
  const [inputValue, setInputValue] = useState('');
  const [desc, setDesc] = useState('');
  const { closePopup } = usePopup();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveData = () => {
    if (inputValue) {
      saveData(inputValue, desc);
      closePopup();
    }
  };
  const quillRef = useRef(null);
  return (
    <div className="background-src-popup">
      <div className="title-section">
        <div className="icon">
          <Icon />
        </div>
        <h3>
          Dodaj link z grafiką
          <br />
          do zlecenia
        </h3>
      </div>
      <div className="index">
        <input
          className="input-field"
          type="text"
          value={inputValue}
          onChange={handleInput}
          placeholder="Wpisz, wklej link"
        />
        <ReactQuill
          ref={quillRef}
          className="additional-text"
          type="text"
          value={desc}
          onChange={(html) => setDesc(html)}
          theme={'bubble'}
          placeholder="Dodatkowe informacje"
          modules={{
            toolbar: [['bold'], ['image'], ['clean'], [{ color: ['#eb008c', '#00000'] }]]
          }}
        />
      </div>
      <div className="buttons bottom">
        <button className="cancel" onClick={() => closePopup()}>
          Anuluj
        </button>
        <button className="save" onClick={handleSaveData}>
          Dodaj
        </button>
      </div>
    </div>
  );
};

export default BackgroundSrcInputPopup;
