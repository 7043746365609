import React from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal
} from '@floating-ui/react';
import { useState } from 'react';
import loop from '../../../../../assets/loop.svg';
import noPhotoPlaceholder from '../../../../../assets/no_photo_2.svg';

/**
 *
 * Product slide element with custom product view tooltip
 *
 */

const ProductSlideElement = ({ data, productIndex, handleButton, handleImageModal, handleRemoveProduct }) => {
  const [isOpen, setIsOpen] = useState(false); //tooltip

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start'
      }),
      shift()
    ]
  });

  const hover = useHover(context, {
    move: true,
    delay: { open: 300, close: 200 },
    enabled: true
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    outsidePress: true,
    outsidePressEvent: 'mousedown',
    enabled: true,
    escapeKey: true,
    ancestorScroll: true,
    referencePress: true
  });
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <>
      <div
        className="product"
        data-id-data={data.id}
        onClick={(e) => handleButton(e, productIndex)}
        key={`add-product-${productIndex}`}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {productIndex + 1}
      </div>
      <FloatingPortal>
        <div
          id="tooltip"
          className={`product-tooltip ${isOpen ? 'tooltipVisible' : ''}`}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="image-view" onClick={(e) => handleImageModal(data?.img_src || noPhotoPlaceholder, e)}>
            <div className="hover-shadow">
              <div className="shadow"></div>
              <div className="loop">
                <img src={loop} alt="loop" />
              </div>
            </div>
            <img className="view" src={data?.photos[0].url || noPhotoPlaceholder} alt="product view" />
          </div>
          <span
            onClick={(e) => {
              handleRemoveProduct(productIndex, e);
              setIsOpen(false);
            }}
          >
            Usuń
          </span>
        </div>
      </FloatingPortal>
    </>
  );
};

export default ProductSlideElement;
