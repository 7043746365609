import React from 'react';

const ListElements = ({ elements, handleCheckboxCheck, inputs, parent, handleChildOption }) => {
  return (
    <>
      {elements.map((el, i) => {
        return (
          <li key={`option-2-${parent.id}-${i}`} style={{ order: el?.position || 'unset' }}>
            <label className="real-checkbox">
              <input
                checked={handleCheckboxCheck(el.id)}
                type="checkbox"
                data-name={`sizeO ption`}
                data-textvalue={el.id}
                value={el.default_name}
                data-parent={parent.id}
                onChange={handleChildOption}
                ref={(el) => inputs.current.push(el)}
                data-checkbox-group={el.id}
              ></input>
              {el.default_name}
            </label>
          </li>
        );
      })}
    </>
  );
};

export default ListElements;
