import React, { useState, useRef, useContext } from 'react';
import orderServices from '../../../../../services/orderServices';
import PERMISSIONS_ENUMS from '../../../../../../globalEnums/PERMISSIONS_ENUMS';
import checkPermissions, { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import { ReloadTableContext } from '../../../../../Router';
import Loader from '../../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../../helper/usePopup';
import OrderAccepted from '../../../Popup/AddOrder/OrderAccepted';
import AcceptProjectsModal from '../../InProgressPopups/AcceptProjectsModal';
import NewInfoPopup from '../../../Popup/AddOrder/NewInfoPopup';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const AcceptButton = ({ orderID, rowData, variantsData }) => {
  const buttonRef = useRef();
  const { user } = useContext(UserContext);
  const { setReloadComponent } = useContext(ReloadTableContext);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const { initPopup } = usePopup();

  const handleOrderStatus = (status) => {
    orderServices
      .changeOrderStatus(orderID, status)
      .then(() => {
        initPopup(<OrderAccepted setReloadComponent={setReloadComponent} />);
        setActiveButtonLoader(false);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<NewInfoPopup type={'negative'} info={'accept_button'} />);
        setActiveButtonLoader(false);
      });
  };

  const handleAcceptButton = () => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('AcceptButton action', sentryContext);

    const accPerm = checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) ? true : false;
    if (accPerm && orderID) {
      setActiveButtonLoader(true);
      handleOrderStatus('accepted_acc', false);
    } else if (!rowData?.has_first_acceptation && !rowData.is_reformat) {
      initPopup(<AcceptProjectsModal orderID={orderID} variantsData={variantsData} />);
    } else {
      if (orderID) {
        setActiveButtonLoader(true);
        handleOrderStatus('finished', true);
      }
    }
  };

  return (
    <>
      {shouldDisplayButton('accept', rowData?.order_status, user) !== 'Brak' && (
        <>
          <button
            className={`accept ${shouldDisplayButton('accept', rowData?.order_status, user) === 'Szary' ? 'casual' : null}`}
            onClick={handleAcceptButton}
            ref={buttonRef}
          >
            {activeButtonLoader ? (
              <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} />
            ) : (
              'Zaakceptuj'
            )}
          </button>
        </>
      )}
    </>
  );
};

export default AcceptButton;
