import { handleHostForImageURL } from '../../../../../functions/handleHostForImageURL';

const generateProductViewData = (variantsData) => {
  const productFieldValues = findProductBoxField(variantsData);
  const generatedProductViewData = [];

  if (productFieldValues && Array.isArray(productFieldValues) && productFieldValues.length > 0) {
    productFieldValues.forEach((value) => {
      generatedProductViewData.push({
        variant_id: value.variant_id,
        products: generateProductsValuse(value.value)
      });
    });
  }

  return generatedProductViewData;
};

const findProductBoxField = (variantsData) => {
  for (let i = 0; i < variantsData.length; i++) {
    if (variantsData[i]?.variants) {
      if (variantsData[i]?.variants[0]?.value && variantsData[i]?.variants[0]?.value[0]?.ean)
        return variantsData[i]?.variants;
    }
  }

  return false;
};

const generateProductsValuse = (productsData) => {
  const productDataList = [];

  if (productsData && Array.isArray(productsData) && productsData.length > 0) {
    productsData.forEach((value) => {
      productDataList.push({
        id: value.id || value.view_id,
        img_src: handleHostForImageURL(value.photos[0].url),
        rotate: value.rotate,
        display_width: value.display_width,
        display_height: value.display_height
      });
    });
  }

  return productDataList;
};

export default generateProductViewData;
