import React, { useContext, useState, useEffect } from 'react';
import { MainAppContext } from '../../../../MainAppComponent';

import { AddNewOrderContext } from '../../../../pages/e-commerce/AddNewOrder';
import handleDisplayProductView from '../helper/handleDisplayProductView';
import { ReactComponent as ProductViewIcon } from '../../../../../assets/product-view.svg';
import { ProductViewContext } from '../../OrderBox/OrderBoxContent';
import Product from './Product';
import AddProductPopup from '../../Popup/AddProductPopup';
import usePopup from '../../../../helper/usePopup';

const ProductView = ({ productBoxID, variant }) => {
  const { productViewData, setProductViewData } = useContext(ProductViewContext);
  const { setShadowActive } = useContext(MainAppContext);
  const { boxSettings } = useContext(AddNewOrderContext);
  const [hideProductView, setHideProductView] = useState(false);
  const { initPopup } = usePopup();

  const addNewProduct = (index) => {
    setShadowActive(true);
    initPopup(
      <AddProductPopup productViewData={productViewData} setProductViewData={setProductViewData} productIndex={index} />
    );
  };
  useEffect(() => {
    const boxes = boxSettings.hidden_boxes;
    setHideProductView(handleDisplayProductView(boxes, productBoxID, variant));
  }, [boxSettings]);

  return (
    <div className={hideProductView ? 'view inactive' : 'view'}>
      <div className="wrapper">
        <div className="title">
          <ProductViewIcon />
          <h2>Podgląd produktu</h2>
        </div>
        <div className="products">
          <div className="products-wrapper">
            {productViewData
              ? productViewData.map((el, index) => {
                  return (
                    <>
                      <Product
                        el={el}
                        index={index}
                        productViewData={productViewData}
                        setProductViewData={setProductViewData}
                        actualVariant={variant}
                      />
                    </>
                  );
                })
              : null}
            <button
              className="add-new-product"
              onClick={() => addNewProduct(productViewData.length)}
              key="add-new-product"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
