import React, { useState, useEffect, useRef, useContext } from 'react';
import PageComponent from '../globalelements/PageComponent';
import Table from './elements/Table';
import AddFileWrapper from '../../AddFile/elements/AddFileWrapper';
import variantsServices from '../../../../services/variantsServices';
import orderServices from '../../../../services/orderServices';
import FileSender from '../helper/fileSender';
import imageServices from '../../../../services/imageServices';
import ImageList from './elements/ImageList';
import getBoxFields from '../../../../services/getBoxFields';
import { prepareDataKeyVisual } from '../helper/prepareDataKeyVisual';
import ExternalLink from '../../ExternalLink';
import EXTERNAL_LINKS from '../../ENUMS/EXTERNAL_LINKS';
import imageFilterByStatus from '../../../../../functions/imageFilterByStatus';
import PAGE_COMPONENT_ENUMS from '../ENUMS/PAGE_COMPONENT_ENUMS';
import TeamProductView from './elements/TeamProductView';
import generateProductViewData from '../helper/generateProductViewData';
import AGENTION_NOTES_ENUMS from '../../Table/collapseElements/ENUMS/AGENTION_NOTES_ENUMS';
import AgentionNotes from '../../Table/collapseElements/AgentionNote';
import { TeamContext } from '../TeamBoxAcc/TeamContext';
import CorrectionList from '../../Table/collapseElements/CorrectionList';
import PERMISSION_ENUM from '../ENUMS/TEAN_PERMISSIONS';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../helper/usePopup';
import FileSendSuccess from '../../Popup/FileSendSuccess';
import { sendSentryError, sendSentryInfo } from '../../../../helper/sentryHelper';
import SomethingWentWrong from '../../Popup/AddOrder/SomethingWentWrong';
import ErrorLoadingData from '../../Popup/AddOrder/ErrorLoadingData';
import { ReactComponent as ViewIcon } from '../../../../../assets/team/view.svg';
import DTPFile from '../../Popup/AddOrder/DTPFile';

const KeyVisual = ({
  orderId,
  setOrderId,
  keyVisualDataLoader,
  setKeyVisualDataLoader,
  setRealoadComponent,
  permission
}) => {
  const { currentWorkProject, setCurrentWorkProject } = useContext(TeamContext);
  const [page, setPage] = useState(0); //it's order list index
  const [variantsList, setVariantsList] = useState([]);
  const [variantID, setVariantID] = useState(null);
  const [sizeList, setSizeList] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [variantData, setVariantData] = useState([[]]);
  const [reloadComponentKV, setRealoadComponentKV] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeReloadAnimation, setActiveReloadAnimation] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [uploadFilesList, setUploadFilesList] = useState([]);
  const [validatedExternalLink, setValidatedExternalLink] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [variantBoxFieldValue, setVariantBoxFieldValue] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const { initPopup } = usePopup();
  const Dropdown = useRef();
  const dropdownInputRef = useRef();

  const externalLinksComponentTypes = [
    {
      link_type: EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION,
      validate: true,
      saveButton: true
    },
    {
      link_type: EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL,
      validate: false,
      saveButton: false
    }
  ];

  const agentionNotesTypes = [
    {
      note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.MANAGE_BY_NOTE,
      button: permission === PERMISSION_ENUM.ACCM ? true : false
    },
    {
      note_type: AGENTION_NOTES_ENUMS.NOTE_TYPE.ASSIGNED_TO_NOTE,
      button: permission === PERMISSION_ENUM.ACCM ? false : true
    }
  ];

  const initExtarnalLinkValidationStructure = (variantsList) => {
    const variantIdList = variantsList.map((variant) => variant.id);

    const externalLinksValidation = variantIdList.map((variant) => {
      const fields_to_validate = [];

      externalLinksComponentTypes.forEach((link) => {
        if (link.validate)
          fields_to_validate.push({
            link_type: link.link_type,
            is_validated: false
          });
      });

      return { variant_id: variant, fields: fields_to_validate };
    });

    setValidatedExternalLink(externalLinksValidation);
  };
  const initData = () => {
    if (orderId) {
      setKeyVisualDataLoader(true);
      variantsServices
        .getVariants(orderId)
        .then((r) => {
          const sentryContext = {
            title: 'Variants Details',
            payload: {
              keyVisualData: JSON.stringify(r),
              orderId: orderId
            }
          };

          sendSentryInfo(`Agention note key visual variant data for order: ${orderId}`, sentryContext);

          setVariantsList(r);
          initExtarnalLinkValidationStructure(r);
          if (r && r[0]?.id) {
            setVariantID(r[0].id);
            getImageList(r[0].id);
          } else {
            getImageList(null);
          }
        })
        .catch((error) => {
          console.error(error);
          initPopup(<ErrorLoadingData />);
          setKeyVisualDataLoader(false);
        });
      orderServices
        .getOrderSizes(orderId)
        .then((r) => {
          const sizeArr = [];
          for (let i = 0; i < r.length; i++) {
            const value = r[i].value.children;

            value.forEach((el) => {
              sizeArr.push(el);
            });
          }
          setSizeList(sizeArr);
        })
        .catch((error) => {
          console.log('Orderd sizes error (KeyVisual)');
          console.error(error);
          initPopup(<ErrorLoadingData />);
          setKeyVisualDataLoader(false);
        });

      getBoxFields
        .getVariantBoxFieldValue(orderId)
        .then((r) => {
          setVariantBoxFieldValue(r);
          setProductsData(generateProductViewData(r));
          setVariantData(prepareDataKeyVisual(r, variantsList.length));
          setKeyVisualDataLoader(false);
        })
        .catch((error) => {
          console.log('Orderd box fields values error (KeyVisual)');
          console.error(error);
          initPopup(<ErrorLoadingData />);
          setKeyVisualDataLoader(false);
        });

      orderServices
        .getOrderDetails(orderId)
        .then((res) => {
          setOrderDetails(res);
        })
        .catch((error) => {
          console.log('Orderd detail error (KeyVisual)');
          console.error(error);
          setKeyVisualDataLoader(false);
        });
    }
  };

  useEffect(() => {
    getImageList(variantID);
  }, [variantID]);

  const getImageList = (variant_id) => {
    const timeOut = setTimeout(() => {
      if (orderId) {
        imageServices
          .getImages(orderId, variant_id)
          .then((r) => {
            setImagesData(imageFilterByStatus(r));

            if (activeReloadAnimation) setActiveReloadAnimation(false);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'get_image'} />);
          });

        clearTimeout(timeOut);
      }
    }, 500);
  };

  useEffect(() => {
    if (reloadComponentKV) {
      getImageList(variantID);
      setRealoadComponentKV(false);
    }
  }, [reloadComponentKV]);

  useEffect(() => {
    if (orderId) {
      initData();
      setPage(0);
      if (orderId !== currentWorkProject[0]?.id) setUploadFilesList([]);
    }
  }, [orderId]);

  const sendFiles = async (variantID, index, filesList) => {
    if (index < filesList.length) {
      try {
        const r = await imageServices.createImageSlot(orderId, variantID);
        const slot_pk = r.pk;
        const senderResponse = await FileSender({ selectedFile: filesList[index] }, slot_pk, setImagesData);

        if ([500, 502].includes(senderResponse.status)) {
          throw new Error(`HTTP error! Status: ${senderResponse.status}`);
        } else {
          sendFiles(variantID, index + 1, filesList);
        }
      } catch (error) {
        console.error(error);
        initPopup(<SomethingWentWrong type={'negative'} info={'sending_image'} />);
      }
    } else {
      setLoading(false);
    }
  };

  const checkIfExternalLinkIsValidated = () => {
    for (let i = 0; i < validatedExternalLink.length; i++) {
      if (validatedExternalLink[i].variant_id === variantID) {
        const fields = validatedExternalLink[i].fields;
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].is_validated === false) return false;
        }
      }
    }

    return true;
  };

  const handleUploadFile = (e) => {
    const filesList = Array.from(e.target.files);
    const variantID = variantsList[page] ? variantsList[page].id : null;

    if (checkIfExternalLinkIsValidated() && filesList.length > 0) {
      setLoading(true);
      sendFiles(variantID, 0, filesList);
    } else {
      if (filesList.length === 0) {
        initPopup(<DTPFile file />);
      } else {
        initPopup(<DTPFile />);
      }
    }
  };
  const finishWork = () => {
    setSendLoader(true);

    const sentryContext = {
      title: 'Details',
      payload: {
        orderId: orderId
      }
    };

    orderServices
      .changeOrderStatus(orderId, 'ready_for_qa')
      .then(() => {
        initPopup(<FileSendSuccess setReloadComponent={setRealoadComponent} />);
        setSendLoader(false);
        setOrderId(null);
        setCurrentWorkProject([]);
        sendSentryInfo('Team Graphic Finish Work', sentryContext);
      })
      .catch((error) => {
        console.log('FINISH GRAPHIC WORK ERROR - KeyVisual.js line: 228');
        console.error(error);
        initPopup(<SomethingWentWrong />);
        setSendLoader(false);
        setOrderId(null);
        setCurrentWorkProject([]);
        sendSentryError('Team Graphic Finish Work ERROR', sentryContext);
      });
  };

  const handleDisabledMode = () => {
    if ([PERMISSION_ENUM.ACCM].includes(permission)) {
      return false;
    }

    return orderId !== currentWorkProject[0]?.id ? true : false;
  };

  return (
    <div className={`key-visual-box-container box box-${permission}`}>
      <div className="title-row">
        <ViewIcon />
        <h1>Twoje zlecenia</h1>
      </div>
      <PageComponent
        page={page}
        setPage={setPage}
        setVariantID={setVariantID}
        data={variantsList}
        finished={orderDetails?.designer_status === 'finished'}
        type={
          permission === PERMISSION_ENUM.ACCM
            ? PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_ACCM
            : PAGE_COMPONENT_ENUMS.PAGE_COMPONENT_TYPES.TEAM_GRAPHIC
        }
        sendAction={finishWork}
        sendLoader={sendLoader}
        permission={permission}
        showContent={orderId ? true : false}
      >
        {orderId ? (
          <>
            {productsData[page]?.products.length > 0 && <TeamProductView productsData={productsData[page]} />}
            <Table
              data={variantData[page] || null}
              orderID={orderId}
              variantID={variantID}
              orderDetails={orderDetails}
            />
            <CorrectionList variantID={variantID} page={page} data={variantBoxFieldValue} orderID={orderId} />
            {agentionNotesTypes.map((note, index) => {
              return (
                <AgentionNotes
                  key={`agention-notes-ks-${index}`}
                  isReformat={orderDetails?.order_reformat ? true : false}
                  orderID={orderId}
                  variantsData={variantsList}
                  showVariant={page}
                  reformatData={orderDetails ? orderDetails[note.note_type] : ''}
                  noteType={note.note_type}
                  viewType={AGENTION_NOTES_ENUMS.VIEW_TYPE.TEAM}
                  saveButton={note.button}
                />
              );
            })}
            <div className="addfile-content">
              <div className="title-wrapper">
                <h2 className="under-title">DODAJ PLIK</h2>
                <div className="border-title" />
              </div>
              <AddFileWrapper
                loading={loading}
                handleInputFileChange={handleUploadFile}
                uploadFilesList={uploadFilesList}
                isDisabled={handleDisabledMode()}
              />
              {/* </div> */}
              {externalLinksComponentTypes.map((link, index) => {
                return (
                  <ExternalLink
                    key={`external-link-ks-${index}`}
                    orderID={orderId}
                    variantID={variantID}
                    linkType={link.link_type}
                    variantsData={variantsList}
                    showVariant={page}
                    orderData={orderDetails}
                    setValidation={setValidatedExternalLink}
                    validateStructure={validatedExternalLink}
                    saveButton={link.saveButton}
                    isDisabled={handleDisabledMode()}
                    isReformat={orderDetails?.is_reformat || false}
                  />
                );
              })}
              <ImageList
                list={imagesData}
                setImagesData={setImagesData}
                orderID={orderId}
                setRealoadComponent={setRealoadComponentKV}
                activeReloadAnimation={activeReloadAnimation}
                setActiveReloadAnimation={setActiveReloadAnimation}
                handleUploadFile={handleUploadFile}
                isDisabled={handleDisabledMode()}
                variantID={variantID}
                permission={permission}
                orderDetails={orderDetails}
              />
            </div>
          </>
        ) : (
          <span>Aktualnie nie pracujesz nad żadnym projektem</span>
        )}
      </PageComponent>
      {keyVisualDataLoader && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
    </div>
  );
};

export default KeyVisual;
