import React, { useState, useContext, useEffect } from 'react';
import { ReformatContext } from '../../../pages/e-commerce/Reformatting';

const ReformatingActionName = ({ data, setData, boxID, validate, sourceType }) => {
  const { reformatData, setReformatData } = useContext(ReformatContext);
  const [noSourceError, setNoSourcerError] = useState(false);
  const [actionName, setActionName] = useState(reformatData.order_name);

  const handleInput = (e) => {
    if (sourceType) {
      setNoSourcerError(false);
      setActionName(e.target.value);
      for (let i = 0; i < data.length; i++) {
        if (data[i].box_field === boxID) {
          data[i].order[0].value.content = e.target.value;
          break;
        }
      }
      setData(data);
    } else {
      setNoSourcerError(true);
    }
  };

  useEffect(() => {
    setNoSourcerError(false);
  }, [sourceType]);

  const handlePrefix = () => {
    const newActionName = actionName.endsWith('_reformat') || !actionName ? actionName : `${actionName}_reformat`;
    setActionName(newActionName);
    setReformatData((prevState) => {
      return {
        ...prevState,
        action_name: newActionName,
        order_name: newActionName
      };
    });
  };

  return (
    <>
      <div className="reformatting-action-name">
        <input
          className={validate ? 'active' : 'active not-validated'}
          placeholder="Wpisz..."
          value={actionName || ''}
          onChange={handleInput}
          onBlur={handlePrefix}
        ></input>
        {noSourceError && (
          <span className="error campaign">
            *Aby wpisać nazwę akcji <strong>musisz wybrać plik źródłowy</strong>
          </span>
        )}
      </div>
    </>
  );
};

export default ReformatingActionName;
