import React, { useEffect, useState } from 'react';
import generateReformatsDisplayView from '../../../../../../functions/reformatsFunctions/generateReformatsDisplayView';
import handleLinkInText from '../../../../../../functions/handleLinkInText';
import Image from '../../../Table/collapseElements/bannerElements/Image';
import getClonedImage from '../../../../../../functions/getClonedImage';
import imageServices from '../../../../../services/imageServices';
import ImageModal from '../../../Popup/ImageModla';
import MODAL_TYPES from '../../../Popup/ENUMS/IMAGE_MODAL_TYPES';
import ProductShow from '../../../../globalElements/Products/ProductsShow';

const Table = ({ data, orderID, variantID, orderDetails }) => {
  const [reformatData, setReformatData] = useState([]);
  const [mainBannerFromClone, setMainBannerFromClone] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  /* this useEffect part of code i used for get order reformats details */
  useEffect(() => {
    if (orderDetails) {
      const orderReformat = orderDetails.order_reformat;
      if (orderReformat?.basic_size_list && orderReformat?.custom_size_list) {
        const reformatDataStructure = generateReformatsDisplayView(orderReformat);

        if (reformatDataStructure.length) setReformatData(reformatDataStructure);
      } else if (reformatData.length) {
        setReformatData([]);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    imageServices
      .getImages(orderID, variantID)
      .then((res) => {
        setMainBannerFromClone(getClonedImage(res));
      })
      .catch((error) => {
        console.error(`KS get variant images error: ${error}`);
      });
  }, [variantID]);

  return (
    <div className="table-wrapper">
      <table className="table">
        <tbody>
          {data &&
            Object.keys(data).map(function (key, index) {
              if (data[key]) {
                return (
                  <tr key={index}>
                    <td>{key}</td>
                    {Array.isArray(data[key]) ? (
                      <td className="logotype-value" style={{ padding: 0 }}>
                        {data[key]}
                      </td>
                    ) : typeof data[key] === 'object' ? (
                      <td className="product-value">{data[key]}</td>
                    ) : (
                      <td
                        dangerouslySetInnerHTML={{
                          __html: handleLinkInText(data[key])
                        }}
                      ></td>
                    )}
                  </tr>
                );
              }
            })}
          {reformatData.length > 0 &&
            reformatData.map((el, index) => {
              return (
                <tr key={`reformats-${index}`}>
                  <td>{el.name}</td>

                  {typeof el.value === 'object' ? (
                    <td className="reformats">{el.value}</td>
                  ) : (
                    <td
                      className="reformats"
                      dangerouslySetInnerHTML={{
                        __html: handleLinkInText(el.value)
                      }}
                    ></td>
                  )}
                </tr>
              );
            })}
          {mainBannerFromClone && (
            <tr className="info-box">
              <td className="name">{`Główny banner`}</td>
              <td className="main-cloned-banner">
                <Image
                  source={mainBannerFromClone.source}
                  imagePK={mainBannerFromClone.pk}
                  index={0}
                  handleImage={() => {
                    setShowImageModal(true);
                  }}
                  key={`image-${0}`}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showImageModal && (
        <ImageModal
          setShowImageModal={setShowImageModal}
          imageSource={mainBannerFromClone.source}
          modalType={MODAL_TYPES.SINGLE_IMAGE}
        />
      )}
    </div>
  );
};

export default Table;
