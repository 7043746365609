import authHeader from '../helper/auth-header';
import { handleResponse } from '../helper/handle-response';

const getTokens = (api_key, core_id) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ session_key: `${api_key}`, core_id: `${core_id}` })
  };
  return fetch(`${process.env.REACT_APP_API_URL}/core/token/`, requestOptions).then((r) => {
    return r.json();
  });
};

const getCurrentUser = () => {
  const tokensJson = localStorage.getItem('tokens');
  if (!tokensJson) {
    return false;
  }
  const tokens = JSON.parse(tokensJson);

  if (tokens && Object.keys(tokens).length === 0) {
    return false;
  }

  return tokens;
};

const getRefleshToken = async () => {
  const tokens = getCurrentUser();
  //jesli epmpty to logout
  const { refresh } = tokens;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: refresh })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/core/token/refresh/`, requestOptions).then((r) => {
    return r.json().then((r) => {
      if (r.access) {
        localStorage.setItem('tokens', JSON.stringify({ ...tokens, access: r.access }));
        return { access: r.access };
      } else {
        return false;
      }
    });
  });
};

const getCurrentUserId = async () => {
  const auth = authHeader();
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: auth }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/teams/current_user`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getTokens,
  getRefleshToken,
  getCurrentUser,
  getCurrentUserId
};
