import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';
import RAPORTS_ENUMS from '../elements/e-commerce/Raports/RAPORTS_ENUMS.js';

const getReport = async (
  chosenMethod,
  order_statuses,
  date_chosen_to,
  date_chosen_from,
  include_reformats,
  report_name,
  language,
  format_lists
) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  let endpoint = `reports/?`;

  const filteredFormatsList = format_lists.filter((format) => {
    if (format !== 'reformats') return format;
  });

  if (date_chosen_to) endpoint += `img_created_to=${date_chosen_to}&`;
  if (date_chosen_from) endpoint += `img_created_from=${date_chosen_from}&`;
  if (chosenMethod) endpoint += `method=${chosenMethod}&`;
  if (order_statuses && order_statuses !== RAPORTS_ENUMS.TYPE.ALL) endpoint += `order_statuses=${order_statuses}&`;
  if (include_reformats) endpoint += `include_reformats=${include_reformats}&`;
  if (report_name) endpoint += `report_name=${report_name}&`;
  if (language) endpoint += `language=${language}&`;

  //filtering formats_list and chose id's
  if (filteredFormatsList?.length > 0) endpoint += `formats_list=${filteredFormatsList.join(',')}&`;

  //check if special range is chosen
  endpoint += `include_reformats=${format_lists.includes('reformats') ? true : false}&`;

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getReport
};
