import React, { useState, useContext, useRef } from 'react';
import CalenderContainer from '../calenderElements/CalenderContainer';
import { MainAppContext } from '../../MainAppComponent';
import { ReactComponent as PeriodIcon } from '../../../assets/raports/period.svg';

const CalenderFromTo = ({
  chosenDateFrom,
  setChosenDateFrom,
  chosenDateTo,
  setChosenDateTo,
  isValidated,
  handleFilter,
  calenderRef,
  displaySearchButton
}) => {
  const [hour, setHoure] = useState('');
  const [minute, setMinute] = useState('');
  const { setShadowActive } = useContext(MainAppContext);
  const [error, setError] = useState(false);

  const [displayCalenderFrom, setDisplayCalenderFrom] = useState(false);
  const [displayCalenderTo, setDisplayCalenderTo] = useState(false);

  const calendarFromRef = useRef();
  const calendarToRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  const disactiveOnDeadlineFromChose = () => {
    setShadowActive(false);
    setDisplayCalenderFrom(false);
  };
  const disactiveOnDeadlineToChose = () => {
    setShadowActive(false);
    setDisplayCalenderTo(false);
  };

  return (
    <div className="filter-deadline calender-from-to" ref={calenderRef}>
      <div className="title-section">
        <PeriodIcon />
        <h3>Okres</h3>
      </div>

      <div className="inputs">
        <div className="calender-input">
          <span className="calender-from">Od: </span>
          <CalenderContainer
            blockEdit={false}
            calendarRef={calendarFromRef}
            hour={hour}
            minute={minute}
            hourRef={hourRef}
            minuteRef={minuteRef}
            setChosenDate={setChosenDateFrom}
            setHoure={setHoure}
            setMinute={setMinute}
            chosenDate={chosenDateFrom}
            error={error}
            inputValidated={isValidated}
            handleShadow={false}
            calenderValue={chosenDateFrom}
            allowChoseAnyDate={true}
            showTimeInput={false}
            disActiveOnDateChose={disactiveOnDeadlineFromChose}
            displayCalender={displayCalenderFrom}
            setDisplayCalender={setDisplayCalenderFrom}
            autoDateSet={true}
          />
        </div>
        <div className="calender-input">
          <span className="calender-to">Do: </span>
          <CalenderContainer
            blockEdit={false}
            calendarRef={calendarToRef}
            hour={hour}
            minute={minute}
            hourRef={hourRef}
            minuteRef={minuteRef}
            setChosenDate={setChosenDateTo}
            setHoure={setHoure}
            setMinute={setMinute}
            chosenDate={chosenDateTo}
            error={error}
            inputValidated={isValidated}
            handleShadow={false}
            calenderValue={chosenDateTo}
            allowChoseAnyDate={true}
            showTimeInput={false}
            disActiveOnDateChose={disactiveOnDeadlineToChose}
            displayCalender={displayCalenderTo}
            setDisplayCalender={setDisplayCalenderTo}
            autoDateSet={true}
          />
        </div>
      </div>
      {displaySearchButton && (
        <button className="btn-search" onClick={handleFilter}>
          Szukaj
        </button>
      )}
    </div>
  );
};

export default CalenderFromTo;
