/**
 * renderCalendar() - method that render custom calender
 *
 * @param {*} setCurrentDays - state that set chosen month days
 * @param {*} setCurrentMonthName - state that set hosen month name
 * @param {*} currYear - state that holds current year
 * @param {*} currMonth - state that holds current month
 * @param {*} date - state that holds current year
 * @param {Function} handleDate - method that help handle chosen date
 */

import { month_list } from '../../components/elements/calenderElements/month_list';
import React from 'react';
const renderCalendar = (setCurrentDays, chosenDate, setCurrentMonthName, currYear, currMonth, handleDate) => {
  const months = month_list;
  const firstDayofMonth = new Date(currYear, currMonth, 0).getDay();
  const lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate();
  const lastDayofMonth = new Date(currYear, currMonth, lastDateofMonth).getDay();
  let dayCounter = 1;

  setCurrentDays([]);

  // Extract day from chosenDate
  const chosenDay = chosenDate ? parseInt(chosenDate.split('-')[0]) : null;

  for (let i = firstDayofMonth; i > 0; i--) {
    setCurrentDays((prevState) => [...prevState, <li className="inactive" key={`first-day-${i}`}></li>]);
    dayCounter += 1;
  }

  for (let i = 1; i <= lastDateofMonth; i++) {
    const isToday =
      i === new Date().getDate() && currMonth === new Date().getMonth() && currYear === new Date().getFullYear()
        ? 'is-today'
        : '';

    const isChosen = i === chosenDay ? 'chosen' : ''; // Check if this is the chosen day

    const month = currMonth < 9 ? `0${currMonth + 1}` : currMonth + 1;
    const day = i < 10 ? `0${i}` : i;
    const dateValue = `${day}-${month}-${currYear}`;

    setCurrentDays((prevState) => [
      ...prevState,
      <li className={`${isToday} ${isChosen}`} data-value={dateValue} onClick={handleDate} key={`day-${i}`}>
        {i}
      </li>
    ]);

    dayCounter += 1;
  }

  for (let i = lastDayofMonth; i < 7; i++) {
    setCurrentDays((prevState) => [...prevState, <li className="inactive" key={`last-${i}`}></li>]);
    dayCounter += 1;
  }

  setCurrentMonthName(months[currMonth] + ' ' + currYear);
};

export default renderCalendar;
