import React, { useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { TableDataContext } from '../../../../Router';
import { useSearchParams } from 'react-router-dom';
import { ORDER_DATA_PARAMETERS_ACTION_TYPE } from '../OrderDataParameterReducer/orderDataParametersReducer';

const Pagination = ({ orderDataParameters, setOrderDataParameters }) => {
  const { tableContext } = useContext(TableDataContext);
  const pageCount = tableContext.total_page;
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageClick = (data) => {
    let updateParams = false;
    const page = data.selected + 1;

    setOrderDataParameters({
      type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGINATION,
      page: page
    });

    if (searchParams.has('order')) {
      searchParams.delete('order');
      updateParams = true;
    }
    if (searchParams.has('pagination')) {
      searchParams.delete('pagination');
      updateParams = true;
    }

    if (updateParams) setSearchParams(searchParams);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Następna"
        initialPage={orderDataParameters.page - 1}
        onPageChange={handlePageClick}
        disableInitialCallback={true}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="Poprzednia"
        renderOnZeroPageCount={null}
        className={`table-pagination `}
      />
    </>
  );
};

export default Pagination;
