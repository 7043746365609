/**
 * generateDisplayData() - A method that generates the structure of data displayed in tables
 *
 * example usage: CollapseRow.js, CorrectionList.js
 *
 * @param {array} data - data of box fields values
 * @param {integer} variantIndex - variant ID
 * @returns filtered data structure to display in tables
 */

import generateTableContent from './generateTableContent';
import { generateReformatSizes } from './reformatsFunctions/generateReformatsDisplayView';

const generateDisplayData = (data, variantIndex, compare, customSizeList) => {
  const filteredData = data.map((el) => {
    let is_size = false,
      is_product = false,
      is_logotype = false,
      is_global_order_value = false;
    let currentVariantValue = '',
      fieldType,
      variantID;
    if (el.variants) {
      const elementValue = el.variants[variantIndex]?.value;
      const calculatedContent = el.variants[variantIndex]?.calculated_value?.calculated_content;

      if (calculatedContent) {
        const calculatedContentTitle = generateTableContent.generateCalculetedContent(calculatedContent) + '<br/>';
        if (calculatedContent[0]?.default_name_placement == 'right') {
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value
            ? el.variants[variantIndex]?.custom_inputs_value + ' '
            : '';
          currentVariantValue += calculatedContentTitle;
        } else if (calculatedContent[0]?.default_name_placement == 'left') {
          currentVariantValue += calculatedContentTitle;
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value
            ? el.variants[variantIndex]?.custom_inputs_value
            : '';
        } else if (el.variants[variantIndex]?.custom_inputs_value) {
          currentVariantValue += el.variants[variantIndex]?.custom_inputs_value;
        } else {
          currentVariantValue = calculatedContentTitle;
        }
      } else if (elementValue) {
        if (elementValue[0]?.ean) {
          //product fields
          if (compare) {
            const productList = elementValue;

            productList?.forEach((product, index) => {
              currentVariantValue += product.ean;

              if (index + 1 < productList.length) currentVariantValue += ', ';
            });
          } else {
            const productsArr = elementValue;
            const variant_id = el.variants[variantIndex]?.variant_id;
            is_product = true;
            currentVariantValue = generateTableContent.generateProductContent(productsArr, variant_id);
          }
        } else if (elementValue[0]?.type) {
          //logotype fields
          const logosArr = elementValue.filter((el) => el.url); //filter for old structure
          const variant_id = el.variants[variantIndex]?.variant_id;
          const customInputsValue = el.variants[variantIndex]?.custom_inputs_value;
          is_product = false;
          is_logotype = true;
          currentVariantValue = generateTableContent.generateLogotypeContent(logosArr, variant_id, customInputsValue);
        } else if (elementValue.length > 0) {
          const shouldBeList = el.variants[variantIndex].value.length > 0;
          el.variants[variantIndex].value.map((el, index) => {
            if (typeof el === 'object') {
              //this object is for background source from BackgroundChooser.js file
              currentVariantValue += `${shouldBeList ? `<strong>Tło numer ${index + 1}:</strong>` : ''} </br> ${el.src} </br> ${el.description}`;
            } else {
              currentVariantValue += `${el}, `;
            }
          });
        } else {
          currentVariantValue = el.variants[variantIndex]?.custom_inputs_value
            ? el.variants[variantIndex].custom_inputs_value
            : elementValue.content || '';
        }
      }

      variantID = el.variants[variantIndex]?.variant_id;

      fieldType = 'variants';
    } else if (el.order) {
      is_global_order_value = true; //A-163 solution

      if (el.order[0]?.calculated_value?.calculated_content) {
        if (el.order[0].value?.children) is_size = true;

        if (el.order[0].basic_size_list) {
          /*
            ABC-242, check if order has basic_size list and custom_size_list. 
            If it has that means order is reformated and we have to prepare special HTML structure to display that data for order sizes
          */
          const basicSizes = el.order[0]?.basic_size_list ? JSON.parse(el.order[0].basic_size_list) : null;
          const customSizes = el.order[0]?.custom_size_list ? JSON.parse(el.order[0].custom_size_list) : null;

          currentVariantValue = generateReformatSizes(basicSizes, customSizes);
        } else {
          //default way of displaying sizes in tables and CompareColumn.js
          currentVariantValue += generateTableContent.generateCalculetedContent(
            el.order[0].calculated_value.calculated_content
          );
        }
      } else {
        currentVariantValue = el.order[0].custom_inputs_value
          ? el.order[0].custom_inputs_value
          : el?.order[0]?.value?.content || '';
      }

      variantID = el.order[0].order_id;
      fieldType = 'order';
    } else {
      currentVariantValue = '';
    }

    const boxFieldID = el.box_field;

    return {
      name: el.name,
      value: currentVariantValue,
      type: fieldType,
      boxFieldID: boxFieldID,
      variantID: variantID,
      is_size: is_size,
      is_product: is_product,
      is_logotype: is_logotype,
      is_changed: el.is_changed,
      setting: el.settings,
      is_global_order_value: is_global_order_value
    };
  });

  return filteredData;
};

export default generateDisplayData;
