const initHardReload = () => {
  const cetaNavbar =
    process.env.REACT_APP_ENV === 'local'
      ? document.querySelector('header .actions')
      : document.querySelector('.nav.navbar-top-links');
  const firstNavbarElement = cetaNavbar.querySelector('li');
  const liElement = document.createElement('li');
  const liIcon = document.createElement('i');

  liElement.classList.add('hard-reload');

  liIcon.classList.add('fa', 'fa-refresh');

  liElement.appendChild(liIcon);

  liElement.setAttribute('title', 'Załaduj stronę ponownie');

  cetaNavbar.insertBefore(liElement, firstNavbarElement);

  liElement.addEventListener(`click`, () => {
    window.location.reload(true);
  });
};

export default { initHardReload };
