import React, { useContext, useRef } from 'react';
import { TableDataContext } from '../../../../Router';

import TABLE_TYPES from '../../../../../globalEnums/TABLE_TYPES_ENUMS';
import notificationServices from '../../../../services/notificationServices';
import notificationBell from '../../../../../cetaMethods/notificationBell';
import { ReloadTableContext } from '../../../../Router';
import { UserContext } from '../../../../wrapper/AuthWrap';
import generateTableElementsClass from '../collapseElements/helper/generateTableElementsClass';
import { ORDER_DATA_PARAMETERS_ACTION_TYPE } from '../OrderDataParameterReducer/orderDataParametersReducer';

const TableHeaders = ({ setOrderDataParameters, orderDataParameters }) => {
  const { tableContext } = useContext(TableDataContext);
  const { setReloadComponent } = useContext(ReloadTableContext);
  const { user } = useContext(UserContext);
  const tableHeaderRef = useRef([]);
  const sortButtonRef = useRef();

  const handleOrderByList = (value) => {
    const orderByListCopy = [...orderDataParameters.ordering];

    for (let i = 0; i < orderByListCopy.length; i++) {
      if (orderByListCopy[i]?.includes(value)) {
        orderByListCopy.splice(i, 1);
        break;
      }
    }

    return orderByListCopy;
  };

  const sortTable = (e, header) => {
    const order = e.currentTarget.dataset.order;
    const button = e.currentTarget.querySelector('.sort-btn');

    let orderByListCopy = [];

    if (header.box_field) {
      orderByListCopy = handleOrderByList(header.box_field);
    } else {
      orderByListCopy = handleOrderByList(header.defined_field);
    }

    if (header.is_sortable) {
      if (order === 'desc') {
        e.currentTarget.dataset.order = 'asc';
        button.classList.remove('desc');
        button.classList.add('asc');
        if (header.box_field) {
          setOrderDataParameters({
            type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY,
            ordering: [...orderByListCopy, `${header.box_field}`]
          });
        } else {
          setOrderDataParameters({
            type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY,
            ordering: [...orderByListCopy, header.defined_field]
          });
        }
      } else if (order === 'asc') {
        e.currentTarget.dataset.order = '';
        button.classList.remove('asc');
        setOrderDataParameters({
          type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY,
          ordering: [...orderByListCopy]
        });
      } else {
        e.currentTarget.dataset.order = 'desc';
        button.classList.remove('asc');
        button.classList.add('desc');
        if (header.box_field) {
          setOrderDataParameters({
            type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY,
            ordering: [...orderByListCopy, `-${header.box_field}`]
          });
        } else {
          setOrderDataParameters({
            type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY,
            ordering: [...orderByListCopy, `-${header.defined_field}`]
          });
        }
      }
    }
  };

  const handleMarkAllAsRead = () => {
    notificationServices.markAllAsRead().then(() => {
      setReloadComponent(true);
    });

    notificationBell.getNotificationNumber();
  };

  return (
    <div className={generateTableElementsClass('thead ', tableContext.tableType, user)}>
      {tableContext.tableConfiguration.map((el, index) => {
        return (
          <div
            className="action desc"
            ref={(el) => {
              tableHeaderRef.current.push(el);
            }}
            key={`sort-col-key-${index}`}
            onClick={(event) => sortTable(event, el)}
            data-col={el.defined_field}
            data-order="desc"
            style={{ order: el.position, width: `${el.width}%` }}
          >
            {el.column_title} {el.is_sortable && <button className="sort-btn" ref={sortButtonRef}></button>}
            {tableContext.tableType === TABLE_TYPES.NOTIFICATION &&
              index === tableContext.tableConfiguration.length - 1 && (
                <div
                  className="mark-all-as-read"
                  title="zaznacz wszystko jako przeczytane"
                  onClick={handleMarkAllAsRead}
                ></div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TableHeaders);
