import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { ReactComponent as Close } from '../../../../assets/closse-icon.svg';
import usePopup from '../../../helper/usePopup';

const ShowVideoPopup = ({ url }) => {
  const [name, setName] = useState('');
  const { closePopup } = usePopup();
  useEffect(() => {
    extractFileName(url);
  }, []);
  function extractFileName(url) {
    const parts = url.split('/');
    const fileNameWithExtension = parts.pop() || '';
    setName(fileNameWithExtension.split('.').slice(0, -1).join('.'));
  }
  return (
    <div className="video-popup" onClick={(e) => e.stopPropagation()}>
      <div className="close-container">
        <Close
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            closePopup();
          }}
        />
      </div>
      <ReactPlayer url={url} playing controls width={1280} height={720} />
      <div className="title-container">
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ShowVideoPopup;
