import React, { useEffect, useState, useContext } from 'react';
import ProjectTypeRow from './elements/ProjectTypeRow';
import orderServices from '../../../../services/orderServices';
import { UserContext } from '../../../../wrapper/AuthWrap';
import workLogServices from '../../../../services/workLogServices';
import { TeamContext } from '../TeamBoxAcc/TeamContext';
import Loader from '../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../globalElements/Loader/LOADER_TYPE';
import { ReactComponent as OrderIcon } from '../../../../../assets/team/order.svg';

const TASK_ROW = {
  PENDING: ['accepted', 'accepted_acc', 'ready_for_qa'],
  TO_DO: ['renew', 'correction', 'rejected', 'rejected_acc', 'in_progress', 'accepted_for_realization']
};

const TeamBoxDtp = ({
  data,
  setIsDroppableToTeam,
  setOrderId,
  orderId,
  setRealoadComponent,
  teamBoxDTPLoading,
  setTeamOrderBoxDTPLoading,
  projectAdditionalActionsRef,
  setKeyVisualDataLoader,
  startProjectRef,
  permission,
  userType
}) => {
  const { user } = useContext(UserContext);
  const { currentWorkProject, setCurrentWorkProject } = useContext(TeamContext);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [todoTasks, setTodoTasks] = useState([]);

  const initializeNewWorkLog = (order_id) => {
    setTeamOrderBoxDTPLoading(true);
    if (currentWorkProject) setKeyVisualDataLoader(true);
    startProjectRef.current.forEach((el) => {
      if (el) el.classList.remove('show');
    });

    workLogServices.startWorkLog(order_id).then((res) => {
      handleSetOrder(assignedProjects, res);
      setTeamOrderBoxDTPLoading(false);
      setKeyVisualDataLoader(false);
    });
  };

  const handleSetOrder = (assigned, response) => {
    let currentProject = [];
    const pendingTasks = [];
    const todoTasks = [];

    for (let i = 0; i < assigned.length; i++) {
      if (assigned[i].id === response?.order && !TASK_ROW.PENDING.includes(assigned[i].status)) {
        currentProject = [assigned[i]];
      } else if (TASK_ROW.PENDING.includes(assigned[i].status)) {
        pendingTasks.push(assigned[i]);
        setKeyVisualDataLoader(false);
      } else if (TASK_ROW.TO_DO.includes(assigned[i].status)) {
        todoTasks.push(assigned[i]);
        setKeyVisualDataLoader(false);
      }
    }

    setCurrentWorkProject(currentProject);
    setPendingTasks(pendingTasks);
    setTodoTasks(todoTasks);

    setTeamOrderBoxDTPLoading(false);
  };

  useEffect(() => {
    setIsDroppableToTeam(false);
  }, [data]);

  const checkIfTaskIsHandleByUser = (assigned, res) => {
    for (let i = 0; i < assigned.length; i++) {
      if (assigned[i].id === res.order) {
        return true;
      }
    }
    return false;
  };

  const initData = () => {
    if (user.pk) {
      orderServices.getOrdersAssignedToUserProjects(user.pk).then((r) => {
        setAssignedProjects(r);
        const assigned = r;

        workLogServices.getWorkLog().then((res) => {
          if (checkIfTaskIsHandleByUser(assigned, res)) setOrderId(res.order);
          handleSetOrder(assigned, res);
        });
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div className={`team-box-container box box-${permission}`}>
      <div className="title-row">
        <OrderIcon />
        <h1>Twoje zlecenia</h1>
      </div>
      <div className="boxes-container">
        <ProjectTypeRow
          projectList={false}
          setRealoadComponent={setRealoadComponent}
          data={currentWorkProject}
          title={'Rozpoczęte'}
          initializeNewWorkLog={initializeNewWorkLog}
          currentWorkProject={currentWorkProject}
          assigneToDtp={true}
          setOrderId={setOrderId}
          orderId={orderId}
          startProjectRef={startProjectRef}
          showRocketStartProject={true}
          projectAdditionalActionsRef={projectAdditionalActionsRef}
          showAction={false}
          userType={userType}
        />
        <ProjectTypeRow
          projectList={false}
          setRealoadComponent={setRealoadComponent}
          data={todoTasks}
          title={'Do realizacji'}
          initializeNewWorkLog={initializeNewWorkLog}
          currentWorkProject={currentWorkProject}
          assigneToDtp={true}
          setOrderId={setOrderId}
          orderId={orderId}
          startProjectRef={startProjectRef}
          showRocketStartProject={true}
          projectAdditionalActionsRef={projectAdditionalActionsRef}
          showAction={true}
          userType={userType}
        />
        <ProjectTypeRow
          projectList={true}
          setRealoadComponent={setRealoadComponent}
          assigneToDtp={true}
          orange
          data={pendingTasks}
          currentWorkProject={currentWorkProject}
          title={'Do akceptacji'}
          initializeNewWorkLog={initializeNewWorkLog}
          setOrderId={setOrderId}
          orderId={orderId}
          startProjectRef={startProjectRef}
          showRocketStartProject={false}
          projectAdditionalActionsRef={projectAdditionalActionsRef}
          showAction={false}
          userType={userType}
        />
        {teamBoxDTPLoading && <Loader type={LOADER_TYPE.LOAD_DATA} startAnimation={true} />}
      </div>
    </div>
  );
};

export default TeamBoxDtp;
