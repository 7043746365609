import React from 'react';
import { ReactComponent as SourceFileIcon } from '../../../../../assets/reformats/source_file.svg';
import { ReactComponent as FormatIcon } from '../../../../../assets/reformats/format.svg';
import { ReactComponent as ViewIcon } from '../../../../../assets/view_logo.svg';

const headerText = {
  formats: {
    title: 'Format',
    subtitle: '— wybierz interesujące Cię przeformatowanie z pliku źródłowego zadanego powyżej',
    logo: <FormatIcon />
  },
  source_file: {
    title: 'Plik źródłowy',
    subtitle: '— wybierz layout z którego ma zostać przygotowane przeformatowanie, ew. podaj link do pliku',
    logo: <SourceFileIcon />
  },
  view: { title: 'Podgląd pliku źródłowego', logo: <ViewIcon /> },
  unstandard: {
    title: 'Format niestandardowy',
    subtitle: '—  wpisz w polu poniżej',
    logo: <FormatIcon />
  }
};

const SectionHeader = ({ headerType }) => {
  return (
    <div className="header">
      {headerText[headerType].logo}
      <div className="title">
        <h3>{headerText[headerType].title}</h3>
      </div>
    </div>
  );
};

export default SectionHeader;
