import React, { useEffect, useRef, useState } from 'react';

const CustomSizeInputs = ({
  setOrderCustomSizeList,
  chosenSizes,
  customSizeList,
  handleData,
  removeButtonType,
  reformatData,
  setReformatData
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isActive, setIsActive] = useState(false);

  const handleButton = (event) => {
    setInputValue('');
    setIsActive(false);
    handleData(event, inputValue);
  };
  const handleCheckChange = (size, event) => {
    const checked = event.target.checked;
    if (checked) {
      if (!reformatData.custom_sizes_list.includes(size)) {
        setReformatData({
          ...reformatData,
          custom_sizes_list: [...reformatData.custom_sizes_list, size]
        });
      }
    } else {
      const newSizesList = reformatData.custom_sizes_list.filter((s) => s !== size);
      setReformatData({ ...reformatData, custom_sizes_list: newSizesList });
    }
  };
  const handleChangeValue = (e) => {
    setInputValue(e.target.value);
  };
  const buttonRef = useRef(null);
  useEffect(() => {
    const chosenValues = new Set(chosenSizes?.map((size) => size.value) || []);
    const filteredItems = customSizeList.filter((item) => !chosenValues.has(item));
    if (setOrderCustomSizeList) {
      setOrderCustomSizeList((prevState) => {
        // Compute the new state
        const newState = prevState.filter((value) => filteredItems.includes(value));

        // Check if the new state is different from the previous state
        const isEqual =
          newState.length === prevState.length && newState.every((value, index) => value === prevState[index]);

        // Only update state if it's different
        return isEqual ? prevState : newState;
      });
    }
  }, [chosenSizes, customSizeList]);

  return (
    <>
      {customSizeList?.length > 0
        ? customSizeList.map((size, index) => {
            if (removeButtonType === 'checkbox') {
              return (
                <li className="list-element" key={'li' + index}>
                  <label className="real-checkbox">
                    <input checked={true} type="checkbox" onChange={handleButton} data-remove-value={size}></input>
                    <span className="checkmark"></span>
                    {size}
                  </label>
                </li>
              );
            } else {
              return (
                <li className="list-element" key={'li' + index}>
                  <label className="real-checkbox" style={{ margin: 0 }}>
                    <input
                      type="checkbox"
                      checked={reformatData.custom_sizes_list.includes(size)}
                      onChange={(e) => handleCheckChange(size, e)}
                    />
                    <span className="checkmark"></span>
                    {size}
                  </label>
                </li>
              );
            }
          })
        : null}
      <li className="custom-size-element add-element">
        <label className="real-checkbox" style={{ margin: 0 }} onChange={() => setIsActive((prevState) => !prevState)}>
          <input type="checkbox" checked={isActive} />
          <span className="checkmark"></span>
        </label>
        <span className="custom-text">Inny</span>
        <div className="add-input">
          <input
            className="custom-size-input"
            value={inputValue}
            readOnly={!isActive}
            onClick={(e) => {
              e.stopPropagation();
              setIsActive(true);
            }}
            onChange={handleChangeValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                buttonRef.current.click();
              }
            }}
          ></input>
          <button className="add_btn" ref={buttonRef} onClick={handleButton}></button>
        </div>
      </li>
    </>
  );
};

export default CustomSizeInputs;
