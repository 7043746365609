export const getBoxFieldContent = (boxFieldsData, boxFieldID, actualVariant, isUnique) => {
  let actualBox,
    result = [];

  boxFieldsData.forEach((el) => {
    if (el.box_field == boxFieldID) {
      actualBox = el;
    }
  });
  if (isUnique) {
    let uniqueVariant;
    if (actualBox && 'order' in actualBox && actualBox.order) {
      uniqueVariant = actualBox.order[0];
    } else {
      uniqueVariant = actualBox.variants[0];
    }

    if (uniqueVariant) {
      if (uniqueVariant.value && 'content' in uniqueVariant.value) result = uniqueVariant.value.content;
      if (uniqueVariant.value && 'children' in uniqueVariant.value) result = uniqueVariant.value.children;
    }
  } else {
    actualBox?.variants?.forEach((el) => {
      if (el.variant_id == actualVariant) {
        if (el.value) {
          if ('content' in el.value) result = el.value.content;
          if ('children' in el.value) result = el.value.children;
        }
      }
    });
  }

  return result;
};

export const getHeadersFieldContent = (boxFieldsData, boxFieldID, actualVariant) => {
  let actualBox, result;

  boxFieldsData.forEach((el) => {
    if (el.box_field == boxFieldID) {
      actualBox = el;
    }
  });
  actualBox.order.forEach((el) => {
    if (el.order_id == actualVariant) {
      if (el.value) {
        result = el.custom_inputs_value && el.custom_inputs_value !== '' ? el.custom_inputs_value : el.value.content;
      }
    }
  });
  return result;
};
