import React, { useState, useEffect } from 'react';
import copyToClipboard from '../../../functions/copyToClipboard';
import EXTERNAL_LINKS from './ENUMS/EXTERNAL_LINKS';
import variantsServices from '../../services/variantsServices';
import orderServices from '../../services/orderServices';
import copy from '../../../assets/table_collapse/copy.svg';
import { sendSentryInfo } from '../../helper/sentryHelper';

const ExternalLink = ({
  orderID,
  variantID,
  linkType,
  variantsData,
  showVariant,
  orderData,
  setValidation,
  validateStructure,
  isReformat,
  isReadonly,
  saveButton,
  isDisabled
}) => {
  const [externalLink, setExternalLink] = useState('');
  const [externalLinkData, setExternalLinksData] = useState([]);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);

  const initData = () => {
    let externalLink = '',
      externalLinkDataObj = {};

    const variantsDataCopy = variantsData.map((el, index) => {
      if (linkType === EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL) {
        externalLink = el?.stock_file_url || '';

        if (isReformat) {
          externalLink = orderData?.stock_file_url || el?.stock_file_url || ''; //for old orders with old mechanics
        } else {
          externalLink = el?.stock_file_url || '';
        }

        externalLinkDataObj = {
          variant_id: el.id,
          stock_file_url: el.stock_file_url || ''
        };
      } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION) {
        externalLink = el?.agency_internal_url || '';

        if (isReformat) {
          externalLink = orderData?.agency_internal_url || el?.agency_internal_url || ''; //for old orders with old mechanics
        } else {
          externalLink = el?.agency_internal_url || '';
        }

        externalLinkDataObj = {
          variant_id: el.id,
          agency_internal_url: el.agency_internal_url ? el.agency_internal_url : ''
        };
      } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES) {
        externalLinkDataObj = {
          variant_id: el.id,
          file_url: el.file_url ? el.file_url : ''
        };

        if (isReformat) {
          externalLink = orderData?.file_url || el?.file_url || ''; //for old orders with old mechanics
        } else {
          externalLink = el?.file_url || '';
        }
      } else {
        if (isReformat) {
          externalLink = orderData?.action_url || el?.action_url || ''; //for old orders with old mechanics
        } else {
          externalLink = el?.action_url || '';
        }

        externalLinkDataObj = {
          variant_id: el.id,
          action_url: el.action_url || ''
        };
      }

      if (index === showVariant) {
        setExternalLink(externalLink);
        handleValidation(externalLink);
      }

      return externalLinkDataObj;
    });

    setExternalLinksData(variantsDataCopy);
  };

  useEffect(() => {
    //start init data when we get variantsData
    initData();
  }, [variantsData]);

  useEffect(() => {
    //handle value for each variant, code below is implemented only for orders with variants
    if (!isReformat && externalLinkData[showVariant]) {
      let externalLink = '';

      if (linkType === EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL) {
        externalLink = externalLinkData[showVariant].stock_file_url;
      } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION) {
        externalLink = externalLinkData[showVariant].agency_internal_url;
      } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES) {
        externalLink = externalLinkData[showVariant].file_url;
      } else {
        externalLink = externalLinkData[showVariant].action_url;
      }

      setExternalLink(externalLink);
      handleValidation(externalLink);
    }
  }, [showVariant]);

  const handleSaveLink = () => {
    setActiveButtonLoader(true);

    let body;

    if (linkType === EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL) {
      body = { stock_file_url: externalLink };
    } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION) {
      body = { agency_internal_url: externalLink };
    } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES) {
      body = { file_url: externalLink };
    } else {
      body = { action_url: externalLink };
    }

    if (setValidation) setValidation(handleValidationStructure(externalLinkData[showVariant].variant_id, externalLink));

    sendSentryInfo(`External links save data: ${JSON.stringify(body)}, Order id: ${orderID}`);

    if (isReformat) {
      orderServices.changeOrderDetail(orderID, body).then((res) => {
        setActiveButtonLoader(false);
        handleLocalExternalLinkValue(externalLink);
      });
    } else {
      if (externalLinkData[showVariant]) {
        variantsServices.updateVariants(externalLinkData[showVariant].variant_id, body).then((res) => {
          setActiveButtonLoader(false);
          handleLocalExternalLinkValue(externalLink);
        });
      }
    }
  };

  const handleLocalExternalLinkValue = (value) => {
    const dataCopy = [...externalLinkData];

    if (linkType === EXTERNAL_LINKS.LINKS_TYPES.STOCK_IMAGE_URL) {
      dataCopy[showVariant].stock_file_url = value;
    } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.SERVER_LOCATION) {
      dataCopy[showVariant].agency_internal_url = value;
    } else if (linkType === EXTERNAL_LINKS.LINKS_TYPES.OPEN_FILES) {
      dataCopy[showVariant].file_url = value;
    } else {
      dataCopy[showVariant].action_url = value;
    }

    setExternalLinksData(dataCopy);
  };

  const handleInput = (e) => {
    if (!isReadonly) setExternalLink(e.target.value);
  };

  const handleValidationStructure = (variant_id, value) => {
    const copyValidateStructure = [...validateStructure];

    for (let i = 0; i < copyValidateStructure.length; i++) {
      if (copyValidateStructure[i].variant_id === variant_id) {
        const fields = copyValidateStructure[i].fields;
        for (let j = 0; j < fields.length; j++) {
          if (fields[j].link_type === linkType) {
            fields[j].is_validated = value ? true : false;
            break;
          }
        }
        break;
      }
    }

    return copyValidateStructure;
  };

  const handleValidation = (data) => {
    if (data && setValidation) {
      setValidation(handleValidationStructure(variantID, data));
    }
  };

  const handleKeyDown = (event) => {
    if (['Enter', 'Tab'].includes(event.key)) {
      handleSaveLink();
    }
  };

  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <div className={`external-link ${EXTERNAL_LINKS.COMPONENT_CLASS[linkType]}`}>
      <div className="input-box">
        <span>{EXTERNAL_LINKS.LINK_INPUT_TITLE[linkType]}</span>
        <div className="bottom-part-link">
          <input
            className={'input'}
            placeholder={EXTERNAL_LINKS.LINK_INPUT_PLACEHOLDER[linkType]}
            data-target={`action`}
            value={externalLink || ''}
            onChange={handleInput}
            onBlur={!isReadonly && handleSaveLink}
            onKeyDown={handleKeyDown}
            readOnly={isReadonly}
          ></input>
          <div className="button-container">
            {saveButton && (
              <button
                className={`copy`}
                onClick={copyToClipboard}
                data-value={externalLink}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                disabled={isDisabled}
              >
                {EXTERNAL_LINKS.COPY_OPEN_BUTTON_CONTENT[linkType] === 'skopiuj' ? (
                  <img className="copy-icon" src={copy} alt="" />
                ) : (
                  EXTERNAL_LINKS.COPY_OPEN_BUTTON_CONTENT[linkType]
                )}
                {showTooltip && <div className="tooltip">Kopiuj</div>}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalLink;
