/**
 * handleDisplayMonth() - method that handle calender display month
 *
 * @param {*} element - arrow that swithc months
 * @param {*} currMonth - current month
 * @param {*} currYear - current year
 * @param {*} setCurrMonth - set chosen month to display in calender
 * @param {*} setDate - set date
 * @param {*} setCurrYear - set current year
 */

const handleDisplayMonth = (element, currMonth, currYear, setCurrMonth, setDate, setCurrYear) => {
  const currMonth_help = element.id === 'prev' ? currMonth - 1 : currMonth + 1;

  if (currMonth_help < 0 || currMonth_help > 11) {
    const getDate = new Date(currYear, currMonth, new Date().getDate());
    const getCurrYear = currMonth_help > 11 ? getDate.getFullYear() + 1 : getDate.getFullYear() - 1;
    const getCurrMonth = currMonth_help > 11 ? 0 : 11;

    setDate(getDate);
    setCurrYear(getCurrYear);
    setCurrMonth(getCurrMonth);
  } else {
    const getDate = new Date();
    setDate(getDate);
    setCurrMonth(currMonth_help);
  }
};

export default handleDisplayMonth;
