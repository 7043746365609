import IMAGE_STATUS from '../globalEnums/IMAGE_STATUS';

/**
 * getClonedImage() - search and returns cloned image from duplicated orders
 *
 * @param {Array} images - array of images from server
 * @returns only one main cloned image from the variant
 */
const getClonedImage = (images) => {
  for (let i = 0; i < images.length; i++) {
    if (images[i]?.image && images[i]?.status == IMAGE_STATUS.CLONED) {
      return { source: images[i].image, pk: images[i].pk };
    }
  }

  return null;
};

export default getClonedImage;
