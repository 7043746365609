/**
 * Check if chosen element is in array, if array includes element handleDataListElements will remove thath element, if not element will be added
 * @param {*} element - chosen element
 * @param {Array} dataList - array to handle
 * @returns maped array
 */

const handleDataListElements = (element, dataList) => {
  const copyDataList = [...dataList];

  if (copyDataList.includes(element)) {
    const indexToRemove = copyDataList.indexOf(element);
    copyDataList.splice(indexToRemove, 1);
  } else {
    copyDataList.push(element);
  }

  return copyDataList;
};

export default handleDataListElements;
