const TYPE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  ALL: 'all'
};

const METHOD_TYPE = {
  FILE_CREATION: 'file_creation',
  ORDER_FINISH: 'order_finish'
};

export default {
  TYPE,
  METHOD_TYPE
};
