import React from 'react';

const Month = ({ handleCalenderButton, currentMonthName }) => {
  return (
    <div className="header">
      <button id="prev" onClick={handleCalenderButton}>{`<`}</button>
      <p className="current-date">{currentMonthName}</p>
      <button id="next" onClick={handleCalenderButton}>{`>`}</button>
    </div>
  );
};

export default Month;
