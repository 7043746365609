import React from 'react';

/**
 * AuthorInfo - component to display author of project data
 *
 * Displays department and name of author
 *
 * @param authorsList - list of authors example: [{department: 'DTP', name: 'John'}];
 *
 */

const AuthorInfo = ({ authorsList }) => {
  return (
    <>
      {authorsList?.length > 0 ? (
        <div className={`author-info`}>
          {authorsList.map((author, index) => {
            return (
              <div className="author-info__group" key={`author-${index}`}>
                {author.department && <span className="author-info__department">{author.department}</span>}
                {author.name && <span className="author-info__name">{author.name}</span>}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default AuthorInfo;
