import React, { useState, useRef, useContext } from 'react';
import orderServices from '../../../../../services/orderServices';
import Loader from '../../../../globalElements/Loader/Loader';
import LOADER_TYPE from '../../../../globalElements/Loader/LOADER_TYPE';
import usePopup from '../../../../../helper/usePopup';
import StartObserve from '../../../Popup/AddOrder/StartObserve';
import StopObserve from '../../../Popup/AddOrder/StopObserve';
import { shouldDisplayButton } from '../../../../../../functions/checkPermissions';
import { UserContext } from '../../../../../wrapper/AuthWrap';
import { sendSentryInfo } from '../../../../../helper/sentryHelper';

const ObserveButton = ({ orderID, rowData }) => {
  const buttonRef = useRef();
  const { user } = useContext(UserContext);
  const [activeButtonLoader, setActiveButtonLoader] = useState(false);
  const [isObserved, setIsObserved] = useState(rowData?.is_observed);
  const { initPopup } = usePopup();

  const handleObserve = () => {
    const sentryContext = {
      title: 'Action Details',
      payload: {
        orderId: orderID
      }
    };

    sendSentryInfo('ObserveButton action', sentryContext);

    const observe = rowData.is_observed;

    setActiveButtonLoader(true);
    orderServices
      .observeOrder(orderID, observe)
      .then(() => {
        initPopup(isObserved ? <StopObserve /> : <StartObserve />);
        setActiveButtonLoader(false);
        setIsObserved((prevState) => !prevState);
      })
      .catch((error) => {
        console.error(error);
        setActiveButtonLoader(false);
      });
  };

  return (
    <>
      {shouldDisplayButton('observe', rowData?.order_status, user) !== 'Brak' && (
        <button className={isObserved ? `stop-observe` : `observe`} onClick={handleObserve} ref={buttonRef}>
          {activeButtonLoader ? (
            <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15} />
          ) : isObserved ? (
            'Przestań obserwować'
          ) : (
            'Obserwuj'
          )}
        </button>
      )}
    </>
  );
};

export default ObserveButton;
