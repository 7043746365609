import React, { useEffect, useState } from 'react';

import MODAL_TYPES from '../Popup/ENUMS/IMAGE_MODAL_TYPES';
import Image from './collapseElements/bannerElements/Image';
import ImageModal from '../Popup/ImageModla';
import handleLinkInText from '../../../../functions/handleLinkInText';
import ProductShow from '../../globalElements/Products/ProductsShow';

const TableDataList = ({
  displayData,
  reformatData,
  mainBannerFromClone,
  dataListRef,
  orderDisplay,
  status,
  type,
  client,
  agency
}) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState(mainBannerFromClone?.source || '');

  const handleTinyMceImageModal = (e) => {
    setImageModalSrc(e.target.src);
    setShowImageModal(true);
  };

  const handleBannerFromClone = () => {
    setImageModalSrc(mainBannerFromClone?.source || '');
    setShowImageModal(true);
  };

  useEffect(() => {
    const timout = setInterval(() => {
      if (dataListRef?.current) {
        const tinyMceElement = dataListRef.current.querySelector('.info-box p img');

        if (tinyMceElement) {
          clearInterval(timout);
          tinyMceElement.addEventListener('click', handleTinyMceImageModal);
        }
      }
    }, 300);
  }, []);

  return (
    <>
      {orderDisplay && (
        <>
          <div className="info-box" key={`info-box}`}>
            <span className="name" style={{ color: '#000000' }}>
              STATUS
            </span>
            <span className="value" dangerouslySetInnerHTML={{ __html: handleLinkInText(status) }}></span>
          </div>
          <div className="info-box" key={`info-box`}>
            <span className="name" style={{ color: '#000000' }}>
              RODZAJ ZLECENIA
            </span>
            <span className="value" dangerouslySetInnerHTML={{ __html: handleLinkInText(type) }}></span>
          </div>
          <div className="info-box" key={`info-box`}>
            <span className="name" style={{ color: '#000000' }}>
              KLIENT
            </span>
            <span className="value" dangerouslySetInnerHTML={{ __html: handleLinkInText(client) }}></span>
          </div>
          <div className="info-box" key={`info-box`}>
            <span className="name" style={{ color: '#000000' }}>
              AGENCJA
            </span>
            <span className="value" dangerouslySetInnerHTML={{ __html: handleLinkInText(agency) }}></span>
          </div>
        </>
      )}
      {displayData.length > 0 &&
        displayData.map((el, index) => {
          if (el.value) {
            return (
              <div className="info-box" key={`info-box-${index}`}>
                <span className="name" style={{ color: el.setting?.color_on_list || '#000000' }}>
                  {el.name}
                </span>
                {el.is_product ? (
                  <div className="product-value">
                    <ProductShow productsVisualisation={el.value} />
                  </div>
                ) : el.is_global_order_value ? (
                  <span className="value"> {el.value}</span>
                ) : el.is_logotype ? (
                  <div className="logotype-value">{el.value}</div>
                ) : (
                  <span
                    className="value"
                    dangerouslySetInnerHTML={{
                      __html: handleLinkInText(el.value)
                    }}
                  ></span>
                )}
              </div>
            );
          }
        })}
      {reformatData.length > 0 &&
        reformatData.map((el, index) => {
          return (
            <div className="info-box" key={`reformatData-key-${index}`}>
              <span className="name">{el.name}</span>
              {typeof el.value === 'object' ? (
                <span className="value">{el.value}</span>
              ) : (
                <span
                  className="value"
                  dangerouslySetInnerHTML={{
                    __html: handleLinkInText(el.value)
                  }}
                ></span>
              )}
            </div>
          );
        })}
      {mainBannerFromClone && (
        <div className="info-box">
          <span className="name">{`Główny banner`}</span>
          <div className="main-cloned-banner">
            <Image
              source={mainBannerFromClone.source}
              imagePK={mainBannerFromClone.pk}
              index={0}
              handleImage={handleBannerFromClone}
              key={`image-${0}`}
            />
          </div>
        </div>
      )}

      {showImageModal && (
        <ImageModal
          setShowImageModal={setShowImageModal}
          imageSource={imageModalSrc}
          modalType={MODAL_TYPES.SINGLE_IMAGE}
        />
      )}
    </>
  );
};

export default TableDataList;
