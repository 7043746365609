import React from 'react';
import { ReactComponent as Send } from '../../../../../assets/popups/send.svg';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';
const Success = () => {
  const navigate = useNavigate();
  const { closeOrderPopup } = usePopup();
  const handleBackToDashboard = () => {
    closeOrderPopup();
    navigate('/');
  };
  return (
    <div className="send-popup">
      <div className="upper-part-popup">
        <Send />
        <h3>Zlecenie pomyślnie przesłane do realizacji.</h3>
      </div>
      <div className="single-button">
        <button className="button accept" onClick={handleBackToDashboard}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Success;
