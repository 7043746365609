import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';

const removeOrderVariant = async (variant_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  let res = await fetch(`${process.env.REACT_APP_API_URL}/orders/variant/${variant_id}/`, requestOptions);

  const attempts = 3;
  let step = 0;

  while (!res.ok && step < attempts) {
    res = await fetch(`${process.env.REACT_APP_API_URL}/orders/variant/${variant_id}/`, requestOptions);

    step++;
  }
};

export default {
  removeOrderVariant
};
