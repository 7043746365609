import React from 'react';
import { capitalizeFirstLetter } from '../../functions/capitalizeFirstLetter';

const RadioInput = ({
  label,
  value,
  language,
  checked,
  onChange,
  ref,
  dataTextContent,
  dataType,
  dataCheckbox,
  dataCheckboxGroup
}) => {
  return (
    <label className={`custom-radio ${language ? 'language-radio' : null}`}>
      <input
        data-text-content={dataTextContent}
        checked={checked}
        type="radio"
        data-checkbox={dataCheckbox}
        data-checkbox-group={dataCheckboxGroup}
        value={value}
        onChange={onChange}
        data-type={dataType}
        ref={ref}
      />
      <span className="custom-radio-checkmark"></span>
      {capitalizeFirstLetter(label)}
    </label>
  );
};

export default RadioInput;
