import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';
import axios from 'axios';

const getReformatList = async () => {
  const auth = authHeader();
  const api_key = getApiKey();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getReformatByID = async (reformat_id) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/${reformat_id}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const createNewReformat = async (data) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const body = JSON.stringify(data);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const updateReformat = async (reformat_id, data) => {
  const auth = authHeader();
  const api_key = getApiKey();

  const body = JSON.stringify(data);

  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/${reformat_id}/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const createNewSize = async (reformat_id, new_size) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const body = JSON.stringify({ reformat: reformat_id, size: new_size });
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    },
    body
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/${reformat_id}/sizes/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getClientSizes = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reformats/client/sizes/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const sendFile = async (reformat_id, file) => {
  const auth = authHeader();

  const formData = new FormData();
  formData.append('file', file);

  axios.post(`${process.env.REACT_APP_API_URL}/reformats/${reformat_id}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: auth,
      'Content-Disposition': `attachment; filename="filename.jpg"`
    }
  });
};

export default {
  createNewReformat,
  createNewSize,
  getClientSizes,
  sendFile,
  getReformatList,
  updateReformat,
  getReformatByID
};
