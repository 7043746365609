import React, { useEffect, useState, useContext, createContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import MyProjectsBox from '../../elements/e-commerce/Team/MyProjectBox/MyProjectsBox';
import TeamBoxAcc from '../../elements/e-commerce/Team/TeamBoxAcc/TeamBoxAcc';
import TeamBoxDtp from '../../elements/e-commerce/Team/TeamBoxDtp/TeamBoxDtp';
import NewOrderBox from '../../elements/e-commerce/Team/NewOrderBox/NewOrderBox.js';
import TeamProvider from '../../elements/e-commerce/Team/TeamBoxAcc/TeamContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { onDragEnd } from '../../elements/e-commerce/Team/helper/onDragEnd';
import KeyVisual from '../../elements/e-commerce/Team/KeyVisual/KeyVisual';
import { segregateOrdersColumns } from '../../elements/e-commerce/Team/helper/segregateOrdersColumns';
import authorization from '../../services/authorization';
import { UserContext } from '../../wrapper/AuthWrap';
import permissionSelection from '../../elements/e-commerce/Team/helper/permissionSelection';
import teamBoxGetData from '../../helper/teamBoxGetData';
import PERMISSION_ENUM from '../../elements/e-commerce/Team/ENUMS/TEAN_PERMISSIONS.js';
import usePopup from '../../helper/usePopup';
import SomethingWentWrong from '../../elements/e-commerce/Popup/AddOrder/SomethingWentWrong';
import ENUMS from '../../elements/e-commerce/Team/ENUMS.js';

export const ReloadTeamContext = createContext(null);

const Team = () => {
  const { user } = useContext(UserContext);
  const { initPopup } = usePopup();
  const [currentUserId, setCurrentUserId] = useState();
  const [permission, setPermission] = useState(PERMISSION_ENUM.ACCM);
  const [orderId, setOrderId] = useState(null);
  const [reloadComponent, setRealoadComponent] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [myProjectBoxLoading, setMyProjectBoxLoading] = useState(true);
  const [newOrderBoxLoading, setNewOrderBoxLoading] = useState(true);
  const [teamBoxLoading, setTeamOrderBoxLoading] = useState(true);
  const [teamBoxDTPLoading, setTeamOrderBoxDTPLoading] = useState(true);
  const [keyVisualDataLoader, setKeyVisualDataLoader] = useState(false);

  const [columns, setColumns] = useState({
    assignedToPerson: {
      name: 'assignedToPerson',
      items: []
    },
    newOrderBox: {
      name: 'newOrderBox',
      items: []
    },
    MyProjectsBox: {
      name: 'MyProjectsBox',
      items: []
    }
  });

  const [isDroppableToTeam, setIsDroppableToTeam] = useState(false);
  const [selectBoxData, setSelectBoxData] = useState({
    role: '',
    person: ''
  });

  const startProjectRef = useRef([]);
  const projectAdditionalActionsRef = useRef([]);
  const initValues = () => {
    authorization
      .getCurrentUserId()
      .then((r) => {
        setCurrentUserId(r.pk);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<SomethingWentWrong type={'negative'} info={'user_id'} />);
      });
    const selectedPerm = permissionSelection(user);

    const setActions = [setMyProjectBoxLoading, setNewOrderBoxLoading];
    if (selectedPerm === PERMISSION_ENUM.CLIENT) {
      segregateOrdersColumns(columns, setColumns, user.pk, setActions);
    } else {
      segregateOrdersColumns(columns, setColumns, null, setActions);
    }

    setPermission(selectedPerm);

    if (searchParams.get('order')) {
      setOrderId(searchParams.get('order'));
    } else {
      setOrderId(null);
    }
  };

  useEffect(() => {
    if (reloadComponent) {
      initValues();
      setRealoadComponent(false);
    }
  }, [reloadComponent]);

  useEffect(() => {
    initValues();
  }, []);

  useEffect(() => {
    teamBoxGetData(selectBoxData, setTeamOrderBoxLoading, columns, setColumns, '');
  }, [selectBoxData.id]);

  return (
    <TeamProvider>
      {process.env.REACT_APP_ENV === 'local' ? (
        <button
          onClick={() => {
            if (permission === PERMISSION_ENUM.ACCM) {
              setPermission(PERMISSION_ENUM.GRAPHIC_DESIGNER);
            } else {
              setPermission(PERMISSION_ENUM.ACCM);
            }
          }}
        >
          widok dla {permission}
        </button>
      ) : null}
      <div className={'team-container'}>
        {permission === PERMISSION_ENUM.ACCM && (
          <DragDropContext
            onDragEnd={(result) => {
              onDragEnd(result, columns, setColumns, selectBoxData.id, currentUserId);
            }}
          >
            <div className="row">
              <div className="col" style={{ width: `330px`, marginRight: `13px` }}>
                <NewOrderBox
                  newOrderBoxLoading={newOrderBoxLoading}
                  setNewOrderBoxLoading={setNewOrderBoxLoading}
                  setRealoadComponent={setRealoadComponent}
                  columns={columns}
                  setColumns={setColumns}
                  data={columns.newOrderBox.items}
                  permission={PERMISSION_ENUM.ACCM}
                  setOrderId={setOrderId}
                  orderId={orderId}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                />
              </div>
              <div className="col" style={{ flex: 1, marginRight: `13px`, minWidth: `652px` }}>
                <MyProjectsBox
                  myProjectBoxLoading={myProjectBoxLoading}
                  setMyProjectBoxLoading={setMyProjectBoxLoading}
                  setRealoadComponent={setRealoadComponent}
                  columns={columns}
                  setColumns={setColumns}
                  isDroppableToMyProjects={true}
                  setIsDroppableToTeam={setIsDroppableToTeam}
                  data={columns.MyProjectsBox.items}
                  permission={PERMISSION_ENUM.ACCM}
                  setOrderId={setOrderId}
                  orderId={orderId}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                  userType={ENUMS.ROLE.ACCM}
                />
              </div>
              <div className="col" style={{ width: `661px` }}>
                <TeamBoxAcc
                  teamBoxLoading={teamBoxLoading}
                  setTeamOrderBoxLoading={setTeamOrderBoxLoading}
                  setRealoadComponent={setRealoadComponent}
                  selectBoxData={selectBoxData}
                  setSelectBoxData={setSelectBoxData}
                  setIsDroppableToTeam={setIsDroppableToTeam}
                  isDroppableToTeam={isDroppableToTeam}
                  data={columns.assignedToPerson.items}
                  setColumns={setColumns}
                  columns={columns}
                  permission={PERMISSION_ENUM.ACCM}
                  setOrderId={setOrderId}
                  orderId={orderId}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                />
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ width: `100%`, height: `calc(100vh - 545px)` }}>
                <KeyVisual
                  setRealoadComponent={setRealoadComponent}
                  orderId={orderId}
                  setOrderId={setOrderId}
                  keyVisualDataLoader={keyVisualDataLoader}
                  setKeyVisualDataLoader={setKeyVisualDataLoader}
                  permission={PERMISSION_ENUM.ACCM}
                />
              </div>
            </div>
          </DragDropContext>
        )}
        {permission === PERMISSION_ENUM.CLIENT && (
          //TODO: Remove for ACC
          <DragDropContext
            onDragEnd={(result) => {
              onDragEnd(result, columns, setColumns, selectBoxData.id, currentUserId, true);
            }}
          >
            <div className="row">
              <div className="col" style={{ width: '40.6%', marginRight: `13px` }}>
                <MyProjectsBox
                  myProjectBoxLoading={myProjectBoxLoading}
                  setMyProjectBoxLoading={setMyProjectBoxLoading}
                  setRealoadComponent={setRealoadComponent}
                  permission={PERMISSION_ENUM.CLIENT}
                  columns={columns}
                  setColumns={setColumns}
                  isDroppableToMyProjects={false}
                  setIsDroppableToTeam={setIsDroppableToTeam}
                  data={columns.MyProjectsBox.items}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                  orderId={orderId}
                  displayName={ENUMS.ROLE.CLIENT}
                />
              </div>
              <div className="col" style={{ width: '60.5%' }}>
                <TeamBoxAcc
                  teamBoxLoading={teamBoxLoading}
                  setTeamOrderBoxLoading={setTeamOrderBoxLoading}
                  setRealoadComponent={setRealoadComponent}
                  permission={PERMISSION_ENUM.CLIENT}
                  selectBoxData={selectBoxData}
                  setSelectBoxData={setSelectBoxData}
                  setIsDroppableToTeam={setIsDroppableToTeam}
                  isDroppableToTeam={true}
                  data={columns.assignedToPerson.items}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                  orderId={orderId}
                />
              </div>
            </div>
          </DragDropContext>
        )}
        {permission === PERMISSION_ENUM.GRAPHIC_DESIGNER && (
          <>
            <div className="row">
              <div className="col" style={{ width: '38.6%' }}>
                <TeamBoxDtp
                  teamBoxDTPLoading={teamBoxDTPLoading}
                  setTeamOrderBoxDTPLoading={setTeamOrderBoxDTPLoading}
                  setRealoadComponent={setRealoadComponent}
                  currentUserId={currentUserId}
                  selectBoxData={selectBoxData}
                  setSelectBoxData={setSelectBoxData}
                  setIsDroppableToTeam={setIsDroppableToTeam}
                  isDroppableToTeam={isDroppableToTeam}
                  data={columns.assignedToPerson.items}
                  setOrderId={setOrderId}
                  orderId={orderId}
                  keyVisualDataLoader={keyVisualDataLoader}
                  setKeyVisualDataLoader={setKeyVisualDataLoader}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                  permission={PERMISSION_ENUM.GRAPHIC_DESIGNER}
                  userType={ENUMS.ROLE.GRAPHIC}
                />
              </div>
              <div className="col" style={{ width: '60.5%' }}>
                <KeyVisual
                  setRealoadComponent={setRealoadComponent}
                  orderId={orderId}
                  setOrderId={setOrderId}
                  keyVisualDataLoader={keyVisualDataLoader}
                  setKeyVisualDataLoader={setKeyVisualDataLoader}
                  permission={PERMISSION_ENUM.GRAPHIC_DESIGNER}
                  startProjectRef={startProjectRef}
                  projectAdditionalActionsRef={projectAdditionalActionsRef}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </TeamProvider>
  );
};

export default Team;
