import React from 'react';

const TableLogotypesList = ({ value, url }) => {
  return (
    <>
      <a className={`logotype-reference`} href={url} target={`_blank`}>
        <span className="value" dangerouslySetInnerHTML={{ __html: value }}></span>
      </a>
    </>
  );
};

export default TableLogotypesList;
