import React from 'react';
import { ReactComponent as Error } from '../../../../../assets/popups/error.svg';
import usePopup from '../../../../helper/usePopup';

const CannotLoadProducts = () => {
  const { closeOrderPopup } = usePopup();
  return (
    <div className="wrong-popup">
      <Error />
      <h3>Nie można wczytać listy produktów</h3>
      <p>
        Pomoc: <a href="mailto:e-commerce@app4ad.pl">e-commerce@app4ad.pl</a>
      </p>
      <div className="single-button">
        <button className="button accept" onClick={() => closeOrderPopup()}>
          OK
        </button>
      </div>
    </div>
  );
};

export default CannotLoadProducts;
