export const getActualBoxFieldState = (boxFieldsData, boxFieldID, actualVariant, isUnique) => {
  let result;

  const actualBox = getActualBox(boxFieldsData, boxFieldID);
  if ('order' in actualBox && actualBox.order) {
    result = actualBox.order[0];
  } else {
    actualBox.variants?.forEach((el) => {
      if (el.variant_id == actualVariant) {
        result = el;
      }
    });
  }

  return result;
};

export const getActualUniqueFieldState = (boxFieldsData, boxFieldID, actualVariant) => {
  let result;

  const actualBox = getActualBox(boxFieldsData, boxFieldID);

  actualBox.order.forEach((el) => {
    if (el.variant_id == actualVariant) {
      result = el;
    }
  });

  return result;
};

export const getUniqueFieldState = (boxFieldsData, boxFieldID, orderID) => {
  let result;

  const actualBox = getActualBox(boxFieldsData, boxFieldID);

  actualBox.order.forEach((el) => {
    if (el.order_id == orderID) {
      result = el;
    }
  });

  return result;
};

export const getActualBoxFieldValue = (boxFieldsData, boxFieldID, actualVariant, isUnique) => {
  let result;

  const actualBox = getActualBox(boxFieldsData, boxFieldID);
  if (isUnique) {
    const variants = actualBox.order[0];
    if (variants.value && 'parent_id' in variants.value) {
      result = variants.value.parent_id;
    } else {
      result = variants.value?.content;
    }
  } else {
    actualBox.variants?.forEach((el) => {
      if (el.variant_id == actualVariant) {
        if (el.value) {
          if ('parent_id' in el.value) {
            result = el.value.parent_id;
          } else {
            result = el.value.content;
          }
        }
      }
    });
  }

  return result;
};

const getActualBox = (boxFieldsData, boxFieldID) => {
  let actualBox;
  boxFieldsData.forEach((el) => {
    if (el.box_field == boxFieldID) {
      actualBox = el;
    }
  });

  return actualBox;
};
