import React from 'react';
import { ReactComponent as Date } from '../../../../../assets/popups/date.svg';
import usePopup from '../../../../helper/usePopup';
import 'react-toastify/dist/ReactToastify.css';

const DeadlineError = () => {
  const { closeOrderPopup } = usePopup();
  const handleBackToDashboard = () => {
    closeOrderPopup();
  };
  return (
    <div className="send-popup">
      <div className="upper-part-popup">
        <Date />
        <h3>
          DEADLINE NA PROPOZYCJE <br />
          nie może być późniejszy <br />
          niż DEADLINE ZLECENIA.
        </h3>
      </div>
      <div className="single-button">
        <button className="button accept" onClick={handleBackToDashboard}>
          OK
        </button>
      </div>
    </div>
  );
};

export default DeadlineError;
