import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import usePopup from '../../../helper/usePopup';
import { ReactComponent as Correct } from '../../../../assets/popups/correct.svg';

const FileSendSuccess = ({ setReloadComponent }) => {
  const navigate = useNavigate();
  const { closePopup } = usePopup();
  const [searchParams, setSearchParams] = useSearchParams();
  const temp = useRef(null);

  useEffect(() => {
    temp.current = setTimeout(() => {
      closePopup();
      setSearchParams({});
    }, 5000);
    return () => {
      clearTimeout(temp.current);
    };
  }, [navigate, closePopup]);

  return (
    <div className={`new-order-popup positive`} onClick={(e) => e.stopPropagation()}>
      <Correct />
      <h3>Projekt pomyślnie wysłany do Accounta.</h3>
      <p
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          textDecoration: 'underline'
        }}
        onClick={() => {
          navigate('/sendedprojects');
          window.location.reload();
          closePopup();
        }}
      >
        Kliknij, żeby sprawdzić status
      </p>
      <div className="single-button">
        <button
          className={`button accept`}
          onClick={() => {
            clearTimeout(temp.current); // Użyj temp.current, aby uzyskać aktualny identyfikator
            setReloadComponent(true);
            closePopup();
            setSearchParams({});
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default FileSendSuccess;
