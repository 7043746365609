import React, { useContext, useState, useEffect } from 'react';
import { TableDataContext } from '../../../../Router';
import Correction from '../InProgressPopups/Correction';
import { ActiveCorrectionPopupPopupsContext } from './CollapseRow';
import Slider from '../Slider';
import PERMISSIONS_ENUMS from '../../../../../globalEnums/PERMISSIONS_ENUMS';
import checkPermissions from '../../../../../functions/checkPermissions';
import { UserContext } from '../../../../wrapper/AuthWrap';
import imageServices from '../../../../services/imageServices';
import { MainAppContext } from '../../../../MainAppComponent';
import Image from './bannerElements/Image';
import checkIfSourceIsPDF from '../../../../../functions/checkIfSourceIsPDF';
import LinksView from './bannerElements/LinkView';
import imageFilterByStatus from '../../../../../functions/imageFilterByStatus';
import getClonedImage from '../../../../../functions/getClonedImage';
import AddFileWrapper from '../../AddFile/elements/AddFileWrapper';
import FileSender from '../../Team/helper/fileSender';
import MP4 from '../../../../../assets/mp4.svg';
import { ReactComponent as AVI } from '../../../../../assets/avi.svg';
import { ReactComponent as SVG } from '../../../../../assets/svg.svg';
import usePopup from '../../../../helper/usePopup';
import ShowVideoPopup from '../../Popup/ShowVideoPopup';
import ShowSVGPopup from '../../Popup/ShowSVGPopup';
import ImageCarouselPopup from '../../Popup/ImageCarouselPopup';
import SomethingWentWrong from '../../Popup/AddOrder/SomethingWentWrong';
import { getFileExtension } from '../../../../../functions/fileExtensions';

const notAllowedStatus = ['in_progress', 'correction', 'ready_for_qa', 'accepted_for_realization', 'correction'];

const CollapseBanner = ({
  data,
  orderID,
  setDisplayData,
  fetchedData,
  isReformat,
  variantID,
  rowData,
  setMainBannerFromClone
}) => {
  const { user } = useContext(UserContext);
  const { tableContext } = useContext(TableDataContext);
  const { setShadowActive } = useContext(MainAppContext);
  const { activeCorrectionPopup } = useContext(ActiveCorrectionPopupPopupsContext);
  const { initPopup } = usePopup();
  const { popup } = useContext(MainAppContext);
  const [showImageModal, setShowImageModal] = useState(false);
  const [fetchedImagesData, setFetchedImagesData] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [mainBanner, setMainBanner] = useState({
    src: null,
    image_pk: null,
    component: null
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [reloadComponent, setRealoadComponent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBannerChose = (e, icon, el, index) => {
    const element = e.target;
    if (icon) {
      const slideIndex = parseInt(index) + 1;
      setMainBanner({
        src: el.image,
        extension: getFileExtension(el.image),
        image_pk: el.pk,
        component: <Image source={el.image} imagePK={el.pkl} index={index} handleImage={handleMainBaner} />
      });
      setCurrentSlide(slideIndex);
    } else {
      const slideIndex = parseInt(element.dataset.index) + 1;
      setMainBanner({
        src: element.src,
        extension: getFileExtension(element.src),
        image_pk: element.dataset.imagePk,
        component: (
          <Image
            source={element.src}
            imagePK={element.dataset.imagePk}
            index={element.dataset.index}
            handleImage={handleMainBaner}
          />
        )
      });
      setCurrentSlide(slideIndex);
    }
    setShadowActive(false);
    setShowImageModal(false);
  };

  const generateImages = () => {
    imageServices.getImages(orderID, variantID, isReformat).then((res) => {
      let mainbanner = { src: null, image_pk: null, component: null };
      const banners = [];

      const filteredImagesByStatus = imageFilterByStatus(res, tableContext.tableType);
      filteredImagesByStatus.forEach((el, index) => {
        if (el?.image) {
          if (mainbanner.src === null) {
            mainbanner = {
              src: el.image,
              image_pk: el.pk,
              extension: getFileExtension(el.image),
              component: (
                <Image
                  source={el.image}
                  modified={el?.modified_at}
                  imagePK={el.pk}
                  index={index}
                  handleImage={handleMainBaner}
                  key={`image-${index}`}
                />
              )
            };
          }
          if (['mp4', 'avi', 'svg'].includes(getFileExtension(el.image))) {
            switch (getFileExtension(el.image)) {
              case 'mp4':
                banners.push(
                  <figure>
                    <img
                      src={MP4}
                      style={{ width: 60, height: 60, objectFit: 'cover' }}
                      onClick={(e) => handleBannerChose(e, true, el, index)}
                    ></img>
                  </figure>
                );
                break;
              case 'avi':
                banners.push(<AVI width={60} height={60} onClick={(e) => handleBannerChose(e, true, el, index)} />);
                break;
              case 'svg':
                banners.push(<SVG width={60} height={60} onClick={(e) => handleBannerChose(e, true, el, index)} />);
                break;
            }
          } else {
            banners.push(
              <Image
                source={el.image}
                modified={el?.modified_at}
                imagePK={el.pk}
                index={index}
                handleImage={handleBannerChose}
                key={`image-${index}`}
              />
            );
          }
        }
      });

      const cloneImage = getClonedImage(res);

      setBannerList(banners);
      setFetchedImagesData(filteredImagesByStatus);
      setMainBannerFromClone(cloneImage);
      if (res[0]) {
        setMainBanner(mainbanner);
      } else {
        setMainBanner({ src: null, component: null });
      }
    });
  };

  useEffect(() => {
    generateImages();

    if (reloadComponent) setRealoadComponent(false);
  }, [variantID, reloadComponent]);

  const handleMainBaner = (e) => {
    const fileDataset = e.target.dataset.file;

    if (fileDataset && checkIfSourceIsPDF(fileDataset)) {
      window.open(fileDataset, '_blank');
    } else {
      setShowImageModal(true);
    }
  };
  useEffect(() => {
    if (showImageModal) {
      initPopup(
        <ImageCarouselPopup
          type={bannerList.length > 1 ? `multiple` : 'single'}
          src={mainBanner.src}
          images={generateImageModalList()}
          close={setShowImageModal}
        />
      );
    }
  }, [showImageModal]);
  useEffect(() => {
    if (popup.display === false) {
      setShowImageModal(false);
    }
  }, [popup]);
  const generateImageModalList = () => {
    const result = [mainBanner.component];

    fetchedImagesData.forEach((el, index) => {
      if (el.pk !== parseInt(mainBanner.image_pk)) {
        result.push(<Image source={el.image} imagePK={el.pk} index={index} handleImage={handleBannerChose} />);
      }
    });

    return result;
  };

  const sendFiles = async (variantID, index, filesList) => {
    if (index < filesList.length) {
      try {
        const r = await imageServices.createImageSlot(orderID, variantID);
        const slot_pk = r.pk;
        const senderResponse = await FileSender({ selectedFile: filesList[index] }, slot_pk);

        if ([500, 502].includes(senderResponse.status)) {
          throw new Error(`HTTP error! Status: ${senderResponse.status}`);
        } else {
          sendFiles(variantID, index + 1, filesList);
        }
      } catch (error) {
        console.error(error);
        initPopup(<SomethingWentWrong />);
        setLoading(false);
      }
    } else {
      setRealoadComponent(true);
      setLoading(false);
    }
  };

  const handleUploadFile = (e) => {
    const filesList = Array.from(e.target.files);
    setLoading(true);
    sendFiles(variantID, 0, filesList);
  };
  const handleShowVideo = (url) => {
    initPopup(<ShowVideoPopup url={url} />);
  };

  const displayFileIcon = () => {
    switch (mainBanner.extension) {
      case 'mp4':
        return (
          <figure>
            <img src={MP4} style={{ width: 150, height: 150 }} onClick={() => handleShowVideo(mainBanner.src)}></img>
          </figure>
        );
      case 'avi':
        return <AVI width={60} height={60} />;
      case 'svg':
        return (
          <SVG
            width={150}
            height={150}
            onClick={() => initPopup(<ShowSVGPopup url={mainBanner.src} data={mainBanner} />)}
          />
        );
    }
  };
  return (
    <>
      <div className={`banner ${tableContext.tableType}`}>
        <span className="files-header">Pliki</span>
        {!notAllowedStatus.includes(rowData.order_status) ||
        checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ALWAYS_SHOW_BANNER) ? (
          <div className="banner-wrapper">
            {checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ADMIN]) &&
              rowData.order_status !== 'finished' && (
                <AddFileWrapper loading={loading} handleInputFileChange={handleUploadFile} width={170} height={170} />
              )}
            {mainBanner.component && (
              <div className="main-banner">
                {['mp4', 'avi', 'svg'].includes(mainBanner.extension)
                  ? displayFileIcon(mainBanner.extension)
                  : mainBanner.component}
              </div>
            )}
          </div>
        ) : null}
        {tableContext.tableType !== 'notification' &&
          (!notAllowedStatus.includes(rowData.order_status) ||
            checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS.ALWAYS_SHOW_BANNER)) && (
            <>
              {mainBanner.component && bannerList.length > 0 ? (
                <>
                  <div className="small-banners">
                    {bannerList.length > 1 ? (
                      <Slider spaceBetween={17} slidesPerView={3} slides={bannerList} hasCustomNavigation={true} />
                    ) : null}
                  </div>
                  <LinksView
                    currentSlide={currentSlide}
                    mainBanner={mainBanner}
                    bannerList={bannerList}
                    type={tableContext.tableType}
                    orderID={orderID}
                    imagePk={mainBanner.image_pk}
                    setReloadComponent={setRealoadComponent}
                  />
                </>
              ) : null}

              {activeCorrectionPopup ? (
                <Correction
                  fetchedData={fetchedData}
                  banner={mainBanner.src}
                  data={data}
                  setDisplayData={setDisplayData}
                  orderID={orderID}
                />
              ) : null}
            </>
          )}
      </div>
    </>
  );
};

export default CollapseBanner;
