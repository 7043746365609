import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../wrapper/AuthWrap';
import PERMISSIONS_ENUMS from '../../../globalEnums/PERMISSIONS_ENUMS';
import checkPermissions from '../../../functions/checkPermissions';
import dashboard from './dashboardElementsList/dashboard';
import { MainAppContext } from '../../MainAppComponent';

const Dashboard = () => {
  const { user, setUser } = useContext(UserContext);
  const { totalRemovePopup, setTotalRemovePopup } = useContext(MainAppContext);

  useEffect(() => {
    //reset some states when go to main view
    if (totalRemovePopup) setTotalRemovePopup(false);
  }, []);
  return (
    <div className="client-service">
      <div className="wrapper">
        {dashboard.dashboardBoxes.map((box, index) => {
          if (box.permission_name) {
            const permision = box.permission_name;
            if (checkPermissions(user, PERMISSIONS_ENUMS.PERMISSIONS[permision])) {
              return (
                <div className="box" id={`box-${index}`} key={`dashboard-${index}`}>
                  <a href={process.env.REACT_APP_ENV === 'local' ? box.link : '/mod_ecommerce' + box.link}>
                    <div className="box-wrapper">
                      <div className="icon">
                        <i className={box.icon} />
                      </div>
                      <div className="text-section">
                        <h2>{box.title}</h2>
                        <span>{box.subtitle}</span>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
          } else {
            return (
              <div className="box" id={`box-${index}`} key={`dashboard-${index}`}>
                <a href={process.env.REACT_APP_ENV === 'local' ? box.link : '/mod_ecommerce' + box.link}>
                  <div className="box-wrapper">
                    <div className="icon">
                      <i className={box.icon} />
                    </div>
                    <div className="text-section">
                      <h2>{box.title}</h2>
                      <span>{box.subtitle}</span>
                    </div>
                  </div>
                </a>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Dashboard;
