import generateTableContent from "../../../../../functions/generateTableContent";

export const prepareDataKeyVisual = (data) => {
  let variantsObjectArray = [];
  let variantsNumber = getVariantsNumber(data);
  data.forEach((objectSingle) => {
      if(objectSingle.order){
        objectSingle.order?.forEach((element, index) => {
          for(let i=0; i<variantsNumber; i++){
            let value = getValue(element, false, objectSingle.name);
            variantsObjectArray[i] = {
              ...variantsObjectArray[i],
              [objectSingle.name]: value,
            };
          }

        });
      }else{
        objectSingle.variants?.forEach((element, index) => {
          let value = getValue(element, true);

          variantsObjectArray[index] = {
            ...variantsObjectArray[index],
            [objectSingle.name]: value,
          };
        });
      }

  });

  return variantsObjectArray;
};

const getValue = (data, hasVariants, type) => {
  let dataValue = data?.value;
  let currentVariantValue = "";
  if (hasVariants && data?.calculated_value?.calculated_content) {
    let calculatedContent = data.calculated_value.calculated_content

    let calculatedContentTitle = generateTableContent.generateCalculetedContent(calculatedContent)+'<br/>';
    if (calculatedContent[0]?.default_name_placement == "right") {
      currentVariantValue += data.custom_inputs_value ? data.custom_inputs_value + " " : "";
      currentVariantValue += calculatedContentTitle;
    } else if (calculatedContent[0]?.default_name_placement == "left") {
      currentVariantValue += calculatedContentTitle;
      currentVariantValue += data.custom_inputs_value ? data.custom_inputs_value : "";
    } else if (data.custom_inputs_value) {
      currentVariantValue += data.custom_inputs_value;
    } else {
      currentVariantValue = calculatedContentTitle;
    }
    return currentVariantValue;
  } else if (dataValue && data?.value[0]?.ean) {
    let productsArr = data?.value;
    let variant_id = data?.variant_id;
    return generateTableContent.generateProductContent(productsArr, variant_id);
  }else if (dataValue && data?.value[0]?.type) {
    let logotypeArr = data?.value;
    let variant_id = data?.variant_id;
    let customInputsValue = data?.custom_inputs_value;
    return generateTableContent.generateLogotypeContent(logotypeArr, variant_id, customInputsValue);
  }else if(data.value?.length > 0 && data.value.every(el=> typeof el === 'object')){
    //in case if field is background source, ABC-203
    dataValue.forEach((value, index, array) => {
      currentVariantValue += `${value?.src || value} ${index < dataValue.length - 1 ? ',' : ''}` //${value?.src || value} this is solution for older orders
    })
    return currentVariantValue
  }else if( type === 'ROZMIAR' && data?.calculated_value?.calculated_content){
    return generateTableContent.generateCalculetedContent(data.calculated_value.calculated_content);
  } if (data.custom_inputs_value) {
    return data.custom_inputs_value;
  } else if (data.value?.content) {
    return data.value.content;
  } else {
    return "";
  }
};

const getVariantsNumber = (data) =>{
  for(let i=0; i<data.length; i++){
    if(data[i].variants) return data[i].variants.length
  }

  return 1
}
