const dashboardBoxes = [
  {
    title: 'Dodaj nowe zlecenie',
    subtitle: 'Łatwo i szybko dodaj nowe zlecenie.',
    link: '/addneworder',
    icon: 'fa fa-plus-circle',
    permission_name: 'ADD_NEW_ORDER_PAGE'
  },
  {
    title: 'Przesłane do agencji',
    subtitle: 'Zlecenia czekające na akceptację.',
    link: '/sendedprojects',
    icon: 'fa fa-paper-plane',
    permission_name: null
  },
  {
    title: 'W realizacji',
    subtitle: 'Zlecenia, przed pierwszą korektą.',
    link: '/inprogressprojects',
    icon: 'fa fa-heart',
    permission_name: null
  },
  {
    title: 'Szkice',
    subtitle: 'Zlecenia przeniesione do oczekujących.',
    link: '/draft',
    icon: 'fa fa-folder-open',
    permission_name: null
  },
  {
    title: 'Przeformatowania',
    subtitle: 'Dodaj zlecenie na przeformatowanie.',
    link: '/reformatting',
    icon: 'fa fa-bomb',
    permission_name: 'REFORMAT_PAGE'
  },
  {
    title: 'Archiwum',
    subtitle: 'Sekcja zakończonych projektów.',
    link: '/archive',
    icon: 'fa fa-archive',
    permission_name: null
  },
  {
    title: 'Team',
    subtitle: 'Zarządzaj projektami.',
    link: '/team',
    icon: 'fa fa-users',
    permission_name: null
  },
  {
    title: 'Powiadomienia',
    subtitle: 'Zarządzaj projektami.',
    link: '/notifications',
    icon: 'fa fa-bell',
    permission_name: null
  },
  {
    title: 'Raportowanie',
    subtitle: 'Podsumowanie czasowe zleceń.',
    link: '/raports',
    icon: 'fa fa-pie-chart',
    permission_name: 'GET_REPORTS_PAGE'
  },
  {
    title: 'Specyfikacja e-commerce',
    subtitle: 'Historia zmian i informacje techniczne.',
    link: '/specification',
    icon: 'fa fa-wrench',
    permission_name: null
  }
];

export default { dashboardBoxes };
