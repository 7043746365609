import React, { useEffect, useState } from 'react';
import AssignedProjectBox from '../../globalelements/AssignedProjectBox';
import usePopup from '../../../../../helper/usePopup';
import Assignee from '../../../Popup/AddOrder/Assignee';

const ProjectTypeRow = ({
  title,
  data,
  initializeNewWorkLog,
  currentWorkProject,
  setRealoadComponent,
  assigneToDtp,
  setOrderId,
  startProjectRef,
  orderId,
  showRocketStartProject,
  projectAdditionalActionsRef,
  showAction,
  userType
}) => {
  const [assignedPopup, setAssignePopup] = useState({
    showPopup: false,
    order_id: null
  });
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(
        <Assignee
          setRealoadComponent={setRealoadComponent}
          setAssignePopup={setAssignePopup}
          orderID={assignedPopup.order_id}
          assigneToDtp={assigneToDtp}
          changeStatus={true}
        />
      );
    }
  }, [assignedPopup]);
  return (
    <div className="project-type-row-container">
      <div className="title-wrapper">
        <p>
          {title} ({data.length})
        </p>
      </div>
      <div className="content">
        {data.map((element, index) => {
          return (
            <AssignedProjectBox
              setRealoadComponent={setRealoadComponent}
              setAssignePopup={setAssignePopup}
              currentWorkProject={currentWorkProject}
              initializeNewWorkLog={initializeNewWorkLog}
              data={element}
              rocket
              messageDtp
              id={element.id}
              key={index}
              my_order
              setOrderId={setOrderId}
              startProjectRef={startProjectRef}
              orderId={orderId}
              showRocketStartProject={showRocketStartProject}
              projectAdditionalActionsRef={projectAdditionalActionsRef}
              showAction={showAction}
              hasMarginRight={index % 2 === 0}
              userType={userType}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectTypeRow;
