/**
 * checkIfContentIncludeID() - checkes if box field content/value include chosen ID, usualy used for handle checkboxes
 * example usage in files: RadioList.js, Dropdown.js, DropdownContainer.js
 *
 * @param {Integer} id - id of the selected value
 * @param {Array} content - box field content
 * @param {Boolean} isUnique - check if content is unique (is global for whole order)
 * @returns true/false
 */

const checkIfContentIncludeID = (id, content, isUnique) => {
  let result = false;
  if (content && content.length > 0) {
    content.forEach((value) => {
      if (isUnique) {
        if (value.id == id) result = true;
      } else {
        if (value == id) result = true;
      }
    });
  }

  return result;
};

export default checkIfContentIncludeID;
