const PERMISSIONS = {
  ADD_NEW_ORDER_PAGE: ['ecommerce_admin', 'ecommerce_orders_create'],
  CAPTURE_BUTTON: ['ecommerce_admin'],
  EDIT_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  RESTORE_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  REMOVE_BUTTON: ['ecommerce_orders_create'],
  ADD_CORECTION_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  ACCEPT_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  ADD_COUNTER_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  ADD_COMMENT_POPUP: ['ecommerce_admin', 'ecommerce_orders_create'],
  REFORMAT_PAGE: ['ecommerce_admin', 'ecommerce_orders_create'],
  DUPLICATE_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  FORMAT_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  DOWNLOAD_BUTTON: ['ecommerce_admin', 'ecommerce_orders_create'],
  FORWARD_TO_GRAPHIC_BUTTON: ['ecommerce_admin'],
  ARCHIVE_PROJECT_LINKS: ['ecommerce_admin', 'ecommerce_orders_create'],
  IN_PROGRESS_ASSIGNE_PROJECT_CLIENT: ['ecommerce_orders_create'],
  IN_PROGRESS_ASSIGNE_PROJECT_ACC: ['ecommerce_admin'],
  EXTERNAL_LINKS: ['ecommerce_admin', 'ecommerce_dtp', 'ecommerce_creation', 'ecommerce_orders_create'],
  ALWAYS_SHOW_BANNER: ['ecommerce_admin', 'ecommerce_dtp', 'ecommerce_creation'],
  GET_REPORTS_PAGE: ['ecommerce_admin', 'ecommerce_dtp', 'ecommerce_creation'],
  EXTERNAL_LINKS_ACTIONS_FULL_ACCESS: ['ecommerce_admin', 'ecommerce_dtp', 'ecommerce_creation']
};

const PERMISSIONS_NAMES = {
  ECOMERCE_ADMIN: 'ecommerce_admin',
  ECOMERCE_ORDER_CREATE: 'ecommerce_orders_create',
  ECOMERCE_ACCESS: 'ecommerce_access',
  ECOMERCE_DTP: 'ecommerce_dtp',
  ECOMERCE_CREATION: 'ecommerce_creation'
};

export default {
  PERMISSIONS,
  PERMISSIONS_NAMES
};
