import React, { useState, useEffect, useRef } from 'react';
import formatDate from '../../../../functions/formatDate';

const CorrectionMasterList = ({ correctionMasterList, chosenCorrectionMaster, setChosenCorrectionMaster }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [chosenValue, setChosenValue] = useState(null);

  const dropdownRef = useRef();

  useEffect(() => {
    const selectedValue = correctionMasterList.find((item) => item.pk === chosenCorrectionMaster);
    setChosenValue(selectedValue);
  }, [correctionMasterList]);

  const handleListElement = (e, element) => {
    setChosenCorrectionMaster(parseInt(e.target.value));
    setChosenValue(element);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="content dropdown-box" ref={dropdownRef}>
      <div className={'custom-input'} onClick={() => setShowDropdown(!showDropdown)}>
        <span className="info">
          {chosenValue?.pk === correctionMasterList[0]?.pk
            ? 'Najnowsza korekta'
            : (formatDate(chosenValue?.created_at) ?? '')}
        </span>
        <span className="arrow-down"></span>
      </div>
      {showDropdown && (
        <div className="dropdown dropdown-list">
          <ul className="dropdown-list__container">
            {correctionMasterList?.map((el, index) => {
              return (
                <li className="dropdown-list__element" key={`dropdown-list-element-${index}`}>
                  <label>
                    <input
                      type="checkbox"
                      id={`checkboxes-${index}-${el.pk}`}
                      onChange={(e) => handleListElement(e, el)}
                      value={el.pk}
                      checked={chosenCorrectionMaster === el.pk}
                    />
                    {el.pk === correctionMasterList[0].pk ? 'Najnowsza korekta' : formatDate(el.created_at)}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CorrectionMasterList;
