/**
 * The handleHostForImageURL function takes an image URL and modifies it to use the current host.
 *
 * If the REACT_APP_ENV environment variable is set to 'local', the host is obtained from REACT_APP_APP4AD_URL.
 * Otherwise, the host is constructed as 'https://' + window.location.hostname.
 *
 * The function attempts to parse imageUrl as an absolute URL using the URL constructor.
 * If parsing is successful, the original protocol and host are replaced with currentHost.
 * If parsing fails (for example, if imageUrl is a relative path), the function assumes imageUrl
 * is relative and prepends currentHost to it.
 *
 * @param {string} imageUrl - The image URL to be modified.
 * @returns {string} - The modified image URL with the current host.
 */
export const handleHostForImageURL = (imageUrl) => {
  const currentHost = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_APP4AD_URL : window.location.origin;
  console.log('handleHostForImageURL');
  console.log(imageUrl);

  if (imageUrl && !imageUrl.includes(currentHost)) {
    try {
      const parsedUrl = new URL(imageUrl);
      console.log('return try');
      console.log(imageUrl);
      imageUrl = imageUrl.replace(`${parsedUrl.protocol}//${parsedUrl.host}`, currentHost);
    } catch (e) {
      //in case of relative path
      console.log('return catch');
      console.log(imageUrl);
      imageUrl = currentHost + imageUrl;
    }
  }

  console.log('return');
  console.log(imageUrl);

  return imageUrl;
};
