import SOURCE_FILE_TYPE from '../../components/elements/e-commerce/Reformatting/enums/SOURCE_FILE_TYPES';

/**
 * generateNewReformatBody() - method that generates body structure for reformats orders
 *
 * @param {Object} reformatData - stores all data from Reformats
 * @param {String} sourceType - type of chosen order source
 * @param {Boolean} update - used when we want to update existed Reformat from orders
 * @returns body structure for Reformats for Fetch API
 */

const generateNewReformatBody = (reformatData, sourceType, update) => {
  const generatedSizeList = generateSizeList(reformatData.basic_sizes_list, reformatData.custom_sizes_list);

  const bodyData = {
    additional_info: reformatData.additional_info,
    basic_size_list: JSON.stringify(reformatData.basic_sizes_list),
    custom_size_list: JSON.stringify(reformatData.custom_sizes_list),
    size_list: generatedSizeList.join(','), //That variable help backend count a number of formats
    campaign_name: { content: reformatData.campaign_name },
    action_name: { content: reformatData.order_name },
    deadline: { content: reformatData.deadline },
    brief: reformatData.brief,
    correction: reformatData.correction
  };

  // if (update) {
  //   bodyData.order = null;
  //   bodyData.external_url = null;
  // }

  switch (sourceType) {
    case SOURCE_FILE_TYPE.ORDER_ID:
      bodyData.external_url = null;
      bodyData.order = reformatData.order_id;
      bodyData.variant_id = reformatData.variant_id;
      break;
    case SOURCE_FILE_TYPE.EXTERNAL_URL:
      bodyData.order = null;
      bodyData.variant_id = null;
      bodyData.external_url = reformatData.external_url;
      break;
    default:
      throw new Error('Lack of source type');
  }

  return bodyData;
};

/**
 * generateSizeList() - helper method that render list of all sizes chosen by user, that helsp backend count a number of formats
 *
 * @param {Array} basicSizeList - list of objects that store chosen basic sizes grouped by group, basic sizes are defined in admin panel
 * @param {Array} customSizeList - list of custom sizes created by user manually
 * @returns list of all sizes
 */

const generateSizeList = (basicSizeList, customSizeList) => {
  let result = [];

  if (basicSizeList?.length) {
    basicSizeList.forEach((sizeGroup) => {
      if (sizeGroup?.chosen_sizes?.length) {
        result = [...result, ...(sizeGroup?.chosen_sizes || [])];
      }
    });
  }

  if (customSizeList?.length) {
    result = [...result, ...customSizeList];
  }

  return result;
};

export default { generateNewReformatBody };
