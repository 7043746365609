import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CompareColumn from './CompareColumn';
import correctionServices from '../../../services/correctionServices';
import { ReactComponent as CorrectionIcon } from '../../../../assets/team/order.svg';
import { ReactComponent as BriefIcon } from '../../../../assets/team/order.svg';
import generateDisplayData from '../../../../functions/generateDisplayData';
import variantsServices from '../../../services/variantsServices';
import usePopup from '../../../helper/usePopup';
import ErrorLoadingData from '../Popup/AddOrder/ErrorLoadingData';

const CompareVariantsContainer = () => {
  const navigate = useNavigate();
  const { initPopup } = usePopup();

  const [componentIsMounted, setComponentIsMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get('page')));
  const [loadingData, setLoadingData] = useState(true);
  const [correctionMasterList, setCorrectionMasterList] = useState([]);

  const [variantList, setVariantsList] = useState([]);

  const [variantGlobalData, setVariantGlobalData] = useState({
    order_id: searchParams.get('order'),
    variant_id: searchParams.get('variant_id'),
    correction_pk: searchParams.get('correction_pk'),
    pagination: searchParams.get('pagination'),
    prevPage: searchParams.get('prevPage'),
    correction_master: parseInt(searchParams.get('correction_master'))
  });

  const [displayCorrectionData, setDisplatCorrectionData] = useState({
    old: [],
    new: []
  });

  const initData = async (correctionPK) => {
    const pk = correctionPK ? correctionPK : variantGlobalData.correction_pk;

    await correctionServices
      .getCorrectionsDetails(pk)
      .then((res) => {
        const old_boxes = res.boxes ? generateDisplayData(res.boxes, 0, false) : [];
        const new_boxes = res.boxes_new ? generateDisplayData(res.boxes_new, 0, false) : [];

        setDisplatCorrectionData({ old: old_boxes, new: new_boxes });
      })
      .catch((error) => {
        throw new Error(error);
      });

    setLoadingData(false);
  };

  const getCorrectionMasterList = async () => {
    await correctionServices
      .getCorrectionsMasterList(variantGlobalData.order_id)
      .then((res) => {
        console.log(res);

        setCorrectionMasterList(res);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    variantsServices
      .getVariants(variantGlobalData.order_id)
      .then((res) => {
        setVariantsList(res);
      })
      .catch((error) => {
        console.error(error);
        initPopup(<ErrorLoadingData type={'negative'} info={'data_error'} />);
      });

    const fetchData = async () => {
      try {
        await getCorrectionMasterList();
        await initData();
      } catch (error) {
        console.error(error);
        initPopup(<ErrorLoadingData type={'negative'} info={'data_error'} />);
      } finally {
        setComponentIsMounted(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    //handle page and variant_id in link when page is changed
    if (componentIsMounted) {
      setLoadingData(true);

      correctionServices.getCorrectionsList(variantList[page].id, variantGlobalData.correction_master).then((res) => {
        if (res.length && res[res.length - 1].pk) {
          searchParams.set('correction_pk', res[res.length - 1].pk);
          setVariantGlobalData({
            ...variantGlobalData,
            correction_pk: res[res.length - 1].pk
          });
          initData(res[res.length - 1].pk); //we always take the last correction, because we don't know what we should display after switching. It may happen that we switch from variant number 1 (which has 4 variants but we chose 3 for preview) to variant 2 (which has 2 variants) and we don't know what relation to make
          searchParams.set('correction_master', variantGlobalData.correction_master);
        } else {
          setDisplatCorrectionData({ old: [], new: [] });
          setLoadingData(false);
        }
        searchParams.set('page', page);
        searchParams.set('variant_id', variantList[page].id);
        setSearchParams(searchParams);
      });
    }
  }, [page, variantGlobalData.correction_master]);

  const handleButton = () => {
    navigate(-1);
  };

  return (
    <div className="compare-variants">
      <div className="wrapper">
        <div className="row">
          <CompareColumn
            icon={<BriefIcon />}
            dataList={displayCorrectionData.old}
            title={'Pierwotnie'}
            subtitle={'stan przed wprowadzeniem korekty do zlecenia'}
            loadingData={loadingData}
            columnType={'first'}
          />
          <CompareColumn
            icon={<CorrectionIcon />}
            dataList={displayCorrectionData.new}
            title={'Korekta'}
            subtitle={'wartości do zmiany'}
            page={page}
            setPage={setPage}
            variantList={variantList}
            loadingData={loadingData}
            columnType={'correction'}
            showNoDataInfo={true}
            correctionMasterList={correctionMasterList}
            variantGlobalData={variantGlobalData}
            setVariantGlobalData={setVariantGlobalData}
          />
        </div>
        <div className="action-buttons">
          <button className="second-button" onClick={handleButton}>
            ← Powrót do poprzedniego okna{' '}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompareVariantsContainer;
