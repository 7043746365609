import { handleResponse } from '../helper/handle-response.js';
import authHeader from '../helper/auth-header.js';
import getApiKey from '../helper/getApiKey.js';

const getSetting = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/types/settings/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getTablesConfiguration = async () => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/coreconfig/client-table-config/`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

export default {
  getSetting,
  getTablesConfiguration
};
